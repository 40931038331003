/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React, { ReactNode } from 'react'
import {Popover, Tooltip} from "antd";
import {QuestionCircleFilled} from '@ant-design/icons';

type FormTipProps = {
  label: string,
  tip?: string | ReactNode
}

type FormPopoverProps = {
  label: string,
  title?: string,
  content?: string | ReactNode,
}

const FormTipFC: React.FC<React.PropsWithChildren<FormTipProps>> = ({ label, tip }) => {
  return (
    <>
      {tip && <Tooltip placement="topLeft" title={tip}>
        {label}&nbsp;<QuestionCircleFilled />
      </Tooltip>}
      {!tip && <>{label}</>}
    </>
  )
}

const FormPopoverFC: React.FC<React.PropsWithChildren<FormPopoverProps>> = ({ label, content, title }) => {
  return (
    <>
      {content && <Popover title={title} content={content}>
        {label}&nbsp;<QuestionCircleFilled />
      </Popover>}
      {!content && <>{label}</>}
    </>
  )
}

export const FormTip = FormTipFC
export const FormPopover = FormPopoverFC
