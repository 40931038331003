import { Button, Card, Checkbox, Form, Input, Select } from "antd"
import { useForm } from "antd/es/form/Form"
import { formLayout, tailLayout } from "models/models"
import { AuthorizeNetGatewayRequest, CybersourceGatewayRequest, GatewayRequest, GatewayType, StripeGatewayRequest } from "models/paylink"
import { useState } from "react"

const NameInput = () => <Form.Item label='Name' name='name' rules={[{required: true}]}>
  <Input />
</Form.Item>

const ProcessorInput = () => <Form.Item label='Processor' name='processor' rules={[{required: true}]}>
  <Input />
</Form.Item>

const CurrencyInput = () => <Form.Item label='Currency' name='currency' rules={[{required: true}]}>
  <Input />
</Form.Item>

const ProcessingLevelInput = () => <Form.Item label='Processing Level Type' name='processingLevelType' rules={[{required: true}]}>
  <Select>
    <Select.Option key='Never' value='Never' >Never</Select.Option>
  </Select>
</Form.Item>

const SandboxInput = () => <Form.Item label='Sandbox' name='sandbox' valuePropName='checked'>
  <Checkbox />
</Form.Item>

const SubmitButton = () => <Form.Item {...tailLayout}>
  <Button htmlType='submit' type='primary'>Submit</Button>
</Form.Item>

const CommonInputs = () => <>
  <NameInput />
  <ProcessorInput />
  <CurrencyInput />
  <ProcessingLevelInput />
  <SandboxInput />
  <SubmitButton />
</>

const AuthorizeNetGatewayForm = ({ onSubmit }: {onSubmit: (request: AuthorizeNetGatewayRequest) => void}) => {
  const [ form ] = useForm()
  
  const handleSubmit = (values: any) => {
    onSubmit({ gateway: {...values, gatewayType: GatewayType.AuthorizeNet} } as AuthorizeNetGatewayRequest)
  }

  return <Form form={form} {...formLayout} onFinish={handleSubmit}>
    <Card type='inner' title='Credentials'>
      <Form.Item label='Name' name={['credentials', 'name']} rules={[{required: true}]}>
        <Input />
      </Form.Item>

      <Form.Item label='Transaction Key' name={['credentials', 'transactionKey']} rules={[{required: true}]}>
        <Input />
      </Form.Item>
    </Card>

    <br />

    <CommonInputs />
  </Form>
}

const CybersourceGatewayForm = ({ onSubmit }: {onSubmit: (request: CybersourceGatewayRequest) => void}) => {
  const [ form ] = useForm()
  
  const handleSubmit = (values: any) => {
    onSubmit({ gateway: {...values, gatewayType: GatewayType.Cybersource} } as CybersourceGatewayRequest)
  }

  return <Form form={form} {...formLayout} onFinish={handleSubmit}>
    <Card type='inner' title='Credentials'>
      <Form.Item label='Key ID' name={['credentials', 'keyId']} rules={[{required: true}]}>
        <Input />
      </Form.Item>

      <Form.Item label='Merchant ID' name={['credentials', 'merchantId']} rules={[{required: true}]}>
        <Input />
      </Form.Item>

      <Form.Item label='Shared Secret' name={['credentials', 'sharedSecret']} rules={[{required: true}]}>
        <Input />
      </Form.Item>
    </Card>

    <br />

    <CommonInputs />
  </Form>
}

const StripeGatewayForm = ({ onSubmit }: {onSubmit: (request: StripeGatewayRequest) => void}) => {
  const [ form ] = useForm()
  
  const handleSubmit = (values: any) => {
    onSubmit({ gateway: {...values, gatewayType: GatewayType.Stripe} } as StripeGatewayRequest)
  }

  return <Form form={form} {...formLayout} onFinish={handleSubmit}>
    <Card type='inner' title='Credentials'>
      <Form.Item label='Login' name={['credentials', 'login']} rules={[{required: true}]}>
        <Input />
      </Form.Item>
    </Card>

    <br />

    <CommonInputs />
  </Form>
}

export const GatewayForm = ({ onSubmit }: {onSubmit: (request: GatewayRequest) => void}) => {
  const [ gatewayType, setGatewayType ] = useState<GatewayType>(GatewayType.AuthorizeNet)

  return <>
    <Form.Item label='Gateway Type' {...formLayout} required>
      <Select<GatewayType> value={gatewayType} onChange={v => setGatewayType(v)}>
        {Object.values(GatewayType).map(v => <Select.Option key={v} value={v}>{v}</Select.Option>)}
      </Select>
    </Form.Item>

    {gatewayType === GatewayType.AuthorizeNet && <AuthorizeNetGatewayForm onSubmit={onSubmit} />}
    {gatewayType === GatewayType.Cybersource && <CybersourceGatewayForm onSubmit={onSubmit} />}
    {gatewayType === GatewayType.Stripe && <StripeGatewayForm onSubmit={onSubmit} />}
  </>
}