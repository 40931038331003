import { blue, red } from "@ant-design/colors"
import { ApiOutlined, BankOutlined, BookOutlined, CloseCircleOutlined, ContactsOutlined, CreditCardOutlined, DollarCircleOutlined, DollarOutlined, ExclamationCircleOutlined, FieldTimeOutlined, HeartFilled, HeartOutlined, HomeOutlined, MailOutlined, PhoneOutlined, PlusOutlined, StarOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Button, Card, Col, Divider, Layout, Menu, Result, Row, Skeleton, Space, Spin, Statistic, Table, Tag, Tooltip, Typography } from "antd"
import { Content } from "antd/es/layout/layout"
import Sider from "antd/es/layout/Sider"
import { ItemType } from "antd/es/menu/interface"
import { ColumnsType } from "antd/es/table"
import { FancyCard } from "components/Card"
import { CustomerForm } from "components/paylink/CustomerForm"
import { GatewayForm } from "components/paylink/GatewayForm"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { usePortalResources } from "hooks/usePortalResources"
import { useTableSearch } from "hooks/useTableSearch"
import { getMerchantDisplayName } from "models/models"
import { Address, CardMethod, Customer, CustomerRequest, Gateway, GatewayRequest, Payment } from "models/paylink"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { PaylinkVirtualTerminalView } from "./PaylinkVirtualTerminalView"
import { AddressForm, AddressRequest } from "components/paylink/AddressForm"

const { Title } = Typography

const PaymentTable = ({mid, payments, loading}: {mid: string, payments: Payment[], loading: boolean}) => {
  const history = useHistory()

  const columns: ColumnsType<Payment> = useMemo(() => [
    {
      title: 'ID',
      key: 'id',
      render: (_, payment) => <Text>{payment.id}</Text>
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (_, payment) => <Text>{payment.captureAmount.toLocaleString('en-US', {style: 'currency', currency: payment.currency})}</Text>
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, payment) => <Text>{payment.paymentStatus}</Text>
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, payment) => <Text>{payment.paymentType}</Text>
    },
    {
      key: 'actions',
      render: (_, payment) => <div style={{textAlign: 'right'}}>
        <Button type='primary' ghost icon={<BookOutlined />} onClick={_ => history.push(`/pl/${mid}/payments/${payment.id}`)} />
      </div>
    }
  ], [])

  return <Spin spinning={loading}>
    <Table
      dataSource={payments}
      columns={columns}
    />
  </Spin>
}

const NewPaymentCard = ({mid}: {mid: string}) => {
  const history = useHistory()

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => history.push(`/pl/${mid}/vt`)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Payment</Title>
  </FancyCard>
}

const PaymentsInner = ({mid}: {mid: string}) => {
  const { getPayments } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ payments, setPayments ] = useState<Payment[]>([])

  const refresh = useCallback(() => {
    setLoading(true)
    getPayments(mid)
      .then(p => {
        setPayments(p.payments)
        setLoading(false)
      })
      .catch(e => {
        setPayments([])
        setLoading(false)
      })
  }, [mid])

  useEffect(() => {
    refresh()
  }, [mid])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result title='Payments' icon={<DollarCircleOutlined />} />
      </Col>

      <Col span={24}>
        <NewPaymentCard mid={mid} />
      </Col>

      <Col span={24}>
        <PaymentTable payments={payments} loading={loading} mid={mid} />
      </Col>
    </Row>
  </>
}

const NewGatewayCard = ({mid, onRefresh}: {mid: string, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createGateway } = usePaylinkAPI()

  const handleSubmit = useCallback((request: GatewayRequest) => {
    setLoading(true)
    createGateway(mid, request)
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card>
      <GatewayForm onSubmit={handleSubmit} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Gateway</Title>
  </FancyCard>
}

const GatewayLoadingCard = () => {
  return <Card loading={true}>
    <div style={{marginBlockEnd: '1em'}}>
      <Skeleton><Title level={4} style={{marginBlockEnd: '-0.25em'}}>Test Gateway</Title></Skeleton>
      <Skeleton><Text type='secondary' style={{fontSize: '0.75em'}}>abcdefgh-abcd-abcd-abcd-abcdefghijkl</Text></Skeleton>
    </div>
  </Card>
}

const GatewayCard = ({gateway}: {gateway: Gateway}) => {
  return <Card>
    <div style={{marginBlockEnd: '1em'}}>
      {!!gateway.disabled ? <div><Text strong type='danger'>Disabled</Text></div> : <></>}
      <Title level={4} style={{marginBlockEnd: '-0.25em'}}>{gateway.name}</Title>
      <Text type='secondary' style={{fontSize: '0.75em'}}>{gateway.id}</Text>
    </div>
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0.5rem'}}>
      <Tooltip title='Gateway Type'><Text type='secondary'><ApiOutlined /></Text></Tooltip>
      <div>{gateway.gatewayType}</div>

      <Tooltip title='Processor'><Text type='secondary'><CreditCardOutlined /></Text></Tooltip>
      <div>{gateway.processor}</div>
    </div>
  </Card>
}

const GatewaysInner = ({mid}: {mid: string}) => {
  const { getGateways } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ gateways, setGateways ] = useState<Gateway[]>([])

  const refresh = useCallback(() => {
    setLoading(true)
    return getGateways(mid)
      .then(g => {
        setGateways(g.gateways)
        setLoading(false)
      })
  }, [mid])

  useEffect(() => {
    refresh()
  }, [mid])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result title='Gateways' icon={<ApiOutlined />} />
      </Col>

      <Col span={24}>
        <NewGatewayCard mid={mid} onRefresh={refresh} />
      </Col>

      {loading && <>
        <Col span={24}>
          <GatewayLoadingCard />
        </Col>
      </>}

      {!loading && gateways.map(g => <Col span={24} key={g.id}>
        <GatewayCard key={g.id} gateway={g} />
      </Col>)}
    </Row>
  </>
}

const CustomersTable = ({mid, customers, loading}: {mid: string, customers: Customer[], loading: boolean}) => {
  const history = useHistory()

  const { getSearchProps } = useTableSearch<Customer>()

  const columns: ColumnsType<Customer> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, customer) => <Text>{customer.firstName} {customer.lastName}</Text>,
      sorter: (a, b) => `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`),
      ...getSearchProps((value, record) => {
        const fullName = `${record.firstName} ${record.lastName}`.toLowerCase()
        const searchValue = value.toString().toLowerCase()
        return fullName.includes(searchValue)
      })
    },
    {
      title: 'Company',
      key: 'company',
      dataIndex: 'companyName',
      sorter: (a, b) => (a.companyName && b.companyName) ? a.companyName.localeCompare(b.companyName) : 0,
      ...getSearchProps((value, record) => {
        const searchValue = value.toString().toLowerCase()
        if (!record.companyName) return !searchValue.length
        const companyValue = record.companyName.toLowerCase()
        return companyValue.includes(searchValue)
      })
    },
    {
      key: 'actions',
      render: (_, customer) => <div style={{textAlign: 'right'}}>
        <Button icon={<BookOutlined />} type='primary' ghost onClick={_ => history.push(`/pl/${mid}/customers/${customer.id}`)} />
      </div>
    }
  ]

  return <>
    <Spin spinning={loading}>
      <Table
        dataSource={customers}
        columns={columns}
      />
    </Spin>
  </>
}

const CustomersInner = ({mid}: {mid: string}) => {
  const { getCustomers } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ customers, setCustomers ] = useState<Customer[]>([])

  const keyedCustomers = useMemo(() => customers.map(c => ({...c, key: c.id})), [customers])

  const refresh = useCallback(() => {
    setLoading(true)
    return getCustomers(mid)
      .then(c => {
        setCustomers(c.customers)
        setLoading(false)
      })  
      .catch(e => {
        console.error(e)
        setCustomers([])
        setLoading(false)
      })
  }, [mid])

  useEffect(() => {
    refresh()
  }, [mid])

  return <>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result title='Customers' icon={<ContactsOutlined />} />
      </Col>

      <Col span={24}>
        <NewCustomerCard mid={mid} onRefresh={refresh} />
      </Col>

      <Col span={24}>
        <CustomersTable mid={mid} customers={keyedCustomers} loading={loading} />
      </Col>
    </Row>
  </>
}

const NewAddressCard = ({mid, cid, onRefresh}: {mid: string, cid: string, onRefresh: () => void}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createAddress } = usePaylinkAPI()

  const handleSubmit = useCallback((request: AddressRequest) => {
    setLoading(true)
    return createAddress(mid, cid, {address: request})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card title='New Address'>
      <AddressForm onSubmit={handleSubmit} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={_ => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Address</Title>
  </FancyCard>
}

const AddressCard = ({address}: {address: Address}) => {
  return <Card>
    <div>
      <div style={{marginBlockEnd: '0.5em'}}>
        <Title level={4} style={{marginBlockEnd: '-0.25em'}}>{address.firstName} {address.lastName}</Title>
        <Text type='secondary' style={{fontSize: '0.75em'}}>{address.id}</Text>
      </div>
      {!!address.default || !!address.disabled ? <Space size='small'>
        {!!address.default ? <Tag color={blue.primary} icon={<StarOutlined />}>Default</Tag> : <></>}
        {!!address.disabled ? <Tag color={red.primary} icon={<ExclamationCircleOutlined />}>Disabled</Tag> : <></>}
      </Space> : <></>}
    </div>
    <Divider />
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0.5rem'}}>
      <Text type='secondary'><HomeOutlined /></Text>
      <div>
        <Text>{address.line1}</Text>
        <br />
        <Text>{address.locality}, {address.region}, {address.country} {address.postalCode}</Text>
      </div>
      
      <Text type='secondary'><MailOutlined /></Text>
      <Text>{address.email}</Text>

      <Text type='secondary'><PhoneOutlined /></Text>
      <Text>{address.phoneNumber}</Text>
    </div>
  </Card>
}

const CardMethodCard = ({card}: {card: CardMethod}) => {
  return <Card>
    <div>
      <div style={{marginBlockEnd: '0.5em'}}>
        <Title level={4} style={{marginBlockEnd: '-0.25em'}}>{card.cardNumber}</Title>
        <Text type='secondary' style={{fontSize: '0.75em'}}>{card.id}</Text>
      </div>
      {!!card.default || !!card.disabled ? <Space size='small'>
        {!!card.default ? <Tag color={blue.primary} icon={<StarOutlined />}>Default</Tag> : <></>}
        {!!card.disabled ? <Tag color={red.primary} icon={<ExclamationCircleOutlined />}>Disabled</Tag> : <></>}
      </Space> : <></>}
    </div>
    <Divider />
    <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0.5rem'}}>
      <Text type='secondary'><FieldTimeOutlined /></Text>
      <Text>{card.expirationMonth}/{card.expirationYear}</Text>

      <Text type='secondary'><UserOutlined /></Text>
      <Text>{card.nameOnCard}</Text>
      
      <Text type='secondary'><ApiOutlined /></Text>
      <Text>{card.gatewayId}</Text>
    </div>
  </Card>
}

const NewCustomerCard = ({mid, onRefresh}: {mid: string, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const { createCustomer } = usePaylinkAPI()

  const handleSubmit = useCallback((request: CustomerRequest) => {
    setLoading(true)
    createCustomer(mid, {customer: request})
      .then(onRefresh)
      .then(_ => {
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card title='New Customer'>
      <CustomerForm onSubmit={handleSubmit} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Customer</Title>
  </FancyCard>
}

const PaymentView = ({mid}: {mid: string}) => {
  const { getPayment, getCustomer } = usePaylinkAPI()

  const { pid } = useParams<{pid: string}>()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ payment, setPayment ] = useState<Payment | undefined>(undefined)
  const [ customer, setCustomer ] = useState<Customer | undefined>(undefined)

  useEffect(() => {
    refresh()
  }, [mid, pid])

  const refresh = useCallback(() => {
    setLoading(true)
    getPayment(mid, pid)
      .then(p => {
        setPayment(p.payment)
        return p.payment
      })
      .then(p => getCustomer(mid, p.customerId))
      .then(c => {
        setCustomer(c.customer)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setPayment(undefined)
        setCustomer(undefined)
        setLoading(false)
      })

  }, [mid, pid])

  return <Spin spinning={loading}>
    {!!payment && <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Card>
            <Result
              title={<Title level={2}>{payment.id}</Title>}
              icon={<DollarOutlined />}
            />

            <Row gutter={[16, 16]}>
              {!!payment.gatewayId && <Col span={8}>
                <Statistic title='Gateway' value={payment.gatewayId} style={{textAlign: 'center'}} />
              </Col>}
              {!!customer && <Col span={8}>
                <Statistic title='Customer' value={customer.id} style={{textAlign: 'center'}} formatter={_ => <Link to={`/pl/${mid}/customers/${customer.id}`}>{customer.firstName} {customer.lastName}</Link>}  />
              </Col>}
            </Row>
          </Card>
        </Col>
      </Row>
    </>}
  </Spin>
}

const CustomerView = ({mid}: {mid: string}) => {
  const { getCustomer, getAddresses, getCards, getBankAccounts } = usePaylinkAPI()

  const { cid } = useParams<{cid: string}>()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ customer, setCustomer ] = useState<Customer | undefined>(undefined)
  const [ addresses, setAddresses ] = useState<any[] | undefined>(undefined)
  const [ cards, setCards ] = useState<any[] | undefined>(undefined)
  const [ bankAccounts, setBankAccounts ] = useState<any[] | undefined>(undefined)

  useEffect(() => {
    refresh()
  }, [mid, cid])

  const refresh = useCallback(() => {
    setLoading(true)
    Promise.all([getCustomer(mid, cid), getAddresses(mid, cid), getCards(mid, cid), getBankAccounts(mid, cid)])
      .then(([c, a, p, b]) => {
        setCustomer(c.customer)
        setAddresses(a.addresses)
        setCards(p.cards)
        setBankAccounts(b.bankAccounts)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setCustomer(undefined)
        setAddresses(undefined)
        setCards(undefined)
        setBankAccounts(undefined)
        setLoading(false)
      })
  }, [mid, cid])

  return <Spin spinning={loading}>
    {!!customer && <>
      <br />
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Card>
            <Result
              title={<Title level={2}>{customer.firstName} {customer.lastName}</Title>}
              icon={<UserOutlined />}
            />
            <Row gutter={[16, 16]}>
              {!!customer.companyName && <Col span={8}>
                <Statistic title='Company' value={customer.companyName} style={{textAlign: 'center'}} />
              </Col>}
              {!!customer.email && <Col span={8}>
                <Statistic title='Email' value={customer.email} style={{textAlign: 'center'}}  />
              </Col>}
              {!!customer.phoneNumber && <Col span={8}>
                <Statistic title='Phone Number' value={customer.phoneNumber} formatter={v => v}  style={{textAlign: 'center'}} />
              </Col>}
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Divider>Addresses</Divider>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <NewAddressCard mid={mid} cid={cid} onRefresh={refresh} />
            </Col>
            {!!addresses
              ? addresses.map(a => <Col xs={24} xl={12}>
                  <AddressCard address={a} key={a.id} />
                </Col>)
              : <></>}
          </Row>
        </Col>

        <Col span={24}>
          <Divider>Payment Methods</Divider>
          <Row gutter={[32, 32]}>
          {!!cards
            ? cards.map(c => <Col xs={24} xl={12}>
              <CardMethodCard card={c} key={c.id} />
            </Col>)
            : <></>}
          {!!bankAccounts
            ? bankAccounts.map(b => <Card key={b.id}>{JSON.stringify(b)}</Card>)
            : <></>
          }
          </Row>
        </Col>
      </Row>
    </>}
  </Spin>
}

const { Text } = Typography

enum MenuKey {
  PAYMENTS = 'payments',
  GATEWAYS = 'gateways',
  CUSTOMERS = 'customers',
}

const PaylinkMerchantViewRoot = () => {
  const { mid } = useParams<{mid: string}>()
  return <Redirect to={`/pl/${mid}/payments`} />
}

export const PaylinkMerchantView = () => {
  const { mid } = useParams<{mid: string}>()

  const { merchants } = usePortalResources()

  const history = useHistory()
  const match = useRouteMatch()
  const [ selectedMenuItem, setSelectedMenuItem ] = useState<MenuKey>(MenuKey.PAYMENTS)

  const menuItems: (ItemType)[] = [
    {key: MenuKey.PAYMENTS, label: <Text strong>Payments</Text>},
    {key: MenuKey.GATEWAYS, label: <Text strong>Gateways</Text>},
    {key: MenuKey.CUSTOMERS, label: <Text strong>Customers</Text>}
  ]

  const merchant = useMemo(() => {
    return merchants.find(m => m.id === mid)
  }, [mid, merchants])

  if (!merchant) return <></>

  return <>
    <Layout style={{minHeight: '100%'}}>
      <Sider theme="light" width={360} style={{minHeight: '100%'}}>
        <div style={{padding: '0.5em', display: 'flex', flexFlow: 'row', alignItems: 'center'}}>
          <Avatar size={48} shape='square' icon={<TeamOutlined />} />
          <div style={{marginInlineStart: '0.5em'}}>
            <Text strong style={{fontSize: '1em'}}>{getMerchantDisplayName(merchant)}</Text>
            <br />
            <Text type='secondary' style={{fontSize: '0.75em'}}>{merchant.id}</Text>
          </div>
        </div>
        <Divider style={{marginBlock: '0.5em'}} />
        <Menu
          mode='inline'
          items={menuItems}
          onClick={info => history.push(`/pl/${mid}/${info.key}`)}
          // selectedKeys={}
          defaultSelectedKeys={[selectedMenuItem]}
        />
      </Sider>
      <Content className='main-content'>
        <Switch>
          <Route path='/pl/:mid' component={PaylinkMerchantViewRoot} exact />
          <Route path='/pl/:mid/payments' component={() => <PaymentsInner mid={mid} />} exact />
          <Route path='/pl/:mid/payments/:pid' component={() => <PaymentView mid={mid} />} />
          <Route path='/pl/:mid/vt' component={() => <PaylinkVirtualTerminalView />} exact />
          <Route path='/pl/:mid/gateways' component={() => <GatewaysInner mid={mid} />} exact />
          <Route path='/pl/:mid/customers' component={() => <CustomersInner mid={mid} />} exact />
          <Route path='/pl/:mid/customers/:cid' component={() => <CustomerView mid={mid} />} />
        </Switch>
      </Content>
    </Layout>
  </>
} 