import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useSupportAPI } from './useSupportAPI'
import {InterchangeProgramMetadata} from "../models/models";

type InterchangeProgramScheduleContext_Shape = {
  loaded: boolean,
  programs: InterchangeProgramMetadata[]
}

const initialContext: InterchangeProgramScheduleContext_Shape = {
  loaded: false,
  programs: [],
}

const InterchangeProgramScheduleContext = createContext<InterchangeProgramScheduleContext_Shape>(initialContext)

type InterchangeProgramScheduleProvider_Props = {
  mid: string | undefined,
  children: ReactNode,
}

export const InterchangeProgramScheduleProvider = ({ mid, children }: InterchangeProgramScheduleProvider_Props) => {
  const { getInterchangeProgramData } = useSupportAPI()

  const [ loaded, setLoaded ] = useState<boolean>(false)
  const [ programs, setPrograms ] = useState<InterchangeProgramMetadata[]>([])

  const contextValue = { loaded, programs } as InterchangeProgramScheduleContext_Shape

  useEffect(() => {
    getInterchangeProgramData(mid)
      .then(pd => {
        setPrograms(pd.data)
        setLoaded(true)
      })
  }, [mid])

  return <InterchangeProgramScheduleContext.Provider value={contextValue}>
    {children}
  </InterchangeProgramScheduleContext.Provider>
}

export const useInterchangeProgramSchedule = () => {
  const { programs } = useContext(InterchangeProgramScheduleContext)
  const getPrograms = useCallback(() => programs, [programs])
  return { getPrograms }
}