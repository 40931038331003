import { blue } from "@ant-design/colors"
import { ContactsTwoTone, CreditCardTwoTone, DollarOutlined, HomeTwoTone, PlusCircleTwoTone } from "@ant-design/icons"
import { Button, Card, Cascader, Col, Empty, Form, Input, InputNumber, Layout, Result, Row, Select, SelectProps, Spin, Switch, Typography } from "antd"
import { v4 } from 'uuid'
import { CardProps } from "antd/lib"
import { PaylinkIFrame } from "components/paylink/PaylinkIFrame"
import { commaSeparatedFormatter, commaSeparatedParser } from "components/Processor/ProcessorCore"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { useAuthorization } from "hooks/useAuthorization"
import { usePortalResources } from "hooks/usePortalResources"
import { getMerchantDisplayName, Jurisdiction, regionsByJurisdiction } from "models/models"
import { Address, CardMethod, Customer, Gateway } from "models/paylink"
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useForm } from "antd/es/form/Form"
import { PaymentRequest } from "models/paylink/PaymentRequest"

const { Text } = Typography

const NewCustomer = 'newCustomer'
const NewPaymentMethod = 'newPaymentMethod'
const NewAddress = 'newAddress'
const NoAddress = 'noAddress'
const SameAddress = 'sameAddress'

const isRequired = {required: true}

const formToRequest = (values: VTForm_Shape): PaymentRequest => {
  const { gatewayId, customerId, paymentMethodId, amount } = values
  return {
    idempotencyKey: v4(),
    payment: {
      gatewayId,
      customerId,
      paymentMethodToken: paymentMethodId,
      amount,
      addressId: values.billingAddress.type
    }
  }
}

enum AddressType {
  BILLING,
  SHIPPING
}

const ExpandableCard = (props: CardProps & { enabled: boolean }) => {
  const { enabled, children, styles, ...otherProps } = props

  const styleProps = useMemo(() => enabled ? {styles} : ({
    styles: {
      ...(!!styles ? styles : {}),
      body: {
        padding: 0
      }
    }
  }), [enabled, styles])

  return <Card
    {...otherProps}
    {...styleProps}
  >
    {enabled ? children : <></>}
  </Card>
}

const ToggleCard = (props: CardProps) => {
  const [ enabled, setEnabled ] = useState<boolean>(false)

  const { children, ...otherProps } = props

  const styleProps = useMemo(() => enabled ? {} : ({
    styles: {
      ...(props.styles),
      body: {
        padding: 0
      }
    }
  }), [enabled])
  
  return <Card
    {...otherProps}
    {...styleProps}
    extra={<>
      <Switch
        onChange={v => setEnabled(v)}
      />
    </>}
  >
    {enabled ? children : <></>}
  </Card>
}

const MerchantSelector = ({ onSelect }: { onSelect: (mid: string) => void }) => {
  const { merchants, merchantsLoaded } = usePortalResources()

  const options = useMemo(() => {
    if (!merchants) return []
    return merchants.map(m => ({label: getMerchantDisplayName(m), key: m.id, value: m.id}))
  }, [merchants])

  return <>
    <Form.Item layout='vertical' label='Merchant'>
      <Select disabled={!merchantsLoaded} loading={!merchantsLoaded} options={options} onChange={mid => onSelect(mid)} />
    </Form.Item>
  </>
}

const GatewaySelector = ({ merchantId }: { merchantId: string | undefined }) => {
  const { getGateways } = usePaylinkAPI()

  const [ gateways, setGateways ] = useState<Gateway[] | undefined>(undefined)
  const [ loading, setLoading ] = useState<boolean>(false)

  useEffect(() => {
    if (!merchantId) {
      setLoading(false)
      setGateways([])
      return
    }
    (async () => {
      setLoading(true)
    })()
      .then(_ => getGateways(merchantId))
      .then(v => {
        setGateways(v.gateways)
        setLoading(false)
      })
  }, [merchantId])

  const options = useMemo(() => {
    if (!gateways) return []
    return gateways.map(g => ({label: g.name, key: g.id, value: g.id}))
  }, [gateways])

  return <>
    <Form.Item layout='vertical' name='gatewayId' label='Gateway' rules={[isRequired]}>
      <Select disabled={!merchantId || loading} loading={loading} options={options} />
    </Form.Item>
  </>
}

type RegionCascaderOptions = RegionCascaderJurisdiction[]

type RegionCascaderJurisdiction = {
  value: Jurisdiction,
  label: ReactNode,
  children: RegionCascaderRegion[]
}

type RegionCascaderRegion = {
  value: string,
  label: ReactNode
}

export const regionCascaderOptions: RegionCascaderOptions = Object.entries(regionsByJurisdiction).map(([jurisdiction, regions]) => {
  const children = regions.map(r => ({value: r.key, label: r.name}))
  return {value: jurisdiction as Jurisdiction, label: jurisdiction, children}
})

export const RegionSelector = () => {
  console.log(regionCascaderOptions)
  return <Cascader onChange={v => console.log(v)} placeholder='Sample Country / Sample Region' options={regionCascaderOptions} />
}

const SurchargeCard = () => {
  return <ToggleCard
    title='Surcharge'
  >
    <Form.Item layout='vertical' label='Processor'>
      <Select />
    </Form.Item>
  </ToggleCard>
}

const PaymentCard = ({ merchantId, gatewayId, submitKey }: {merchantId: string | undefined, gatewayId: string | undefined, submitKey: string}) => {
  return <Card title='Payment'>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        {!!merchantId && !!gatewayId
          ? <PaylinkIFrame merchantId={merchantId} gatewayId={gatewayId} submitKey={submitKey} />
          : <Empty description='Please select a merchant and/or gateway' />
        }
      </Col>
    </Row>
  </Card>
}

const AddressForm = ({ path }: {path: string[]}) => {
  return <Row gutter={[16, 16]}>
    <Col span={12}>
      <Form.Item layout='vertical' label='First Name' name={[...path, 'firstName']} rules={[isRequired]}>
        <Input placeholder='John' />
      </Form.Item>
    </Col>

    <Col span={12}>
      <Form.Item layout='vertical' label='Last Name' name={[...path, 'lastName']} rules={[isRequired]}>
        <Input placeholder='Doe' />
      </Form.Item>
    </Col>

    <Col span={24}>
      <Form.Item layout='vertical' label='Address' name={[...path, 'address']} rules={[isRequired]}>
        <Input placeholder='1 Sample Drive' />
        <Input placeholder='Unit 1' style={{marginBlockStart: '0.25em'}} />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='City' name={[...path, 'city']} rules={[isRequired]}>
        <Input placeholder='Sample City' />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='Region' name={[...path, 'region']} rules={[{ type: 'array', required: true }]}>
        <RegionSelector />
      </Form.Item>
    </Col>

    <Col span={8}>
      <Form.Item layout='vertical' label='Postal Code' name={[...path, 'postalCode']} rules={[isRequired]}>
        <Input placeholder='11111' />
      </Form.Item>
    </Col>
  </Row>
}

type BillingAddressCard_Props = {
  merchantId: string | undefined,
  customerId: string | undefined,
}
const BillingAddressCard = ({ merchantId, customerId }: BillingAddressCard_Props) => {
  const [ address, setAddress ] = useState<string>(NewAddress)

  return <ExpandableCard
    enabled={address === NewAddress}
    title='Billing Address'
    styles={{extra: {width: 'calc(50% - 8px)'}}}
    extra={<Form.Item noStyle name={['billingAddress', 'type']} rules={[isRequired]} initialValue={address}>
      <AddressSelector defaultValue={NewAddress} onChange={v => setAddress(v)} merchantId={merchantId} customerId={customerId} type={AddressType.BILLING} />
    </Form.Item>}
  >
    <AddressForm path={['billingAddress']} />
  </ExpandableCard>
}

type ShippingAddressCard_Props = {
  merchantId: string | undefined,
  customerId: string | undefined,
}
const ShippingAddressCard = ({ merchantId, customerId }: ShippingAddressCard_Props) => {
  const [ address, setAddress ] = useState<string>(NoAddress)

  return <ExpandableCard
    enabled={address === NewAddress}
    title='Shipping Address'
    styles={{extra: {width: 'calc(50% - 8px)'}}}
    extra={<Form.Item noStyle name={['shippingAddress', 'type']} rules={[isRequired]} initialValue={address}>
      <AddressSelector defaultValue={NoAddress} onChange={v => setAddress(v)} merchantId={merchantId} customerId={customerId} type={AddressType.SHIPPING} />
    </Form.Item>}
  >
    <AddressForm path={['shippingAddress']} />
  </ExpandableCard>
}

const PaymentDetailsDrawer = () => {
  return <>
  
  </>
}

type CustomerSelector_Props = {
  merchantId: string | undefined,
  gatewayId: string | undefined,
}
const CustomerSelector = ({ merchantId, gatewayId }: CustomerSelector_Props) => {
  const { getCustomers } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ customers, setCustomers ] = useState<Customer[]>([])

  const options = useMemo(() => {
    return [
      {label: <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Customer</Text>, key: NewCustomer, value: NewCustomer},
      ...(customers.map((c, i) => {
        console.log(c)
        return {label: <Text><ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />{c.firstName} {c.lastName}{!!c.companyName ? <Text type='secondary'> ({c.companyName})</Text> : <></>} </Text>, key: c.id, value: c.id}
      }))
    ]
  }, [customers])

  useEffect(() => {
    if (!merchantId || !gatewayId) {
      setCustomers([])
      setLoading(false)
      return
    }
    setLoading(true)
    getCustomers(merchantId)
      .then(c => {
        setCustomers(c.customers)
        setLoading(false)
      })
  }, [merchantId, gatewayId])

  return <Form.Item layout='vertical' label='Customer' name='customerId' rules={[isRequired]}>
    <Select
      disabled={!merchantId || !gatewayId || loading}
      loading={loading}
      showSearch
      options={options}
    />
  </Form.Item>
}

type CardLabel_Props = {
  card: CardMethod,
}
const CardLabel = ({ card }: CardLabel_Props) => {
  return <Text>
    <CreditCardTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />
    {card.cardNumber}
    <Text type='secondary'> ({card.nameOnCard})</Text>
  </Text>
}

type PaymentMethodSelector_Props = {
  merchantId: string | undefined,
  customerId: string | undefined,
}
type PaymentCard = any
type BankAccount = any
const PaymentMethodSelector = ({ merchantId, customerId }: PaymentMethodSelector_Props) => {
  const { getCards, getBankAccounts } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ cards, setCards ] = useState<PaymentCard[]>([])
  const [ bankAccounts, setBankAccounts ] = useState<BankAccount[]>([])

  const options = useMemo(() => {
    console.log(cards, bankAccounts)
    return [
      {label: <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Payment Method</Text>, key: NewPaymentMethod, value: NewPaymentMethod},
      ...(cards.map((c, i) => {
        return {label: <CardLabel card={c} />, key: c.token, value: c.token}
      })),
      ...(bankAccounts.map((b, i) => {
        console.log(b)
        return {label: <Text><ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />Bank Account <Text type='secondary'>(Bank Account)</Text></Text>, key: b.id, value: b.id}
      }))
    ]
  }, [cards, bankAccounts])

  useEffect(() => {
    if (!merchantId || !customerId || customerId === NewCustomer) {
      setCards([])
      setBankAccounts([])
      setLoading(false)
      return
    }
    setLoading(true)
    Promise.all([getCards(merchantId, customerId), getBankAccounts(merchantId, customerId)])
      .then(([c, b]) => {
        setCards(c.cards)
        setBankAccounts(b.bankAccounts)
        setLoading(false)
      })
  }, [merchantId, customerId])

  return <Form.Item layout='vertical' label='Payment Method' name='paymentMethodId' rules={[isRequired]}>
    <Select
      disabled={!merchantId || !customerId || loading}
      loading={loading}
      options={options}
    />
  </Form.Item>
}

type AddressLabel_Props = {
  address: Address,
}
const AddressLabel = ({ address }: AddressLabel_Props) => {
  return <Text>
    <ContactsTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />
    {address.firstName} {address.lastName}
    <Text type='secondary'> ({address.line1}, {address.locality}, {address.region} {address.postalCode})</Text>
  </Text>
}

type AddressSelector_Props = {
  merchantId: string | undefined,
  customerId: string | undefined,
  type: AddressType,
}
const AddressSelector = (props: AddressSelector_Props & SelectProps) => {
  const { merchantId, customerId, type, ...selectProps } = props
  
  const { getAddresses } = usePaylinkAPI()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ addresses, setAddresses ] = useState<Address[]>([])

  const options = useMemo(() => {
    return [
      ...(type === AddressType.SHIPPING ? [
        {label: <Text type='secondary'>No Shipping Address</Text>, key: NoAddress, value: NoAddress},
        {label: <Text strong style={{color: blue.primary}}><HomeTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />Same As Billing Address</Text>, key: SameAddress, value: SameAddress}
      ] : []),
      {label: <Text strong style={{color: blue.primary}}><PlusCircleTwoTone twoToneColor={blue.primary} style={{marginInlineEnd: '0.5em'}} />New Address</Text>, key: NewAddress, value: NewAddress},
      ...(addresses.map((a, i) => {
        console.log(a)
        return {label: <AddressLabel address={a} />, key: a.id, value: a.id}
      })),
    ]
  }, [addresses])

  useEffect(() => {
    if (!merchantId || !customerId || customerId === NewCustomer) {
      setAddresses([])
      setLoading(false)
      return
    }
    setLoading(true)
    getAddresses(merchantId, customerId)
      .then(a => {
        setAddresses(a.addresses)
        setLoading(false)
      })
  }, [merchantId, customerId])

  return <Select
    style={{width: '100%'}}
    disabled={!merchantId || !customerId || loading}
    loading={loading}
    options={options}
    {...selectProps}
  />
}

type VTForm_Shape = {
  gatewayId: string,
  customerId: string,
  paymentMethodId: string,
  amount: number,
  billingAddress: {
    type: string
  }
}

export const PaylinkVirtualTerminalView = () => {
  const history = useHistory()

  const [ form ] = useForm()

  const { mid } = useParams<{mid: string}>()

  const { createPayment } = usePaylinkAPI()

  const { isAuthorized, actionsLoaded } = useAuthorization('*', ['admin'])
  const notAuthorized = useMemo(() => actionsLoaded && !isAuthorized('admin'), [actionsLoaded, isAuthorized])

  const [ showLoading, setLoading ] = useState<boolean>(false)
  const [ submitKey, setSubmitKey ] = useState<string>('')

  const submitPayment = useCallback((values: VTForm_Shape) => {
    console.log(values)
    setSubmitKey(v4())

    if (!mid) return

    //submitIFrame()

    const paymentRequest: PaymentRequest = formToRequest(values)
    createPayment(mid, paymentRequest)
  }, [mid])

  if (notAuthorized) return <></>
  return <Spin spinning={showLoading}>
        <Form form={form} component={false} onFinish={(values) => submitPayment(values)}>
          <Row gutter={[32, 32]}>
              {/* Main page */}
              <Col xs={24}>
                <Row gutter={[32, 32]}>
                  <Col span={24}>
                    <Result
                      icon={<DollarOutlined />}
                      extra={<>
                        <Typography.Title level={3}>New Payment</Typography.Title>
                        <div style={{maxWidth: '80ch', margin: '0 auto', fontSize: 'clamp(0.75rem, calc(0.75rem + 0.25vw), 1.125rem)'}}>
                          {/* Idk put some copy here */}
                        </div>
                      </>}
                    />
                  </Col>
                  
                  <Col span={24}>
                    <Card title="Details">
                      <Row gutter={[32, 32]}>
                        <Col span={12}>
                          <GatewaySelector merchantId={mid} />
                        </Col>

                        <Col span={12}>
                          <Form.Item noStyle shouldUpdate={(last, current) => last.gatewayId !== current.gatewayId}>
                            {({getFieldValue}) => {
                              const gatewayId = getFieldValue('gatewayId')
                              return <CustomerSelector merchantId={mid} gatewayId={gatewayId} />
                            }}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item noStyle shouldUpdate={(last, current) => last.customerId !== current.customerId}>
                            {({getFieldValue}) => {
                              const customerId = getFieldValue('customerId')
                              return <PaymentMethodSelector merchantId={mid} customerId={customerId} />
                            }}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item layout='vertical' label='Amount' name='amount' rules={[isRequired]}>
                            <InputNumber
                              addonBefore='$'
                              min={0}
                              step={0.01}
                              precision={2}
                              style={{width: '100%'}}
                              formatter={commaSeparatedFormatter}
                              parser={commaSeparatedParser}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Form.Item noStyle shouldUpdate={(last, current) => last.customerId !== current.customerId}>
                    {({getFieldValue}) => {
                      const customerId = getFieldValue('customerId')
                      if (customerId === NewCustomer) return <>
                        <Col span={24}>
                          <Card title='Customer'>
                            <Row gutter={[32, 32]}>
                              <Col span={12}>
                                <Form.Item label='First Name' name={['customer', 'firstName']} layout='vertical' rules={[isRequired]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item label='Last Name' name={['customer', 'lastName']} layout='vertical' rules={[isRequired]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item label='Phone Number' name={['customer', 'phoneNumber']} layout='vertical' rules={[isRequired]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item label='Email' name={['customer', 'email']} layout='vertical' rules={[isRequired]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item label='Company Name' name={['customer', 'companyName']} layout='vertical' rules={[isRequired]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item label='Merchant Account Number' name={['customer', 'merchantAccountNumber']} layout='vertical' rules={[isRequired]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </>
                      return <></>
                    }}
                  </Form.Item>

                  <Form.Item noStyle shouldUpdate={(last, current) => last.paymentMethodId !== current.paymentMethodId}>
                    {({getFieldValue}) => {
                      const gatewayId = getFieldValue('gatewayId')
                      const paymentMethodId = getFieldValue('paymentMethodId')
                      if (paymentMethodId === NewPaymentMethod) return <>
                        <Col span={24}>
                          <PaymentCard merchantId={mid} gatewayId={gatewayId} submitKey={submitKey} />
                        </Col>
                      </>
                      return <></>
                    }}
                  </Form.Item>

                  {/*
                  <Col span={24}>
                    <SurchargeCard />
                  </Col>
                  */}

                  <Col span={24}>
                    <Form.Item noStyle shouldUpdate={(last, current) => last.customerId !== current.customerId}>
                      {({getFieldValue}) => {
                        const customerId = getFieldValue('customerId')
                        return <BillingAddressCard merchantId={mid} customerId={customerId} />
                      }}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item noStyle shouldUpdate={(last, current) => last.customerId !== current.customerId}>
                      {({getFieldValue}) => {
                        const customerId = getFieldValue('customerId')
                        return <ShippingAddressCard merchantId={mid} customerId={customerId} />
                      }}
                    </Form.Item>
                  </Col>

                </Row>
              </Col>

              <Col span={24}>
                <Button type='primary' block icon={<DollarOutlined />} onClick={_ => form.submit()}>Pay</Button>
              </Col>

          </Row>

          <br />
        </Form>
  </Spin>
}