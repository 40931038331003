import { AddressRequest } from "components/paylink/AddressForm";
import { useRequest } from "hooks/useRequest";
import { CardRequest, Customer, CustomerRequest, Gateway, GatewayRequest, Payment, SessionRequest } from "models/paylink";
import { useCallback } from "react";
import { env2 } from "utils/env2";
import { PaymentRequest } from 'models/paylink/PaymentRequest'


const PaylinkBaseUrl = env2("REACT_APP_PAYLINK_URL")
//const PaylinkAccessToken = env2("REACT_APP_PAYLINK_ACCESS_TOKEN")

//const getAccessToken = () => PaylinkAccessToken

//console.log(getAccessToken())

export const usePaylinkAPI = () => {
  const getOrigin = useCallback(() => PaylinkBaseUrl, [PaylinkBaseUrl])
  const { makeRequest } = useRequest()

  const createSession = useCallback(
    (mid: string, request: SessionRequest) => {
      return makeRequest([`/v2/paylink/sessions/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const getSessionUrl = useCallback((sessionId: string) => `${PaylinkBaseUrl}/v2/controls/${sessionId}`, [])

  const createCustomer = useCallback(
    (mid: string, request: {customer: CustomerRequest}) => {
      return makeRequest([`/v2/paylink/customers/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const createGateway = useCallback(
    (mid: string, request: GatewayRequest) => {
      return makeRequest([`/v2/paylink/gateways/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const createCard = useCallback(
    (mid: string, request: CardRequest) => {
      return makeRequest([`/v2/paylink/cards/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const createAddress = useCallback(
    (mid: string, cid: string, request: AddressRequest) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/addresses`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const createPayment = useCallback(
    (mid: string, request: PaymentRequest) => {
      return makeRequest([`/v2/paylink/payments/${mid}`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'content-type': 'application/json'
        }
      }])
    }, [makeRequest])

  const getGateways: (mid: string) => Promise<{gateways: Gateway[]}> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/gateways/${mid}`])
    }, [makeRequest])

  const getCustomers: (mid: string) => Promise<{customers: Customer[]}> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}`])
    }, [makeRequest])

  const getCustomer: (mid: string, cid: string) => Promise<{customer: Customer}> = useCallback(
    (mid: string, cid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}`])
    }, [makeRequest])

  const getCards: (mid: string, cid: string) => Promise<any> = useCallback(
    (mid: string, cid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/cards`])
    }, [makeRequest])

  const getBankAccounts: (mid: string, cid: string) => Promise<any> = useCallback(
    (mid: string, cid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/bank-accounts`])
    }, [makeRequest])

  const getAddresses: (mid: string, cid: string) => Promise<any> = useCallback(
    (mid: string, cid: string) => {
      return makeRequest([`/v2/paylink/customers/${mid}/${cid}/addresses`])
    }, [makeRequest])

  const getPayment: (mid: string, pid: string) => Promise<{payment: Payment}> = useCallback(
    (mid: string, pid: string) => {
      return makeRequest([`/v2/paylink/payments/${mid}/${pid}`])
    }, [makeRequest])

  const getPayments: (mid: string) => Promise<{payments: Payment[]}> = useCallback(
    (mid: string) => {
      return makeRequest([`/v2/paylink/payments/${mid}`])
    }, [makeRequest])

  return {
    getOrigin,
    createSession, getSessionUrl,
    createCustomer,
    createGateway,
    createCard,
    createAddress,
    createPayment,
    getGateways,
    getCustomers,
    getCustomer,
    getCards,
    getBankAccounts,
    getAddresses,
    getPayment,
    getPayments
  }
}