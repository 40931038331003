import { DollarOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Layout, Result, Row, Spin, Table, Typography } from "antd"
import { useForm } from "antd/es/form/Form"
import { ErrorBoundary } from "components/ErrorBoundary"
import Loading from "components/Loading/Loading"
import { PaylinkIFrame } from "components/paylink/PaylinkIFrame"
import { usePaylinkExternalDependencies } from "hooks/paylink/usePaylinkExternalDependencies"
import { useQueryParams } from "hooks/useQueryParams"
import { PaymentType } from "models/paylink"
import { Suspense, useCallback, useEffect, useMemo, useState } from "react"
import { AddressForm } from "../PaylinkVirtualTerminalView/PaylinkVirtualTerminalView"

type PaylinkHostedPaymentSession_Shape = {
  merchantId: string,
  locationId: string,
  gatewayId: string,
  amount: number,
  paymentType: PaymentType,
  surcharge: boolean,
}

type PaylinkHostedPaymentForm_Shape = any

const useSessionData = () => {
  const { getParamByKey } = useQueryParams()

  const [ session, setSession ] = useState<string>('')

  useEffect(() => {
    setSession(getParamByKey('session') as string)
  }, [getParamByKey])

  const sessionData = useMemo(() => {
    if (!session) return undefined
    const decoded = atob(session)
    return JSON.parse(decoded) as PaylinkHostedPaymentSession_Shape
  }, [session])

  return sessionData
}

const HPPHeader = () => {
  const data = useSessionData()

  return <>
    <Result
      icon={<DollarOutlined />}
      extra={<>
        <Typography.Title level={2}>Complete Payment</Typography.Title>
      </>}
    />
  </>
}

const MerchantDetailsCard = ({ session }: {session: PaylinkHostedPaymentSession_Shape}) => {
  const tableData = useMemo(() => {
    if (!session) return []
    return [
      {key: 'Merchant ID', value: session.merchantId},
      {key: 'Location ID', value: session.locationId},
      {key: 'Gateway ID', value: session.gatewayId},
      {key: 'Amount', value: session.amount},
      {key: 'Payment Type', value: session.paymentType},
      {key: 'Apply Surcharge?', value: session.surcharge.toString()},
    ]
  }, [session])

  const columns = useMemo(() => {
    return [
      {
        key: 'key',
        dataIndex: 'key'
      },
      {
        value: 'value',
        dataIndex: 'value'
      }
    ]
  }, [])

  return <Card title='Payment Details'>
    <Table dataSource={tableData} columns={columns} showHeader={false} pagination={false} />
  </Card>
}

const PaylinkHostedPaymentInner = () => {
  const [ form ] = useForm<PaylinkHostedPaymentForm_Shape>()

  const session = useSessionData()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ submit, setSubmit ] = useState<boolean>(false)

  const submitPaymentForm = useCallback((values: PaylinkHostedPaymentForm_Shape) => {
    setSubmit(true)
  }, [])

  const handleTokenReceived = useCallback((token: any) => {
    console.log(token)
  }, [])

  if (!session) return <></>
  return <>
    <Spin spinning={loading}>
      <Form form={form} component={false} onFinish={(values) => submitPaymentForm(values)}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <HPPHeader />
          </Col>

          <Col xs={24}>
            <MerchantDetailsCard session={session} />
          </Col>

          <Col xs={24}>
            <Card title='Payment Method'>
              <PaylinkIFrame key='' merchantId={session.merchantId} gatewayId={session.gatewayId} submit={submit} onTokenReceived={handleTokenReceived} />
            </Card>
          </Col>

          <Col xs={24}>
            <Card title='Billing Address'>
              <AddressForm path={[] as string[]} />
            </Card>
          </Col>

          <Col xs={24}>
            <Button type='primary' block icon={<DollarOutlined />} onClick={_ => form.submit()}>Pay</Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  </>
}

export const PaylinkHostedPaymentView = () => {
  const { loaded } = usePaylinkExternalDependencies()

  if (!loaded) return <></>
  return <>
    <Layout
      style={{minHeight: '100vh'}}
    >
      <Layout.Content style={{minHeight: 'unset'}}>
        <ErrorBoundary>
          <Suspense fallback={<Loading />}>
            <PaylinkHostedPaymentInner />
          </Suspense>
        </ErrorBoundary>
      </Layout.Content>
    </Layout>
  </>
}