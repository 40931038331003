import { DollarOutlined } from "@ant-design/icons"
import { Button, Card, Col, message, Popconfirm, Result, Row, Space, Spin, Table, Typography } from "antd"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { humanize } from "models/models"
import { PaymentStatus } from "models/paylink"
import { useCallback, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { usePaylinkMerchantContext } from "../PaylinkMerchantContext"

const { Text, Title } = Typography

export const PaymentView = () => {
  const { merchant, payments, gateways, customers, reloadPayments } = usePaylinkMerchantContext()
  const { capturePayment, voidPayment, refundPayment } = usePaylinkAPI()

  const mid = merchant!.id!
  const { pid } = useParams<{pid: string}>()
  const payment = (payments || []).find(p => p.id === pid)
  const gateway = useMemo(() => !!payment ? (gateways || []).find(g => g.id === payment.gatewayId) : undefined, [payment, gateways])
  const customer = useMemo(() => !!payment ? (customers || []).find(c => c.id === payment.customerId) : undefined, [payment, customers])

  const loading = useMemo(() => payment === undefined, [payment])

  const refresh = useCallback(() => {
    reloadPayments(mid)
  }, [reloadPayments, mid])

  const doCapture = useCallback(() => {
    message.info({content: 'Capturing payment...', key: 'capture'})
    capturePayment(mid, pid)
      .catch(e => undefined)
      .then(_ => {
        message.success({content: 'Payment captured successfully!', key: 'capture'})
      })
      .catch(e => {
        message.error({content: 'Payment could not be captured', key: 'capture'})
      })
      .then(refresh)
  }, [mid, pid])

  const doVoid = useCallback(() => {
    message.info({content: 'Voiding payment...', key: 'void'})
    voidPayment(mid, pid)
      .then(_ => {
        message.success({content: 'Payment voided successfully!', key: 'void'})
      })
      .catch(e => {
        message.error({content: 'Payment could not be voided', key: 'void'})
      })
      .then(refresh)
  }, [mid, pid])

  const doRefund = useCallback(() => {
    message.info({content: 'Refunding payment...', key: 'refund'})
    refundPayment(mid, pid)
      .then(_ => {
        message.success({content: 'Payment refunded successfully!', key: 'refund'})
      })
      .catch(e => {
        message.error({content: 'Payment could not be refunded', key: 'refund'})
      })
      .then(refresh)
  }, [mid, pid])

  return <Spin spinning={loading}>
    {!!payment && <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Result
                title={<Title level={2}>{payment.id}</Title>}
                icon={<DollarOutlined />}
                extra={<Space size='middle'>
                  {payment.paymentStatus === PaymentStatus.AUTHORIZED
                    ? <Button type='primary' onClick={doCapture}>Capture</Button>
                    : <></>}
                  {payment.paymentStatus !== PaymentStatus.VOIDED
                    ? <Popconfirm onConfirm={doVoid} title='Are you sure you want to void this transaction?'>
                        <Button>Void</Button>
                      </Popconfirm>
                    : <></>}
                  {payment.paymentStatus === PaymentStatus.CAPTURED
                    ? <Popconfirm onConfirm={doRefund} title='Are you sure you want to refund this transaction?'>
                        <Button>Refund</Button>
                      </Popconfirm>
                    : <></>}
                </Space>}
              />
            </Col>

            <Col span={24}>
              <Card title='Details'>
                <Table
                  columns={[
                    {
                      key: 'key',
                      dataIndex: 'key',
                      width: '50%',
                      render: (value) => <Text strong>{value}</Text>
                    },
                    {
                      key: 'value',
                      dataIndex: 'value',
                      width: '50%',
                    }
                  ]}
                  showHeader={false}
                  pagination={false}
                  dataSource={[
                    ...(!!gateway ? [{key: 'Gateway', value: <Text>{gateway.name} <Text type='secondary'>({gateway.id})</Text></Text>}] : []),
                    ...(!!customer ? [{key: 'Customer', value: <Link to={`/pl/${mid}/customers/${customer.id}`}>{customer.firstName} {customer.lastName}</Link>}] : []),
                    {
                      key: payment.card ? 'Card' : 'Bank Account Number',
                      value: payment.card?.cardNumber ?? payment.bankAccount?.accountNumber ?? 'n/a'
                    },
                    {key: 'Subtotal', value: (payment.captureAmount / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
                    {key: 'Surcharge', value: ((payment.surchargeAmount || 0) / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
                    {key: 'Total', value: ((payment.captureAmount + (payment.surchargeAmount || 0)) / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})},
                    {key: 'Created At', value: humanize(payment.creationMoment)},
                    {key: 'Modified At', value: humanize(payment.modificationMoment)},
                    {key: 'Status', value: payment.paymentStatus},
                    {key: 'Type', value: payment.paymentType},
                    {key: 'Invoice Number(s)', value: payment.invoiceNumbers?.length ? payment.invoiceNumbers : 'n/a'},
                    {key: 'Purchase Order(s)', value: payment.purchaseOrderNumbers?.length ? payment.purchaseOrderNumbers : 'n/a'},
                  ]}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>}
  </Spin>
}
