import { useAuth0 } from "@auth0/auth0-react"
import { useCasbinAPI } from "./useCasbinAPI"
import { useEffect, useMemo, useState } from "react"

type CasbinGrant = [string, string, string]

export const useProducts = () => {
  const { user } = useAuth0()
  const { getUserGrants } = useCasbinAPI()

  const [ loading, setLoading ] = useState<boolean>(true)
  const [ grants, setGrants ] = useState<CasbinGrant[] | undefined>(undefined)

  const loaded = useMemo(() => !!grants, [grants])

  const adminGrants = useMemo(() => !!grants ? grants.filter(g => g[1].includes('*')) : undefined, [grants])
  const surchargingGrants = useMemo(() => !!grants ? grants.filter(g => !g[1].includes('*') && ['owner', 'manager', 'viewer'].some(r => g[2].includes(r))) : undefined, [grants])
  const paylinkGrants = useMemo(() => !!grants ? grants.filter(g => g[2].includes('pay_link') && !g[1].includes('*')) : undefined, [grants])

  const hasSurcharging = useMemo(() => !!adminGrants && !!surchargingGrants && (!!adminGrants.length || !!surchargingGrants.length), [adminGrants, surchargingGrants])
  const hasPaylink = useMemo(() => !!adminGrants && !!paylinkGrants && (!!adminGrants.length || !!paylinkGrants.length), [adminGrants, paylinkGrants])
  const noProducts = useMemo(() => !hasSurcharging && !hasPaylink, [hasSurcharging, hasPaylink])

  useEffect(() => {
    if (!user) return
    setLoading(true)
    const uid = user.sub!
    getUserGrants(uid)
      .then(r => {
        setGrants(r.casbin[0][uid])
        setLoading(false)     
      })
  }, [getUserGrants, user])

  return { loading, loaded, adminGrants, surchargingGrants, paylinkGrants, hasSurcharging, hasPaylink, noProducts }
}
