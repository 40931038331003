/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import _ from "lodash"
import { match, P } from "ts-pattern"
import { ComplianceStatus } from "./audit"
import {env2} from "../utils/env2";
import { accumulate, emptyFeeData, FeeData, FeeDataRecord } from "./feedata";
import { CardContext } from "components/Processor/ProcessorCore";
import dayjs, { Dayjs } from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import duration from 'dayjs/plugin/duration'

dayjs.extend(isSameOrBefore)
dayjs.extend(dayOfYear)
dayjs.extend(duration)

export interface Brand {
  name: string,
  rate: number,
  fix: number
}

export interface Country {
  id: string,
  irate: number,
  ifix: number
}

export interface CardType {
  id: string,
  rate: number,
  fix: number,
  brand: Array<Brand>
}

export interface Fee {
  id: string,
  fix?: number,
  rate?: number,
  qualifier?: string,
}

export interface FixedFees {
  id: string,
  fix: number
}

export interface TieredFees {
  id: string,
  fees: Array<Fee>
}

export interface InterchangeFees {
  id: string,
  fees: Array<Fee>
}

export interface ProgramRateOverride {
  cardRate: number,
  cardFix: number,

  rangeFloor?: number,
  rangeCeiling?: number,

  cardCategory: CardBrand,
  cardProgram: string,
  cardType: string,

  commercial?: boolean
}

export interface InterchangePrograms {
  id: string,
  program: string,
  commercial?: boolean,
  programType?: CardContext,
  rateOverride: ProgramRateOverride[],
}

export enum CardBrand {
  DEFAULT = 'base',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  DISCOVER = 'discover',
  AMEX = 'amex',
}

export enum RuleType {
  STANDARD_COUPONS = 'STANDARD_COUPONS',
  PREMIUM_CUSTOMER = 'PREMIUM_CUSTOMER',
  AGE_BASED = 'AGE_BASED',
  MAX_FEES = 'MAX_FEES',
  MAX_RATE = 'MAX_RATE',
  LINEAR_PRICING = 'LINEAR_PRICING',
  DISCOUNT_TO_MAX = 'DISCOUNT_TO_MAX',
  CARD_CATEGORY_TO_ZERO = 'CARD_CATEGORY_TO_ZERO',
  CARD_BRAND_TO_ZERO = 'CARD_BRAND_TO_ZERO',
  COUNTRY_CODE_DISALLOWED = 'COUNTRY_CODE_DISALLOWED',
  MAX_RATE_THEN_STANDARD_COUPONS = 'MAX_RATE_THEN_STANDARD_COUPONS',
  STANDARD_COUPONS_THEN_MAX_RATE = 'STANDARD_COUPONS_THEN_MAX_RATE',
  CUSTOM = 'CUSTOM',
  GLOBAL = 'GLOBAL',
  RESET = 'RESET'
}

const {VISA, MASTERCARD, DISCOVER, AMEX} = CardBrand
export const allCardBrands = [VISA, MASTERCARD, DISCOVER, AMEX]
export const allCardBrandsWithDefault = [CardBrand.DEFAULT, ...allCardBrands]

export enum RuleSet {
  FIXED_FEE = "FIXED_FEE",
  INTERCHANGE = "INTERCHANGE",
  INTERCHANGE_AVG_BRAND = "INTERCHANGE_AVG_BRAND",
  INTERCHANGE_AVG = "INTERCHANGE_AVG",
  INTERCHANGE_AVG_PRODUCT_LEVEL = "INTERCHANGE_AVG_PRODUCT_LEVEL",
  TIERED = "TIERED"
}

export enum SurchargeType {
  ENTERPRISE = "ENTERPRISE",
  LEGACY_BASIS_POINTS = "LEGACY_BASIS_POINTS",
  TRANSACTION_AMOUNT_PERCENTAGE = "TRANSACTION_AMOUNT_PERCENTAGE",
  SAVINGS_PERCENTAGE = "SAVINGS_PERCENTAGE",
  SAVINGS_PERCENTAGE_PLUS = "SAVINGS_PERCENTAGE_PLUS",
  COUNT = "COUNT",
  FIXED = "FIXED",
  NONE = "NONE",
  VOLUME = "VOLUME"
}

export enum PaymentMethod {
  VENMO = "Venmo",
  PAYPAL = "PayPal",
  ACH = "ACH",
  WIRE = "Wire",
  OTHER = "Other"
}

const {FIXED_FEE, INTERCHANGE, INTERCHANGE_AVG_BRAND, INTERCHANGE_AVG, INTERCHANGE_AVG_PRODUCT_LEVEL, TIERED} = RuleSet
export const allInterchangeRules = [INTERCHANGE, INTERCHANGE_AVG_BRAND, INTERCHANGE_AVG, INTERCHANGE_AVG_PRODUCT_LEVEL]
export const allRules = [...allInterchangeRules, FIXED_FEE, TIERED]
export const allBaseRules = [FIXED_FEE, INTERCHANGE, TIERED]
const RuleSet_Data = {
  [FIXED_FEE]: {
    name: 'Fixed'
  },
  [INTERCHANGE]: {
    name: 'Interchange'
  },
  [TIERED]: {
    name: 'Tiered'
  },
  [INTERCHANGE_AVG_BRAND]: {
    name: 'Interchange Brand Averaging'
  },
  [INTERCHANGE_AVG]: {
    name: 'Interchange Averaging'
  },
  [INTERCHANGE_AVG_PRODUCT_LEVEL]: {
    name: 'Interchange Product-Level Averaging'
  }
}
export const getRuleSetName = (rs: RuleSet | undefined) => !!rs ? RuleSet_Data[rs].name : ''

export enum Persona {
  DIRECT = "DIRECT",
  RESELLER = "RESELLER",
  RESELLER_MANAGED = "RESELLER_MANAGED",
  RESELLER_SMB = "RESELLER_SMB",
  REFERRAL = "REFERRAL",
  GO = "GO",
  PAYLINK = "PAYLINK",
}
const {DIRECT, RESELLER, RESELLER_MANAGED, RESELLER_SMB, REFERRAL, GO, PAYLINK} = Persona
export const allPersonas = [ DIRECT, RESELLER, RESELLER_MANAGED,  RESELLER_SMB, REFERRAL, GO, PAYLINK ]
const Persona_Data = {
  [DIRECT]: { name: 'Direct' },
  [RESELLER]: { name: 'Reseller' },
  [RESELLER_MANAGED]: { name: 'Reseller (Managed)'},
  [RESELLER_SMB]: { name: 'Reseller (SMB)'},
  [REFERRAL]: { name: 'Referral' },
  [GO]: { name: 'Go' },
  [PAYLINK]: { name: 'PayLink' },
}
export const getPersonaName = (p: Persona) => Persona_Data[p].name

export enum ContactProfile {
  COLLECTIVE_OWNERS = "COLLECTIVE_OWNERS",
  COLLECTIVE_MANAGERS = "COLLECTIVE_MANAGERS",
  COLLECTIVE_PRIMARY_CONTACT = "COLLECTIVE_PRIMARY_CONTACT",
  MERCHANT_OWNERS = "MERCHANT_OWNERS",
  MERCHANT_MANAGERS = "MERCHANT_MANAGERS",
  MERCHANT_PRIMARY_CONTACT = "MERCHANT_PRIMARY_CONTACT"
}
const { COLLECTIVE_OWNERS, COLLECTIVE_MANAGERS, COLLECTIVE_PRIMARY_CONTACT, MERCHANT_OWNERS, MERCHANT_MANAGERS, MERCHANT_PRIMARY_CONTACT } = ContactProfile
export const allContactProfiles = [ COLLECTIVE_OWNERS, COLLECTIVE_MANAGERS, COLLECTIVE_PRIMARY_CONTACT, MERCHANT_OWNERS, MERCHANT_MANAGERS, MERCHANT_PRIMARY_CONTACT ]
const ContactProfile_Data = {
  [COLLECTIVE_OWNERS]: { name: 'Collective Owners' },
  [COLLECTIVE_MANAGERS]: { name: "Collective Managers" },
  [COLLECTIVE_PRIMARY_CONTACT]: { name: "Collective Primary Contact" },
  [MERCHANT_OWNERS]: { name: "Merchant Owners" },
  [MERCHANT_MANAGERS]: { name: "Merchant Managers" },
  [MERCHANT_PRIMARY_CONTACT]: { name: "Merchant Primary Contact" }
}
export const getContactProfileName = (c: ContactProfile) => ContactProfile_Data[c].name

export const allProcessors = [
  {name: 'Adyen'},
  {name: 'Chase'},
  {name: 'Checkout.com'},
  {name: 'Fiserv'},
  {name: 'Elavon'},
  {name: 'Global Payments (TSYS, EVO, etc.)'},
  {name: 'Stripe'},
  {name: 'Worldpay'},
]

export const allAcquirers = [
  {name: 'Adyen'},
  {name: 'American Express'},
  {name: 'Bank of America'},
  {name: 'BBVA'},
  {name: 'BMO'},
  {name: 'Citibank'},
  {name: 'Citizens'},
  {name: 'PNC'},
  {name: 'Regions'},
  {name: 'Fifth Third'},
  {name: 'JP Morgan Chase'},
  {name: 'Fiserv'},
  {name: 'Moneris'},
  {name: 'Nuvei (Paya)'},
  {name: 'RBC'},
  {name: 'US Bank'},
  {name: 'Elavon'},
  {name: 'Global Payments (TSYS, Heartland, EVO)'},
  {name: 'PayPal'},
  {name: 'Square'},
  {name: 'Stripe'},
  {name: 'Wells Fargo'},
  {name: 'Worldpay'},
]

export enum Jurisdiction {
  USA = "USA",
  CAN = "CAN",
  ALL = "ALL"
}
const {USA, CAN, ALL} = Jurisdiction
export const allJurisdictions = [
  USA,
  CAN
]
const Jurisdiction_Data = {
  [USA]: { name: 'USA' },
  [CAN]: { name: 'Canada' },
  [ALL]: { name: 'All Jurisdictions' }
}
export const getJurisdictionName = (p: Jurisdiction) => Jurisdiction_Data[p].name

export type Region = {
  key: string,
  name: string,
  abbreviation: string,
  jurisdiction: Jurisdiction,
  surchargeProhibited: boolean,
}
const addKeyToRegion = (region: Omit<Region, 'key'>) => {
  return {...region, key: region.abbreviation}
  // return {...region, key: `${region.jurisdiction}-${region.abbreviation}`} // TODO doesn't play well with the existing backend model, but may be required when we have abbreviation overlaps during i8n efforts
}

// https://interpayments.atlassian.net/browse/PRO-4195
const envAsBoolean = new RegExp(env2("ZERO_PERCENT_STATES_REGEX", ""))

/**
 * Take the default model value however allow a regex override if it exits.  This will
 * allow a runtime addition of a state (e.g., when a law goes into effect)
 * @param region
 */
const applyRestrictions = (region: Omit<Region, 'key'>) => {
  const regexOverride = env2("ZERO_PERCENT_STATES_REGEX")
  const prohibited = (regexOverride) ? !!region.abbreviation.match(envAsBoolean) || region.surchargeProhibited : region.surchargeProhibited
  return {...region, surchargeProhibited: prohibited }
}

export const allRegions: Region[] = [
  { jurisdiction: Jurisdiction.USA, name: 'Alabama', abbreviation: 'AL', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Alaska', abbreviation: 'AK', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Arizona', abbreviation: 'AZ', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Arkansas', abbreviation: 'AR', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'California', abbreviation: 'CA', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Colorado', abbreviation: 'CO', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Connecticut', abbreviation: 'CT', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Delaware', abbreviation: 'DE', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Florida', abbreviation: 'FL', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Georgia', abbreviation: 'GA', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Hawaii', abbreviation: 'HI', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Idaho', abbreviation: 'ID', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Illinois', abbreviation: 'IL', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Indiana', abbreviation: 'IN', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Iowa', abbreviation: 'IA', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Kansas', abbreviation: 'KS', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Kentucky', abbreviation: 'KY', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Louisiana', abbreviation: 'LA', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Maine', abbreviation: 'ME', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Maryland', abbreviation: 'MD', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Massachusetts', abbreviation: 'MA', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Michigan', abbreviation: 'MI', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Minnesota', abbreviation: 'MN', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Mississippi', abbreviation: 'MS', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Missouri', abbreviation: 'MO', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Montana', abbreviation: 'MT', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Nebraska', abbreviation: 'NE', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Nevada', abbreviation: 'NV', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'New Hampshire', abbreviation: 'NH', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'New Jersey', abbreviation: 'NJ', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'New Mexico', abbreviation: 'NM', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'New York', abbreviation: 'NY', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'North Carolina', abbreviation: 'NC', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'North Dakota', abbreviation: 'ND', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Ohio', abbreviation: 'OH', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Oklahoma', abbreviation: 'OK', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Oregon', abbreviation: 'OR', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Pennsylvania', abbreviation: 'PA', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Rhode Island', abbreviation: 'RI', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'South Carolina', abbreviation: 'SC', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'South Dakota', abbreviation: 'SD', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Tennessee', abbreviation: 'TN', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Texas', abbreviation: 'TX', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Utah', abbreviation: 'UT', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Vermont', abbreviation: 'VT', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Virginia', abbreviation: 'VA', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Washington', abbreviation: 'WA', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'D.C.', abbreviation: 'DC', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'West Virginia', abbreviation: 'WV', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Wisconsin', abbreviation: 'WI', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Wyoming', abbreviation: 'WY', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.USA, name: 'Guam', abbreviation: 'GU', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Northern Mariana Islands', abbreviation: 'MP', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Puerto Rico', abbreviation: 'PR', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'US Minor Outlying Islands', abbreviation: 'UM', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Virgin Islands', abbreviation: 'VI', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Micronesia', abbreviation: 'FM', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Palau', abbreviation: 'PW', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'American Samoa', abbreviation: 'AS', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Armed Forces Pacific', abbreviation: 'AP', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Marshall Islands', abbreviation: 'MH', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.USA, name: 'Armed Forces Europe', abbreviation: 'AE', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.CAN, name: 'Alberta', abbreviation: 'AB', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'British Columbia', abbreviation: 'BC', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Manitoba', abbreviation: 'MB', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'New Brunswick', abbreviation: 'NB', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Newfoundland and Labrador', abbreviation: 'NL', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Northwest Territories', abbreviation: 'NT', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Nova Scotia', abbreviation: 'NS', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Nunavut', abbreviation: 'NU', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Ontario', abbreviation: 'ON', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Prince Edward Island', abbreviation: 'PE', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Quebec', abbreviation: 'QC', surchargeProhibited: true },
  { jurisdiction: Jurisdiction.CAN, name: 'Saskatchewan', abbreviation: 'SK', surchargeProhibited: false },
  { jurisdiction: Jurisdiction.CAN, name: 'Yukon', abbreviation: 'YT', surchargeProhibited: false }
].map(applyRestrictions).map(addKeyToRegion)
export const legallyProhibitedRegions: Set<string> = new Set<string>(allRegions.filter(region => region.surchargeProhibited).map(region => region.abbreviation))

type JurisdictionRegionMap = {[Jurisdiction in keyof Jurisdiction]: Region[]}
export const regionsByJurisdiction: JurisdictionRegionMap = allRegions.reduce((o, r) => {
  if (!Object.keys(o).includes(r.jurisdiction)) {
    return {...o, [r.jurisdiction]: [r]}
  }
  o[r.jurisdiction].push(r)
  return o
}, {} as JurisdictionRegionMap)

export enum AveragingPeriod {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
}
const {DAILY, MONTHLY, QUARTERLY, YEARLY} = AveragingPeriod
export const allAveragingPeriods = [ DAILY, MONTHLY, QUARTERLY, YEARLY ]
const AveragingPeriod_Data = {
  [DAILY]: { name: 'Daily' },
  [MONTHLY]: { name: 'Monthly' },
  [QUARTERLY]: { name: 'Quarterly'},
  [YEARLY]: { name: 'Yearly' },
}
export const getAveragingPeriodName = (p: AveragingPeriod) => AveragingPeriod_Data[p].name

export enum AveragingType {
  NONE = 'none',
  BRAND = 'brand',
  GLOBAL = 'global',
  PRODUCT_LEVEL = 'product_level',
}
const { NONE, BRAND, GLOBAL, PRODUCT_LEVEL } = AveragingType
export const allAveragingTypes = [NONE, GLOBAL, PRODUCT_LEVEL]
export const allBrandLevelAveragingTypes = [NONE, GLOBAL]
export const allProductLevelAveragingTypes = [NONE, PRODUCT_LEVEL]
export const allAdminAveragingTypes = [NONE]
export const allVisibleAveragingTypesForMerchant = (merchant: Merchant | undefined, isAdmin: boolean = false) => {
  const brandLevel = isAdmin ? allBrandLevelAveragingTypes : allBrandLevelAveragingTypes.filter(t => !allAdminAveragingTypes.includes(t))
  const productLevel = isAdmin ? allProductLevelAveragingTypes : allProductLevelAveragingTypes.filter(t => !allAdminAveragingTypes.includes(t))
  if (merchant === undefined) return brandLevel
  return merchant.terms?.interchangeType === InterchangeType.PRODUCT_LEVEL ? productLevel : brandLevel
}
const AveragingType_Data = {
  [NONE]: {
    name: 'No Averaging'
  },
  [BRAND]: {
    name: 'Per-Brand Averaging'
  },
  [GLOBAL]: {
    name: 'Brand-Level Averaging'
  },
  [PRODUCT_LEVEL]: {
    name: 'Product-Level Averaging'
  }
}
export const getAveragingTypeName = (at: AveragingType) => AveragingType_Data[at].name

export type SimpleFee = {
  rate: number,
  fix: number,
}

export type PerCardFee = {
  base?: SimpleFee,
  visa?: SimpleFee,
  mastercard?: SimpleFee,
  discover?: SimpleFee,
  amex?: SimpleFee,
}

export type GenericFee = SimpleFee | PerCardFee

export const isSimpleFee = (fee: GenericFee): fee is SimpleFee => {
  const keys = Object.keys(fee)
  return keys.includes('rate') && keys.includes('fix')
}

export const isPerCardFee = (fee: GenericFee): fee is PerCardFee => {
  const keys = Object.keys(fee)
  return keys.includes('base') && !isSimpleFee(fee)
}

export interface FeeBucket {
  name: string,
  fee: GenericFee
}

export enum RateOverrideExpirationRule {
  NEVER = 'never',
  AUTO = 'auto',
  CUSTOM = 'custom'
}

export type FeeConfig = {
  fix?: number,
  rate?: number,
  rateExpiration?: string,
  averagingPeriod?: AveragingPeriod,
  rateTransient?: boolean,
  fixCap?: number,
  rateCap?: number,
  notifiedOn?: string,
}

export type FeeConfigs = {
  convenienceFeeConfig?: FeeConfig,
  serviceFeeConfig?: FeeConfig,
  rentalCredit?: FeeConfig,
  rentalDebit?: FeeConfig
}

export interface ProductLevelNextAvgRates {
  consumer1AvgRate?: number,
  consumer2AvgRate?: number,
  consumer3AvgRate?: number,
  consumer4AvgRate?: number,
  business1AvgRate?: number,
  business2AvgRate?: number,
  business3AvgRate?: number,
  business4AvgRate?: number,
  business5AvgRate?: number,
  corporate1AvgRate?: number,
  corporate2AvgRate?: number,
  corporate3AvgRate?: number
}

export interface Processor {
  id?: string,
  name: string,
  ruleSet: string,
  mid: string,
  rate: number,
  fix: number,
  fixFees: Array<FixedFees>,
  fixedFees: Array<Fee>,
  tieredFees: Array<TieredFees>,
  interchangeFees: Array<InterchangeFees>,
  interchangePrograms: Array<InterchangePrograms>,
  status?: string,
  rateExpiration?: string,
  lastAverageDate?: string,
  averagingPeriod?: string,
  rateTransient?: boolean,
  limitedAcceptance?: boolean,
  feeConfigs?: FeeConfigs,
  // hidden fields
  notifiedOn?: string,
  nextAverageRate?: number,
  nextProductLevelAvgRate?: ProductLevelNextAvgRates,
  lastMaximizationAudit?: string
}

export interface ProcessorSource {
  name: string,
  buckets: Array<FeeBucket>,
  bucketsDebit?: Array<FeeBucket>,
  naicsCode?: string,
  sicCode?: string,
  mccCode?: string,
  isoName?: string,
  id?: string,
  processorName: string,
  gatewayName: string,
  payfacName?: string,
}

export type FullProcessor = Processor & ProcessorSource

export type InterchangeProgramMetadata = [ string, string, string ]

export interface MerchantResource {
  id: string,
  link: string,
  merchant: Merchant
}

export type AcquirerReport = {
  result: MerchantAcquirerReport[],
}

export type MerchantAcquirerReport = {
  id: string,
  name: string,
  organizationName: string | null,
  dba: string | null,
  status: SurchargeStatus,
  collectiveId: string | null,
  surchargeEligibleDate: string | null,
  complianceStatus: ComplianceStatus,
  isSurchargeEligible: boolean,
  isActive: boolean,
  hasBusinessRule: boolean,
  ruleDefinedMaxRate: number | null,
  notificationOn: string | null,
  activeOn: string | null,
  processor: ProcessorAcquirerReport[],
}

export type ProcessorAcquirerReport = {
  id: string,
  name: string,
  currentRate: number | null,
  isOverride: boolean,
  averagingPeriod: AveragingPeriod,
  rateExpiration: string | null,
  notifiedOn: string | null,
  nextRate: number | null,
  expireStrategy: RateOverrideExpirationRule | null,
}

export interface User {
  userId: string,
  email: string,
  emailVerified: boolean,
  name: string,
  picture: string,
  nickname: string
}

export interface Invite {
  token: string,
  email: string,
  roles: Set<UserRole>,
  createdAt: string,
  active: boolean,
}

export interface Collective {
  id?: string,
  name: string,
  status?: string,
  contact?: Contact,
  terms?: Terms,
  invites?: Array<Invite>
  users?: Array<User>
  config?: Map<string, string>
  invoiceSupport?: InvoiceSupport,
  rules?: Array<Rule>,
}

export interface ProcessorContact {
  name?: string,
  contact?: Contact,
  mid?: string,
  notificationOn?: string
}

// do we need any additional payload?
//export interface Token {
//}

export interface Rule {
  id: string,
  priority: number,
  rule: string,
  params: Map<string, string>,
  exclusive: boolean,
  enabled: boolean
}

export interface InvoiceSupport {
  linkInvoicingCustomerId?: string,
  linkInvoicingBankAccountId?: string,
  serviceFeeTerm: string,
  serviceFeeClass: string,
  operationalId: string
}

export interface Merchant {
  id?: string,
  name: string,
  status?: string,
  organization?: Organization,
  contact?: Contact,
  processor: Array<Processor>,
  processorSource: Array<ProcessorSource>,
  log?: Array<any>,
  rules?: Array<Rule>,
  rule?: string,
  active: boolean,
  collectiveId?: string,
  terms?: Terms,
  processorContact?: ProcessorContact,
  tokens: Array<string>,
  invites?: Array<Invite>,
  users?: Array<User>,
  blacklist: Array<string>,
  config?: Map<string, string>,
  invoiceSupport?: InvoiceSupport,
  webhooks?: Webhook[]
}

export interface Organization {
  name: string,
  contact?: Contact,
  orgType: string,
  dba?: string,
  website?: string,
  billingContact?: Contact
}

export enum InterchangeType {
  BRAND_LEVEL = "BRAND_LEVEL_INTERCHANGE",
  PRODUCT_LEVEL = "PRODUCT_LEVEL_INTERCHANGE"
}

export const getInterchangeTypeName = (value: InterchangeType) => {
  if (value === InterchangeType.BRAND_LEVEL) return "Brand-Level Surcharging"
  else if (value === InterchangeType.PRODUCT_LEVEL) return "Product-Level Surcharging"
  return "Unknown"
}

export interface Terms {
  contractIntroDays: number,
  contractStart: string,
  contractActiveOn: string,
  contractTermDays: number,
//  highRisk: boolean,
  surchargeFix: number | string,
  surchargeInclude: boolean,
  surchargeRate: number | string,
  surchargeType: SurchargeType,
  activeOn?: string,
  invoiceType?: string,
  persona?: string,
  jurisdiction?: string,
  interchangeType: InterchangeType,
}

export interface Address {
  street: string,
  city: string,
  state: string,
  zip: string,
  name?: string,
  country?: string
}

export interface Contact {
  name: string,
  address?: Address,
  email?: string,
  phone?: string,
  title?: string
}

export interface TxRequest {
  nicn?: string
  processor?: string
  amount?: number
  totalAmount?: number
  country?: string
  region?: string
  campaign?: string
  data?: Array<string>
  sTxId?: string
  mTxId?: string
  cardToken?: string
  createdAt: string
  callerData?: Array<string>
  op?: string,
  message?: string
}

export interface RuleAdjusted {
  transactionFee?: number
  serviceFee?: number
  serviceFeeMaxed?: number
  enterpriseServiceFee?: number
  code?: string
  message?: string
}

type ProcessorLookup = {
  processorDefinitionId: string,
}

type TxLogSupport = {
  processorLookup?: ProcessorLookup,
}

export interface TxResponse {
  transactionFeeMaxed?: number,
  transactionFeeBase?: number,
  transactionFeeForAvg?: number,
  serviceRate?: number,
  serviceFee?: number,
  feeHighRisk?: number,
  feeMaxed?: number,
  transactionFee?: number,
  maxRate?: number,
  maxFee?: number,
  processorRate?: number,
  processorFix?: number,
  fixedFee?: number,
  enterpriseServiceFee?: number,
  message?: string,
  status?: string,
  createdAt: string,
  ruleAdjusted?: RuleAdjusted,
  support?: TxLogSupport,
}

export interface BusinessData {
  nicn?: string,
  transactionTotal?: number
  merchantTransactionTotal?: number
  region?: string
  country?: string
  ruleMessage?: string
  kind?: string
  maxFee?: number
  brand?: string
}

export interface CollectiveData {
  collectiveId?: string
  surchargeStatus?: string
  serviceFee?: number
}

export enum TxLogRelationshipReason {
  REFUNDS = 'REFUNDS',
  REFUNDED_BY = 'REFUNDED_BY',
}

export type TxLogRelationship = {
  id: string,
  reason: TxLogRelationshipReason,
}

type TxLogMessages = {
  regionRestricted: string,
  cardKindRestricted: string,
  ruleRestricted: string,
  ruleMessage: string,
}

export interface TxLog {
  id: string,
  mTxId?: string,
  cardToken?: string,
  merchantId: string,
  merchant: string,
  processorName?: string,
  processorMid?: string,
  surchargeStatus: SurchargeStatus,
  productCode: string, // TODO create ProductCode enum
  transactionTotal?: number,
  transactionFeeMaxed?: number,
  serviceFee?: number,
  serviceFeeMaxed?: number,
  serviceFeeHighRisk?: number,
  status: TransactionStatus,
  createdAt: string,
  updatedAt: string,
  completedAt?: string,
  lastFeeRequest?: TxRequest,
  lastFeeResponse?: TxResponse,
  businessData: BusinessData,
  collectiveData: CollectiveData,
  refundRefId?: string,
  relatesTo: TxLogRelationship[],
  messages: TxLogMessages,
}


export interface TxLogResponse {
  id: string,
  merchant: string,
  merchantId: string,
  mTxId?: string,
  cardToken?: string,
  processorMid?: string,
  processorName?: string,
  transactionFee?: number,
  transactionFeeMaxed?: number,
  transactionTotal?: number,
  serviceFee?: number,
  enterpriseServiceFee?: number,
  enterpriseSurchargeStatus?: string,
  surchargeStatus?: string,
  status: string,
  createdAt: string,
  updatedAt: string,
  messages: {
    cardKindRestricted: string,
    regionRestricted: string,
    ruleRestricted: string,
    ruleMessage: string,
  },
  businessData: BusinessData
}

export interface Item {
  id?: string
  name: string,
  price: number
}

export interface Nicn {
  id?: string
  country: string,
  scheme: string,
  kind: string,
  category: string
}

/* Types for Dashboard Chart View */

export type RollupSummaryDate = {day: number, year: number}
export type RollupSummaryCounts = {
  count: number,
  credit: number,
  debit: number,
  totalAmount: number,
  totalServiceFee: number,
  totalTransactionAmount: number,
}

export type RollupSummaryRecord = RollupSummaryCounts & {
  _id: RollupSummaryDate
}

export type DashboardChartEntry = {
  date: string,
  // savings chart
  count: number,
  total: number,
  savings: number,
  // credit/debit chart
  creditCount: number,
  debitCount: number,
}

export type DashboardData = {
  count: number,
  creditCount: number,
  debitCount: number,
  total: number,
  savings: number,
  chart: DashboardChartEntry[],
}

export const fetchDashboardData = (getData: (range: [Dayjs, Dayjs]) => Promise<FeeDataRecord[]>, range: [Dayjs, Dayjs]): Promise<RollupSummaryRecord[]> => {
  type FeeDataRecordByRollupSummaryDate = Omit<FeeDataRecord, 'businessDay'> & {_id: RollupSummaryDate}

  const retypeDate = (value: FeeDataRecord): FeeDataRecordByRollupSummaryDate => {
    const { businessDay, ...rest } = value
    const date = parseDateAgnostic(businessDay)
    const year = date.get('year')
    const day = date.dayOfYear() + 1
    const _id: RollupSummaryDate = {year, day}
    return {...rest, _id}
  }

  const emptyRollupByDay = (range: [Dayjs, Dayjs]) => {
    const [ start, end ] = range
    if (end.isBefore(start)) return {}
    let rollup = {}
    let current = start.clone().startOf('day')
    while (current.isSameOrBefore(end)) {
      const year = current.get('year')
      const month = current.get('month') + 1
      const day = current.get('day') + 1
      rollup[serializeDate({year, day})] = emptyFeeData
      current = current.add(1, 'day')
    }
    return rollup
  }

  const reduceByDay = (accumulator: {[key: string]: FeeData}, record: FeeDataRecordByRollupSummaryDate): {[key: string]: FeeData} => {
    const { _id, ...rest } = record
    const recordKey = serializeDate(_id)
    if (accumulator[recordKey] === undefined) return {...accumulator, [recordKey]: rest}
    else return {...accumulator, [recordKey]: accumulate(accumulator[recordKey], rest)}
  }

  const serializeDate = (value: RollupSummaryDate): string => `${value.year},${value.day}`
  const deserializeDate = (value: string): RollupSummaryDate => {
    const [ year, day ] = value.split(',').map(e => Number(e))
    return {year, day}
  }

  const feeDataToSummary = (value: FeeData): RollupSummaryCounts => {
    const count = value.counts.count
    const credit = value.counts.credit
    const debit = value.counts.debit
    const totalAmount = value.sums.transactionFeeMaxed
    const totalServiceFee = value.sums.serviceFee
    const totalTransactionAmount = value.sums.transactionAmount
    return { count, credit, debit, totalAmount, totalServiceFee, totalTransactionAmount }
  }

  return getData(range)
    .then(v => v.map(retypeDate))
    .then(v => v.reduce(reduceByDay, emptyRollupByDay(range)) as {[key: string]: FeeData})
    .then(v => Object.entries(v).map(([serializedDate, data]) => {
      return {_id: deserializeDate(serializedDate), ...feeDataToSummary(data)}
    }))
}

export enum AbstractDateRange {
  MONTH_TO_DATE = 'MTD',
  QUARTER_TO_DATE = 'QTD',
  YEAR_TO_DATE = 'YTD',
  CUSTOM = 'CUSTOM'
}

export const allAbstractDateRanges = [AbstractDateRange.MONTH_TO_DATE, AbstractDateRange.QUARTER_TO_DATE, AbstractDateRange.YEAR_TO_DATE, AbstractDateRange.CUSTOM]

export type DashboardRangeWithoutCustom = {
  range: Exclude<AbstractDateRange, AbstractDateRange.CUSTOM>,
}

export type DashboardRangeWithCustom = {
  range: AbstractDateRange.CUSTOM,
  dates: [Dayjs, Dayjs],
}

export type DashboardRange = DashboardRangeWithoutCustom | DashboardRangeWithCustom

export enum DashboardTabInner_ComponentState {
  LOADING,
  LOADED
}

export enum UserRole {
  VIEWER = 'viewer',
  MANAGER = 'manager',
  OWNER = 'owner',
  ADMIN = 'admin',
  PAYLINK_ADMIN = 'pay_link_admin',
  PAYLINK_PAYER = 'pay_link_payer',
  PAYLINK_ACCOUNTING = 'pay_link_accounting'
}

export const allGrantableRoles = [UserRole.VIEWER, UserRole.MANAGER, UserRole.OWNER, UserRole.PAYLINK_PAYER, UserRole.PAYLINK_ACCOUNTING, UserRole.PAYLINK_ADMIN]
export const allRoles = [...allGrantableRoles, UserRole.ADMIN]

export const savingsChartFormat = {
  xField: 'date',
  yField: ['count', 'savings'],
  meta: {
    count: {
      alias: '# of Transactions',
      min: 0,
    },
    savings: {
      alias: 'Savings',
      min: 0,
      formatter: (s: number) => ('$' + s.toFixed(2))
    },
  },
  xAxis: {
    nice: false,
  },
  yAxis: {},
  geometryOptions: [
    {
      geometry: 'line',
      smooth: true,
    },
    {
      geometry: 'line',
      smooth: true,
    }
  ]
}

export const savingsHistogramFormat = {
  xField: 'date',
  yField: 'savings',
  meta: {
    savings: {
      alias: 'Savings',
      min: 0,
      formatter: (s: number) => s.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
    }
  }
}

export const countHistogramFormat = {
  xField: 'date',
  yField: 'count',
  color: 'rgb(90, 216, 166)',
  meta: {
    count: {
      alias: '# of Transactions',
      min: 0,
    }
  }
}

export const cardTypeHistogramFormat = {
  isStack: true,
  xField: 'date',
  yField: 'count',
  seriesField: 'series',
}

export const cardTypeLineChartFormat = {
  xField: 'date',
  yField: ['creditCount', 'debitCount'],
  meta: {
    creditCount: {
      alias: '# of Credit Transactions',
      min: 0,
    },
    debitCount: {
      alias: '# of Debit Transactions',
      min: 0,
    },
  },
  xAxis: {
    nice: false,
  },
  yAxis: {},
  geometryOptions: [
    {
      geometry: 'line',
      smooth: true,
    },
    {
      geometry: 'line',
      smooth: true,
    }
  ]
}

export const cardTypePieChartFormat = {
  angleField: 'value',
  colorField: 'type',
  radius: 0.6,
  legend: false as false, // needs 'as false' or else will fail typecheck
  label: {
    type: 'spider',
    labelHeight: 20,
    content: '{name}\n{percentage}',
  },
  interactions: [{type: 'element-active'}],
}

export const DataFormat = {
  ANY: 'any',
  NUMBER: {
    INTEGER: 'integer',
    PERCENT: 'percent',
    CURRENCY: 'currency',
  },
  BOOLEAN: 'boolean',
  DATE: 'date',
  ENUM: {
    SURCHARGE_TYPE: 'surchargeType', // used in contract terms
    SURCHARGE_STATUS: 'surchargeStatus',
    TRANSACTION_STATUS: 'txStatus',
    USER_ROLE: 'userRole',
  },
}

export const SurchargeTypeColorMap = new Map([
  ['ENTERPRISE', 'red'],
  ['LEGACY_BASIS_POINTS', 'blue'],
])

export const SurchargeStatusFilter = [
  { text: 'Free Trial', value: 'FREE_TRIAL' },
  { text: 'Active', value: 'ACTIVE' },
  { text: 'Testing', value: 'TESTING' },
  { text: 'Provisional', value: 'PROVISIONAL' },
  { text: 'Inactive', value: 'INACTIVE' },
  { text: 'Deleted', value: 'DELETED' },
]

export enum SurchargeStatus {
  NOT_ENABLED = "Not Enabled",
  ENABLED = "Enabled",
  FREE_TRIAL = "Free Trial",
  TESTING = "Testing",
  PROVISIONAL = "Provisional",
  ACTIVE = "Active",
  DELETED = "Deleted",
}

export enum TransactionStatus {
  CANCELLED = "CANCELLED",
  REFUNDED = "REFUNDED",
  TRANSIENT = "TRANSIENT",
  ASSIGNED = "ASSIGNED",
  AUTHORIZED = "AUTHORIZED",
  CAPTURED = "CAPTURED",
  COMPLETED = "COMPLETED",
  EXCEPTION = "EXCEPTION",
  DECLINED = "DECLINED",
}

const { CANCELLED, REFUNDED, TRANSIENT, ASSIGNED, AUTHORIZED, CAPTURED, COMPLETED, EXCEPTION, DECLINED } = TransactionStatus

export const SurchargeStatusColorMap = new Map([
  ['NOT_ENABLED', 'red'],
  ['ENABLED', 'blue'],
  ['FREE_TRIAL', 'blue'],
  ['TESTING', 'blue'],
  ['PROVISIONAL', 'gold'],
  ['ACTIVE', 'green'],
])

export const TransactionStatusColorMap = new Map([
  [TRANSIENT, 'orange'],
  [ASSIGNED, 'orange'],
  [CAPTURED, 'gold'],
  [COMPLETED, 'green'],
  [DECLINED, 'red'],
])

export const UserRoleColorMap = new Map([
  ["VIEWER", "green"],
  ["ADMIN", "red"],
  ["MANAGER", "blue"]
])

export const AllTxStatus = ['Cancelled', 'Refunded', 'Transient', 'Assigned', 'Authorized', 'Captured', 'Completed', 'Exception', 'Declined']
export const AllTxProductCodes = ['TF', 'SF', 'CF'] // Didn't add BI since it's in another collection (binlog)
export const IncompleteTxStatus = ['Transient', 'Assigned', 'Authorized', 'Captured']
export const RefundableTxStatus = ['Completed']

/* Form Layout Data */
export const formLayout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16},
}

export const formLayoutCompact = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
}

export const tailLayout = {
  wrapperCol: {offset: 8, span: 16},
}

export const fullWidthLayout = {
  wrapperCol: {span: 24},
}

/* Form Field Length Limits - should match FormUtil.scala */

export const DefaultNameMaxLength = 50
export const DefaultEmailMaxLength = 100
export const LongNameMaxLength = 200
export const ExtraLongNameMaxLength = 1000

/* Form Field Length Validator Rule */

export const maxLengthRule = (length: number) => ({
  message: `Length cannot exceed ${length} characters`,
  validator: (_, value: string): Promise<void> => {
    if (typeof value === 'string' && value.length > length) return Promise.reject()
    return Promise.resolve()
  }
})

export const onlyAllowedCharactersChecker = (value: any): boolean => {
  if (typeof value !== 'string' || value === '') return true
  const result = value.match(/[a-z0-9\-.,_:;'#() ]+/gi)
  return !!result && result[0] === value
}

export const onlyAllowedCharactersMessage = `Cannot contain characters other than alphanumeric characters, periods, commas, apostrophes, hyphens, underscores, colons, semicolons, number signs, or parentheses`

export const onlyAllowedCharactersRule = () => ({
  message: onlyAllowedCharactersMessage,
  validator: (_, value: string): Promise<void> => {
    const result = onlyAllowedCharactersChecker(value)
    if (result) return Promise.resolve()
    return Promise.reject()
  }
})

const ZipCodePlusFour = /^[0-9]{5}(?:-[0-9]{4})?$/
const CanadaPostalCode = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i // from https://stackoverflow.com/a/46761018
export const validPostalCodeRule = () => ({
  message: 'Please enter a valid postal code',
  validator: (_, value: string): Promise<void> => {
    if (typeof value !== 'string' || value === '') return Promise.resolve()
    if (!!value.match(ZipCodePlusFour) || !!value.match(CanadaPostalCode)) return Promise.resolve()
    return Promise.reject()
  }
})

export const merchantNameValidator = {
  validator: (rule, value) => {
    if (!value) return Promise.resolve()
    else {
      if ((value as string).trim().match(/^[a-z0-9-\.,_:#();-]+$/)) return Promise.resolve()
      else return Promise.reject('Please enter a valid name (see tooltip)')
    }
  }
}

export const nonZeroRule = () => ({
  message: 'Value must be greater than 0',
  validator: (_, value: string): Promise<void> => {
    if (typeof value === 'number' && value === 0) return Promise.reject()
    if (typeof value === 'string' && Number(value) === 0) return Promise.reject()
    return Promise.resolve()
  }
})

export const roundToPrecision = (value: number, digits: number, asString: boolean = false) => {
  const multiplier = Math.pow(10, digits) || 1 // round to whole digits if no precision is specified
  const roundedValue = Math.round(value * multiplier) / multiplier
  return asString ? roundedValue.toFixed(digits) : roundedValue
}

export const lowercase = (text: string) => text.toLowerCase()
export const uppercase = (text: string) => text.toUpperCase()

export function capitalizeAndReplaceUnderscores(input: string): string {
  return input
      .split('_') // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(' '); // Join with spaces
}

// splits by spaces, capitalizes each word - good for tags, not so much for paragraphs
export const capitalize = (text: string) => text.split(' ').map(w => w.slice(0, 1).toUpperCase() + w.slice(1).toLowerCase()).reduce((last, next) => last !== '' ? last.concat(' ').concat(next) : next, '')
// lifted directly from stackoverflow (https://stackoverflow.com/a/39510222)
export const titleCase = (text: string) => _.startCase(_.lowerCase(text))

export const equalsIgnoreCase = (s1: string, s2: string) => lowercase(s1) === lowercase(s2)
export const includesIgnoreCase = (corpus: string, query: string) => lowercase(corpus).includes(lowercase(query))

export function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export function guid() {
  return s4() + s4() // + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

const typeCache: { [label: string]: boolean } = {};
export function type<T>(label: T | ''): T {
  if (typeCache[<string>label]) {
    throw new Error(`Action type "${label}" is not unique"`);
  }

  typeCache[<string>label] = true;

  return <T>label;
}

export interface Transaction {
  id?: string
  sTxId: string
  mTxId: string
  items: Array<Item>
}

export interface Webhook {
  id: string
  eventType: string
  url: string,
  enabled: boolean
}

export interface NicnRecord {
  id: string | undefined,
  cardBrand: string | undefined,
  cardType: string | undefined,
  cardProgram: string | undefined,
  cardCategory: string | undefined,
  commercial: boolean | string | undefined
}

export interface CommonBin {
  id: string | undefined,
  cardBrand: string | undefined,
  cardType: string | undefined,
  cardProgram: string | undefined,
  cardCategory: string | undefined,
  iso3: string | undefined,
  commercial: boolean | string | undefined,
  prepaid: boolean | string | undefined,
  source: boolean | string | undefined,
}

export type DiffResultResponse = Array<[string, number]>

export type BinRecord = {
  bin: string,
  id: string,
  brand: string,
  country: string,
  isDebit: boolean,
  isDowngraded: boolean,
  issuer: string,
  kind: string,
  program: string,
}

export type BinResponse = {
  id: string,
  data: BinRecord[]
}

export const httpsValidator = (rule, value, callback) => {
  if (value && !/^https:\/\//.test(value)) {
    return Promise.reject('Please enter a valid URL starting with https://')
  } else {
    return Promise.resolve()
  }
}

export function getArrayHead<T>(list: T[]): T | undefined {
  if (list.length > 0) {
    return list[0];
  } else {
    return undefined;
  }
}

export const isHookDisabled = (wid: string, webhooks: Webhook[]) => {
  const hook = getArrayHead(webhooks.filter(x => x.id === wid))
  // If we don't have a hook registered with the wid (shouldn't happen) we default to false
  if (hook !== undefined) { return hook.enabled } else return false
}

export const serializeObjectToURLParams = (params: any) => {
  const result: Array<string> = []
  if (Object.keys(params).length > 0) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const keyValue = params[key]
        if (keyValue !== null) {
          switch (keyValue.constructor.name) {
            case 'Array':
              if (keyValue.length > 0) {
                keyValue.forEach((kv:any) => {
                  result.push(`${encodeURIComponent(key)}=${encodeURIComponent(kv)}`)
                })
              }
              break
            case 'Object':
              (<any>Object).entries(keyValue).map(([k, v]: [string, any]) => {
                if (v) {
                  result.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
                }
              })
              break
            default:
              result.push(`${encodeURIComponent(key)}=${encodeURIComponent(keyValue)}`)
          }
        }
      }
    }
    return result.join('&')
  } else {
    return result
  }
}

export const arrayToUrlParams = (arrayName: string, values: Array<string>) => {
  return values.map(v => `&${arrayName}=${v}`).join('')
}

// Date/Time types

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

// Date/Time functions

// if backend is being weird, we want d.$date.$numberLong, else we can just parse d
export const parseDateAgnostic = (d:any) => (d.$date ? dayjs(parseInt(d.$date.$numberLong)) : dayjs(d))

export const isDateInFuture = (current: Dayjs, rangeValues: [Dayjs, Dayjs]) => {
  if (!rangeValues) return false
  return current.isAfter()
}

export const areDatesWithinRange = (current: Dayjs, rangeValues: RangeValue, rangeInDays: number) => {

  if (!rangeValues) return false

  const tooLate = rangeValues[0] && current.diff(rangeValues[0], 'days') > rangeInDays
  const tooEarly = rangeValues[1] && rangeValues[1].diff(current, 'days') > rangeInDays

  return !!tooEarly || !!tooLate
}

export const humanize = (c:string | Dayjs) => {
  let then = dayjs(c)
  return then.format("ddd, MMM Do, h:mm:ss a")
}

export const formatDuration = (endTime: Dayjs, startTime: Dayjs) => {
  let parts: string[] = []
  const duration = dayjs.duration(endTime.diff(startTime))

  // return nothing when the duration is falsy or not correctly parsed (P0D)
  if(!duration || duration.toISOString() === "P0D") return;

  if(duration.years() >= 1) {
      const years = Math.floor(duration.years())
      parts.push(years+" "+(years > 1 ? "years" : "year"))
  }

  if(duration.months() >= 1) {
      const months = Math.floor(duration.months())
      parts.push(months+" "+(months > 1 ? "months" : "month"))
  }

  if(duration.days() >= 1) {
      const days = Math.floor(duration.days())
      parts.push(days+" "+(days > 1 ? "days" : "day"))
  }

  if(duration.hours() >= 1) {
      const hours = Math.floor(duration.hours());
      parts.push(hours+" "+(hours > 1 ? "hours" : "hour"))
  }

  if(duration.minutes() >= 1) {
      const minutes = Math.floor(duration.minutes())
      parts.push(minutes+" "+(minutes > 1 ? "minutes" : "minute"))
  }

  if(duration.seconds() >= 1) {
      const seconds = Math.floor(duration.seconds())
      parts.push(seconds+" "+(seconds > 1 ? "seconds" : "second"))
  }

  // Only display ms if there is nothing else to show
  if(!parts.length) {
    if (duration.milliseconds() >= 1) {
      const milliseconds = Math.floor(duration.milliseconds())
      parts.push(milliseconds+" "+(milliseconds > 1 ? "milliseconds" : "millisecond"))
    }
  }

  return parts.join(", ")
}

// For theming FeeServ/BinServ
export enum PortalTheme {
  DEFAULT,
  BINSERV,
  FEESERV,
  SIGNUP,
  AUDIT_PORTAL,
  ADMIN_PORTAL
}

// Get human-readable name for merchant display
export const getMerchantDisplayName = (merchant: Merchant) => {
  return match([merchant.organization?.dba, merchant.organization?.name, merchant.name])
    .with([P.string, P.string, P._], (v) => {
      const dba: string = v[0] as string
      const orgName: string = v[1] as string
      return dba.concat(' (').concat(orgName).concat(')')
    })
    .with([P._, P.string, P._], (v) => v[1])
    .with([P._, P._, P.string], (v) => v[2])
    .otherwise(() => 'Unknown Merchant')
}

// Make internal name from an organization name
export const makeInternalName = (name: string) => {
  // can have lowercase letters, numerics, dashes, and periods
  const nonAllowedCharacters = /[^a-z0-9-.]/g
  return name
    .toLowerCase() // no capital letters allowed
    .replaceAll(' ', '-') // turn spaces to dashes
    .replace(nonAllowedCharacters, '') // remove extraneous punctuation
}

export const makeMerchantInternalName = (orgName: string, dba: string | undefined = undefined) => makeInternalName(dba === undefined ? orgName : dba)

// This is affected by backend configuration and is currently uncoupled - this value needs to change if the backend does
export const getContactProfilesFromPersona = (persona: Persona | undefined) => match(persona)
  .with(Persona.RESELLER_MANAGED, () => new Set<ContactProfile>([ContactProfile.COLLECTIVE_PRIMARY_CONTACT]))
  .otherwise(() => new Set<ContactProfile>([ContactProfile.MERCHANT_OWNERS]))

// Set operations

export const union = <T extends any>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const _union = new Set(setA);
  for (const elem of setB) {
    _union.add(elem);
  }
  return _union;
}

export const intersection = <T extends any>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const _intersection = new Set<T>();
  for (const elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

export const difference = <T extends any>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

export const symmetricDifference = <T extends any>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const _difference = new Set(setA);
  for (const elem of setB) {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    } else {
      _difference.add(elem);
    }
  }
  return _difference;
}

export type AllKeysOf<T> = { [P in keyof Required<T>]: true }

export const keyByIndex = (values: object[]) => values.map((v, i) => ({...v, key: i}))

export const pluralize = ({ count, singular, plural }: {count: number, singular: string, plural: string}) => count === 1 ? singular : plural

export const formatURIDatetime = (date: Dayjs) => encodeURIComponent(date.format('YYYY-MM-DDTHH:mm:ssZ'))
export const formatURIDate = (date: Dayjs) => encodeURIComponent(date.format('YYYY-MM-DD'))

export const withBackoff = async <T extends unknown>(f: () => Promise<T>, config: { count: number, delay: number, multiplier: number }) => {
  const { count, delay, multiplier } = config
  let response

  console.log('[withBackoff] Running initial')
  try {
    response = await f()
  } catch { }
  console.log('[withBackoff] f() returned', response)
  if (response) return response

  let backoffCount = count - 1
  let backoffDelay = delay
  while (backoffCount > 0) {
    console.log(`[withBackoff] Running with delay ${backoffDelay}ms`)
    const run = () => {
      return new Promise<T>((res, rej) => {
        const g = () => f()
          .then(r => res(r))
          .catch(_ => rej())
        setTimeout(g, backoffDelay)
      })
    }
    try {
      response = await run()
    } catch { }
    console.log('[withBackoff] f() returned', response)
    if (response) return response
    backoffCount -= 1
    backoffDelay *= multiplier
  }

  return Promise.reject()
}

// Type safe filtering

export const filterFalse = <T extends unknown>(a: T | false): a is T => a !== false
export const filterNull = <T extends unknown>(a: T | null): a is T => a !== null
export const filterUndefined = <T extends unknown>(a: T | undefined): a is T => a !== undefined
export const filterNullish = <T extends unknown>(a: T | null | undefined): a is T => a !== null && a !== undefined

export const UseProcessorView = env2('REACT_APP_PROCESSOR_VIEW') === 'true'