/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import {AuthenticatedBaseService} from "./base-service"
import {Collective, Merchant, MerchantResource, RuleType, TxLog, UserRole} from "../models/models"

import qs from 'qs'
import {guid} from "../utils/higher-order-components";
import {env2} from "../utils/env2";
import { FilterProps } from "utils/txlogHelper";

const RemoteApiClientId = env2("REACT_APP_REMOTE_API_CLIENT_ID", "no-REMOTE_API_CLIENT_ID")

export class MerchantService extends AuthenticatedBaseService {

  async isReady() {
    const h = await this.getAuthHeaders()
    // TODO... sometimes service isn't ready because idToken hasn't been resolved... why???
    // console.log("sometimes service isn't ready because idToken hasn't been resolved... why???")
    // console.log("h ", !!h.headers.authorization, h.headers.authorization)
    return (!!h.headers.authorization)
  }

  // TODO Support API Role.Admin
  async getUsers(): Promise<any[]> {
    console.log("getUsers")
    const url = this.apiUrl(`/v1/e/users`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async getUser(uid: string): Promise<any> {
    console.log("getUser")
    const url = this.apiUrl(`/v1/e/users/${uid}`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Admin
  async getMerchants(): Promise<any[]> {
    console.log("getMerchants")
    const url = this.apiUrl(`/v1/merchant?status=Active`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Admin
  async getAllMerchants(): Promise<any[]> {
    console.log("getMerchants (admin)")
    const url = this.apiUrl(`/v1/merchant?surchargeStatus=any`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async getMyCollectives(): Promise<any[]> {
    console.log("getMyCollectives")
    const url = this.apiUrl(`/v1/collective`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async getMyCollective(cid: string): Promise<Collective> {
    console.log("getMyCollective")
    const url = this.apiUrl(`/v1/collective/${cid}`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async getWhoAmI(): Promise<any[]> {
    console.log('getWhoAmI')
    const url = this.apiUrl(`/v1/collective/whoami`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async getCollectiveRollupMetadata(cid: string, attributes: any): Promise<any> {
    console.log('getCollectiveRollupMetadata')
    const params = qs.stringify({
      ...attributes,
    }, { arrayFormat: 'repeat' })
    const url = this.apiUrl(`/v1/collective/${cid}/txlog/summary/period-metadata?${params}`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async getCollectiveDashboard(data: any = {}): Promise<any[]> {
    console.log('getCollectiveDashboard')
    const url = this.apiUrl(`/v1/collective/dashboard?${this.objectToQueryString(data)}`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Viewer
  async getMerchant(mid: string): Promise<MerchantResource> {
    console.log("getMerchant")
    const url = this.apiUrl(`/v1/merchant/${mid}`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus<MerchantResource>(rsp)
  }

  // TODO Merchant API Role.Viewer
  async getInvoices(mid: string, start: string, end: string): Promise<any> {
    console.log("getInvoices")
    const params = qs.stringify({
      start: start,
      end: end,
    })
    const url = this.apiUrl(`/v1/merchant/${mid}/invoice?${params}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus<any>(rsp)
  }

  // TODO Merchant API Role.Viewer
  // Formats can be "csv", "pdf", or "csv-credits"
  async getInvoiceArtifact(mid: string, invoiceId: string, format: string = "csv"): Promise<any> {
    console.log("getInvoiceArtifact")
    const isCsv = format.includes("csv")
    const params = qs.stringify({
      ...(format === 'csv-credits' ? {format: 'credits'} : {format: 'default'})
    })
    const url = this.apiUrl(`/v1/merchant/${mid}/invoice/${invoiceId}?${params}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders({
        // TODO need to support pdf here too for what user wants
        // Accept: 'application/pdf'
        Accept: isCsv ? 'text/csv' : 'application/pdf'
      })
    })
    return isCsv ? rsp.text() : rsp.arrayBuffer() // this.checkJsonStatus(rsp)
  }

  async listBucket(mid: string): Promise<any> {
    const url = this.apiUrl(`/v1/merchant/${mid}/ch/list`)
    const rsp = await fetch(url, {
      method: "GET",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  async getBulkTxArtifact(mid: string, fileName: string, format: string = 'csv'): Promise<any> {
    const isCsv = format.includes("csv")
    const url = this.apiUrl(`/v1/merchant/${mid}/ch/bulk?fileName=${fileName}`)
    const rsp = await fetch(url, {
      method: 'GET',
      cache: "no-store",
      ...await this.getAuthHeaders({
        Accept: 'text/csv'
      })
    })
    return isCsv ? rsp.text() : rsp.arrayBuffer()
  }

  async getMerchantRollupMetadata(mid: string, attributes: any): Promise<any> {
    console.log('getMerchantRollup')
    const params = qs.stringify({
      ...attributes,
    }, { arrayFormat: 'repeat' })
    const url = this.apiUrl(`/v1/merchant/${mid}/txlog/summary/period-metadata?${params}`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO move to support or internal
  // TODO Merchant API Role.Admin
  async createMerchant(m: Merchant): Promise<any> {
    console.log("createMerchant")
    const url = this.apiUrl(`/v1/merchant`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(m),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  async disableRule(mid: string, ruleType: RuleType): Promise<any> {
    console.log("disableRule", ruleType)
    return this.enableRule(mid, {
      ruleType,
      enable: false,
      data: {}
    });
  }

  async enableRule(mid: string, data: any): Promise<any> {
    console.log("enableRule")
    const url = this.apiUrl(`/v1/merchant/${mid}/rule`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkStatus(rsp, 204)
  }

  // TODO Merchant API Role.Manager
  async limitedUpdateMerchant(m: Merchant): Promise<any> {
    console.log("limitedUpdateMerchant")
    const url = this.apiUrl(`/v1/merchant/${m.id}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(m),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO move to support or internal
  // TODO Support API Role.Admin
  async supportUpdateMerchant(m: Merchant): Promise<any> {
    console.log("supportUpdateMerchant")
    const url = this.apiUrl(`/v1/support/merchant/${m.id}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(m),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Manager
  async setActive(mid: string): Promise<any> {
    console.log("setActive")
    const url = this.apiUrl(`/v1/merchant/${mid}/status/active`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkStatus(rsp, 204)
  }

  // TODO Merchant API Role.Manager
  async setInactive(mid: string): Promise<any> {
    console.log("setInactive")
    const url = this.apiUrl(`/v1/merchant/${mid}/status/inactive`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkStatus(rsp, 204)
  }

  // TODO Merchant API Role.Manager
  async createProcessorForMerchant(mid: string, processor: any): Promise<any> {
    console.log("createProcessorForMerchant")
    const url = this.apiUrl(`/v1/merchant/${mid}/processor/sf`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(processor),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async supportCreateProcessorForMerchant(mid: string, processor: any): Promise<any> {
    console.log("createProcessorForMerchant")
    const url = this.apiUrl(`/v1/support/merchant/${mid}/processor/sf`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(processor),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async supportDeleteMerchant(mid: string): Promise<any> {
    console.log("delete merchant")
    const url = this.apiUrl(`/v1/merchant/${mid}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }

  // TODO Support API Role.Admin
  async supportCollectiveUnassignMerchant(cid: string, mid: string): Promise<any> {
    console.log("unassign merchant")
    const url = this.apiUrl(`/v1/support/collective/${cid}/merchant/${mid}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }


  // TODO Merchant API Role.Owner
  async deleteProcessor(mid: string, pid: string): Promise<any> {
    console.log("deleteProcessor")
    const url = this.apiUrl(`/v1/merchant/${mid}/processor/${pid}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }

  // TODO Merchant API Role.Owner
  async removeMerchant(mid: string): Promise<any> {
    console.log("removeMerchant")
    const url = this.apiUrl(`/v1/merchant/${mid}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }

  // TODO... server is returning an MerchantResource entities.  For now, just appease
  // TODO Collective API Role.Viewer
  async getCollectiveMerchants(cid: string): Promise<any> {
    console.log("getCollectiveMerchants")
    const url = this.apiUrl(`/v1/collective/${cid}/merchant?status=Active`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async getExportArtifact(id: string): Promise<any> {
    console.log("getExportArtifact")
    const url = this.apiUrl(`/v1/support/txlog/${id}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return rsp.text()
  }

  // TODO Support API Role.Admin
  async getCollectives(): Promise<any[]> {
    console.log("getCollectives")
    const url = this.apiUrl(`/v1/support/collective?status=Active`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async createCollective(c: Collective): Promise<any[]> {
    console.log("createCollective")
    const url = this.apiUrl(`/v1/support/collective?status=Active`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(c),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async limitedUpdateCollective(c: Collective): Promise<any[]> {
    console.log("limitedUpdateCollective")
    const url = this.apiUrl(`/v1/collective/${c.id}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(c),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  // currently called from CollectiveView for Managers and up
  async supportUpdateCollective(c: Collective): Promise<any[]> {
    console.log("supportUpdateCollective")
    const url = this.apiUrl(`/v1/support/collective/${c.id}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(c),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async deleteCollective(cid: string): Promise<any> {
    console.log("deleteCollective")
    const url = this.apiUrl(`/v1/collective/${cid}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async assignCollective(cid: string, mid: string): Promise<any[]> {
    console.log("assignCollective")
    const emptyBody = JSON.stringify({})

    const url = this.apiUrl(`/v1/support/collective/${cid}/merchant/${mid}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: emptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  // note... object permissions have a prefix.  in this case "collective:"
  async addPermissionToUser(scope: string | undefined, cid: string, u: string, action: string = "viewer"): Promise<any[]> {
    console.log("assignCollective")
    const emptyBody = JSON.stringify({})

    const obj = cid
    const sub = u
    const act = action

    const url = (scope) ?
      this.apiUrl(`/v1/e/users/${sub}/resource/${scope}:${obj}/permission/${act}`) :
      this.apiUrl(`/v1/e/users/${sub}/resource/${obj}/permission/${act}`)

    const rsp = await fetch(url, {
      method: "POST",
      body: emptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async removeUserPermission(u: string, o: string, a: string): Promise<any[]> {
    console.log("removeUserPermission")
    const url = this.apiUrl(`/v1/e/users/${u}/resource/${o}/permission/${a}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin (should be any authenticated user)
  async getUserObjectPermissions(o: string): Promise<any> {
    console.log('getUserObjectPermissions')
    const url = this.apiUrl(`/v1/e/users/resource/${o}/permission`)
    const rsp = await fetch(url, {
      method: "GET",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async removeUser(u: string): Promise<any[]> {
    console.log("removeUser")
    const url = this.apiUrl(`/v1/e/users/${u}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async deleteInvite(mid: string, token: string): Promise<any[]> {
    console.log("delete invite")
    const url = this.apiUrl(`/v1/merchant/${mid}/user/invite/${token}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Manager
  async removeMerchantUser(mid: string, userId: string, role: string): Promise<any[]> {
    console.log("removeUser")
    const url = this.apiUrl(`/v1/merchant/${mid}/user/${userId}/${role}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async resetUserPassword(u: string): Promise<any[]> {
    console.log("resetUserPassword")
    const emptyBody = JSON.stringify({})
    const url = this.apiUrl(`/v1/support/user/${u}/reset-password`)
    const rsp = await fetch(url, {
      method: "POST",
      body: emptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  async reset2fa(u: string): Promise<any[]> {
    console.log("reset2fa")
    const emptyBody = JSON.stringify({})
    const url = this.apiUrl(`/v1/support/user/${u}/reset-2fa`)
    const rsp = await fetch(url, {
      method: "POST",
      body: emptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async addUserToGroup(u: string, g: string): Promise<any[]> {
    console.log("addUserToGroup")
    const emptyBody = JSON.stringify({})
    const obj = g
    const sub = u
    const url = this.apiUrl(`/v1/e/users/${sub}/resource/${obj}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: emptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Viewer
  async getCollective(cid: string): Promise<Collective> {
    console.log("getCollective")
    // const url = this.apiUrl(`/v1/support/collective/${cid}`)
    const url = this.apiUrl(`/v1/collective/${cid}`)
    const rsp = await fetch(url, {
      cache: "default",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO TransactionFee API Role.Manager
  async generateClientToken(merchantId: any, body: any): Promise<any> {
    const url = this.apiUrl(`/v1/merchant/${merchantId}/token`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO TransactionFee API Role.Manager
  async generateTransientToken(merchantId: any, body: any): Promise<any> {
    const url = this.apiUrl(`/v1/merchant/token/${merchantId}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async getAnalyticsAccessToken(): Promise<any> {
    console.log("getAnalyticsAccessToken")
    const url = this.apiUrl(`/v1/support/ga`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Viewer
  async findLogs(merchantId: any, skip: any, limit: any, attributes: FilterProps): Promise<any> {
    const params = qs.stringify({
      ...attributes,
      limit: limit,
      skip: skip
    }, { arrayFormat: 'repeat' })
    const url = this.apiUrl(`/v1/merchant/${merchantId}/txlog?${params}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return (attributes.format === "csv" || attributes.format === "csv-remote") ? rsp.text() : this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Manager
  async loadTestData(mid: string, data: any): Promise<any[]> {
    console.log("load test-data")
    const url = this.apiUrl(`/v1/merchant/${mid}/testdata`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  async pushNotification(data: any): Promise<any[]> {
    const url = this.apiUrl(`/v1/support/user/notification`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Manager
  async deleteTestData(mid: string, key: string): Promise<any[]> {
    console.log("load test-data")
    const url = this.apiUrl(`/v1/merchant/${mid}/testdata/${key}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  async echo(mid: string): Promise<any[]> {
    console.log("echo")
    const url = this.apiUrl(`/v1/merchant/${mid}/echo`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API
  async supportMerchantInvoiceArtifacts(mid: string, start: string, end: string, audit: boolean, attributes: any): Promise<any> {
    const params = qs.stringify({
      ...attributes,
      mid: mid,
      start: start,
      end: end,
      audit
    })
    const url = this.apiUrl(`/v1/merchant/${mid}/invoice/publish?${params}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API (this is poorly named because it is doing msg->ActorMessage reflection)
  async supportMerchantInvoicePayment(msg: string, start: string): Promise<any> {
    const url = this.apiUrl(`/v1/support/worker/${msg}?start=${start}`)
    const rsp = await fetch(url, {
      method: "GET",
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }

  // TODO Support API
  async supportMerchantGetInvoiceDetails(mid: string, start: string, end: string, credit: boolean): Promise<any> {
    const url = this.apiUrl(`/v1/merchant/${mid}/invoicedetails?start=${start}&end=${end}&mid=${mid}&credit=${credit}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 200).then(_ => rsp.text())
  }

  // TODO Support API
  async supportMerchantInvoice(mid: string, start: string, end: string, audit: boolean, attributes: any): Promise<any> {
    const params = qs.stringify({
      ...attributes,
      mid: mid,
      start: start,
      end: end,
      audit
    })
    const url = this.apiUrl(`/v1/merchant/${mid}/invoice?${params}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Collective API Role.Manager
  async auditCollectiveInvoice(cid: string, start: string, end: string, attributes: any): Promise<any> {
    const params = qs.stringify({
      ...attributes,
      cid: cid,
      start: start,
      end: end,
      audit: true
    })
    const url = this.apiUrl(`/v1/support/collective/${cid}/invoice?${params}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({}),
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async getSupportConfig(): Promise<any[]> {
    const url = this.apiUrl(`/v1/support/config`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Viewer
  async getConfig(mid: string): Promise<any[]> {
    const url = this.apiUrl(`/v1/merchant/${mid}/config`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Manager
  async addConfig(mid:string, data:any): Promise<any> {
    const url = this.apiUrl(`/v1/merchant/${mid}/config`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Manager
  async deleteConfig(mid: string, key: string): Promise<any> {
    console.log("deleteConfig")
    const url = this.apiUrl(`/v1/merchant/${mid}/config/${key}`)
    const rsp = await fetch(url, {
      method: "DELETE",
      ...await this.getAuthHeaders()
    })
    return this.checkStatus(rsp, 204)
  }

  qbGetUrl() {
    const url = this.apiUrl(`/qb`)
    return url
  }

  sfGetUrl() {
    const url = this.apiUrl(`/sf`)
    return url
  }

  // TODO Support API Role.Admin
  async qbResetToken(mid: string, data: string): Promise<any> {
    const url = this.apiUrl(`/v1/merchant/${mid}/qb/token`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async getSupportNicnLoad(audit: boolean, data: any): Promise<any[]> {
    const action = audit ? "audit" : "auto"
    console.log("getSupportNicnLoad", this.objectToQueryString(data))
    const url = this.apiUrl(`/v1/support/bin/${action}?${this.objectToQueryString(data)}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async uploadFromRemote(data: any): Promise<any[]> {
    console.log("uploadFromRemote", this.objectToQueryString(data))
    const url = this.apiUrl(`/v1/support/bin/upload?${this.objectToQueryString(data)}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async getSupportLoadToCommon(audit: boolean, data: any): Promise<any[]> {
    const action = "stage"
    console.log("getSupportLoadToCommon", this.objectToQueryString(data))
    const url = this.apiUrl(`/v1/support/bin/${action}?${this.objectToQueryString(data)}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async getSupportMaterialize(audit: boolean, data: any): Promise<any[]> {
    const action = "materialize"
    console.log("getSupportMaterialize", this.objectToQueryString(data))
    const url = this.apiUrl(`/v1/support/bin/${action}?${this.objectToQueryString(data)}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async getSupportNicnEdit(): Promise<any[]> {
    const url = this.apiUrl(`/v1/nicn/support/interpayments`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Support API Role.Admin
  async supportFindLogs(skip: any, limit: any, attributes: any): Promise<any> {
    const params = qs.stringify({
      ...attributes,
      limit: limit,
      skip: skip
    }, { arrayFormat: 'repeat' })
    const url = this.apiUrl(`/v1/support/txlog?${params}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return (attributes.format === "csv" || attributes.format === "csv-remote") ? rsp.text() : this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Viewer
  async getTxLog(mid: string, id: string): Promise<TxLog> {
    const url = this.apiUrl(`/v1/merchant/${mid}/txlog/${id}`)
    const rsp = await fetch(url, {
      cache: "no-store",
      ...await this.getAuthHeaders()
    })
    return this.checkJsonStatus(rsp)
  }

  // probably deprecated
  // TODO Collective API Role.Manager
  async createMerchantInCollective(cid: string, m: Merchant): Promise<any> {
    console.log("createMerchant")
    const url = this.apiUrl(`/v1/collective/${cid}/merchant`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(m),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  // TODO Merchant API Role.Manager
  async inviteUser(mid: string, name: string, email: string, roles: Set<UserRole>): Promise<any[]> {
    console.log("inviteUser")
    const notEmptyBody = JSON.stringify({
      email,
      name,
      roles
    })
    const url = this.apiUrl(`/v1/merchant/${mid}/user`)
    const rsp = await fetch(url, {
      method: "POST",
      body: notEmptyBody,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  async inviteRsvp(mid: string, token: string): Promise<any[]> {
    console.log("inviteRsvp")
    const empty = JSON.stringify({})
    const url = this.apiUrl(`/v1/merchant/${mid}/user/invite/${token}`)
    const rsp = await fetch(url, {
      method: "POST",
      body: empty,
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  async apiAuthenticate(token: string, cid: string, comeback: string,
                        clientId?: string, clientSecret?: string,
                        exchange: boolean = false): Promise<any> {
    const csrf = guid()
    // server gets clientId from collective config or from auth0 ... const clientIdQs = false && clientId ? `&clientId=${clientId}` : ""
    // server gets clientSecret from collective config or from auth0 ... const clientSecretQs = false && clientSecret ? `&clientSecret=${clientSecret}` : ""
    // const url = this.apiUrl(`/v1/api/authCodeFlowlogin?_a=${token}&cid=${cid}&exchange=${exchange}&token=${csrf}&comeback=${encodeURIComponent(btoa(comeback))}`)
    const url = this.apiUrl(`/v1/api/${cid}/authorize?_a=${token}&cid=${cid}&exchange=${exchange}&code_challenge=${csrf}&comeback=${encodeURIComponent(btoa(comeback))}`)

    const redirectWithCid = `http://localhost:9000/v1/api/${cid}/callback`
    const unauthorizedUrl = this.apiUrl(`/v1/api/${cid}/authorize?client_id=${clientId}&code_challenge=${csrf}&redirect_uri=${redirectWithCid}`)

    const redirect = "http://localhost:9000/v1/api/callback"
    const unauthorizedDirectUrl = this.apiUrl(`/v1/api/${cid}/authorize?client_id=${clientId}&code_challenge=portal-${csrf}&redirect_uri=${redirect}`)

    return {
      url,
      unauthorizedUrl,
      unauthorizedDirectUrl
    }
  }

  async apiAuthorize(serverUri: string): Promise<any> {
    const url = this.apiUrl(serverUri)
    console.log("apiAuthorize", url)
    return url
  }

  async apiRefreshToken(cid: string, refreshToken: string): Promise<any> {
    console.log("apiRefreshToken")
    const url = this.apiUrl(`/v1/api/authCodeFlowRefreshToken`)
    const rsp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        refreshToken,
        "collectiveId": cid
      }),
      cache: "no-store",
      ...await this.getAuthHeaders({
        "content-type": "application/json"
      })
    })
    return this.checkJsonStatus(rsp)
  }

  async jiraAuthenticate(cid: string, token: string, comeback: string): Promise<any> {
    console.log("apiAuthenticate")
    const csrf = guid()
    const url = this.apiUrl(`/v1/support/jira/login?_a=${token}&cid=${cid}&token=${csrf}&comeback=${encodeURIComponent(btoa(comeback))}`)
    return url
  }


  async remoteAuthenticate(token: string, comeback: string): Promise<any> {
    console.log("remoteAuthenticate")
    const csrf = guid()
    const clientId = RemoteApiClientId
    const url = this.apiUrl(`/v1/support/remoteip/login?_a=${token}&clientId=${clientId}&token=${csrf}&comeback=${encodeURIComponent(btoa(comeback))}`)
    return url
  }


}
