import { Button, Form, Input } from "antd"
import { useForm } from "antd/es/form/Form"
import { formLayout, tailLayout } from "models/models"
import { CustomerRequest } from "models/paylink"

export const CustomerForm = ({ onSubmit }: {onSubmit: (request: CustomerRequest) => void}) => {
  const [ form ] = useForm<CustomerRequest>()

  return <Form form={form} {...formLayout} onFinish={onSubmit}>
    <Form.Item label='First Name' name='firstName' rules={[{required: true}]}>
      <Input />
    </Form.Item>

    <Form.Item label='Last Name' name='lastName' rules={[{required: true}]}>
      <Input />
    </Form.Item>

    <Form.Item label='Company Name' name='companyName' rules={[{required: true}]}>
      <Input />
    </Form.Item>

    <Form.Item label='Email' name='email' rules={[{required: true}]}>
      <Input />
    </Form.Item>

    <Form.Item label='Phone Number' name='phoneNumber' rules={[{required: true}]}>
      <Input />
    </Form.Item>

    <Form.Item label='Merchant Account Number' name='merchantAccountNumber' rules={[{required: true}]}>
      <Input />
    </Form.Item>

    <Form.Item {...tailLayout}>
      <Button htmlType='submit' type='primary'>Submit</Button>
    </Form.Item>
  </Form>
}
