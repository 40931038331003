import { useEffect, useMemo, useState } from "react"
import { usePaylinkAPI } from "./usePaylinkAPI"

export const usePaylinkExternalDependencies = () => {
  const { iFrameLibrarySrc, iFrameStyleSrc } = usePaylinkAPI()

  const [ styleLoaded, setStyleLoaded ] = useState<boolean>(false)
  const [ scriptLoaded, setScriptLoaded ] = useState<boolean>(false)
  const loaded = useMemo(() => styleLoaded && scriptLoaded, [styleLoaded, scriptLoaded])

  useEffect(() => {
    if (!iFrameStyleSrc || !iFrameLibrarySrc) return

    const cssElement = document.createElement('link')
    cssElement.setAttribute('rel', 'stylesheet')
    cssElement.setAttribute('type', 'text/css')
    cssElement.setAttribute('href', iFrameStyleSrc)
    cssElement.addEventListener('load', () => setStyleLoaded(true))
    document.head.appendChild(cssElement)

    const scriptElement = document.createElement('script')
    scriptElement.setAttribute('src', iFrameLibrarySrc)
    scriptElement.addEventListener('load', () => setScriptLoaded(true))
    document.body.appendChild(scriptElement)

    return () => {
      setStyleLoaded(false)
      setScriptLoaded(false)
      cssElement.remove()
      scriptElement.remove()
    }
  }, [iFrameLibrarySrc, iFrameStyleSrc])

  return { loaded }
}