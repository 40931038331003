import React, { useEffect, useState } from 'react'
import { Input, InputNumber, Form, Select, AutoComplete, Radio, Row, Col, Divider, Space, Tooltip, Button, Switch, DatePicker, Typography } from 'antd'
import { FormTip } from '../FormTip'
import {
  allBaseRules,
  allCardBrands,
  allCardBrandsWithDefault,
  allAveragingPeriods,
  AveragingType,
  capitalize,
  CardBrand,
  Fee,
  FeeBucket,
  FixedFees,
  FullProcessor,
  GenericFee,
  getAveragingPeriodName,
  getAveragingTypeName,
  getRuleSetName,
  InterchangePrograms,
  isPerCardFee,
  isSimpleFee,
  PerCardFee,
  ProgramRateOverride,
  RuleSet,
  SimpleFee,
  TieredFees,
  AveragingPeriod,
  titleCase,
  InterchangeProgramMetadata,
  RateOverrideExpirationRule,
  nonZeroRule,
  Merchant,
  allVisibleAveragingTypesForMerchant,
  allProcessors,
  allAcquirers,
} from '../../models/models'
import { FormInstance, Rule } from 'antd/lib/form'
import { ResponsiveColumn } from '../Primitives'
import { FancyCard } from '../Card'
import { InterchangeProgramScheduleProvider, useInterchangeProgramSchedule } from '../../hooks/useInterchangeProgramSchedule'
import Title from 'antd/lib/typography/Title'
import { MinusCircleOutlined, PlusOutlined, QuestionCircleFilled } from '@ant-design/icons'
import _ from 'lodash'
import dayjs, { Dayjs } from 'dayjs'
import { formatISO, parseISO } from 'date-fns'
import { useSupportAPI } from 'hooks/useSupportAPI'
import { ConditionalPopover } from 'components/ConditionalPopover'
import {useAuthorization} from "../../hooks/useAuthorization";

const SchemeFeesV2 = true

// Form Path - attach WithRequiredFormPath to input props to give them {attachTo, name} props for binding with antd forms
type FormPath = {
  attachTo: string[],
  name: string,
}
type WithRequiredFormPath<T> = T & FormPath
type WithOptionalFormPath<T> = T | WithRequiredFormPath<T>
const hasFormPath = (object: WithOptionalFormPath<any>): object is WithRequiredFormPath<any> => {
  const keys = Object.keys(object)
  return keys.includes('attachTo') && keys.includes('name')
}

// UseFormContext - attach to components that need to be aware of an owning form
export type UseFormContext = {
  form: FormInstance,
}

// UseAdminContext - attach to components that need to be aware of admin status on owning merchant
export type UseAdminContext = {
  isAdmin?: boolean,
}

// UseMerchantContext - interchange programs now require a context
export type UseMerchantContext = {
  merchant: Merchant | undefined,
}

// Full Width - for forms using entire width of container
const fullWidth = {offset: 2, span: 20}

// Currency Parser/Formatter
export const commaSeparatedParser = value => value ? value.replace(',', '') : ''
export const commaSeparatedFormatter = (value: number | string | undefined): string => {
  if (value === '' || value === undefined) return ''
  else {
    return value
      .toString()
      .split('.')
      .map((part, index) => index === 0 ? part.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : part) // put commas in integer part of number only
      .join('.');
  }
}

// Rule Set Input
type RuleSetInput_NaiveProps = {
  blacklist?: RuleSet[],
}
type RuleSetInput_Props = WithRequiredFormPath<RuleSetInput_NaiveProps>

type AveragingInput_NaiveProps = {label: string, tip: string, required?: boolean}
type AveragingPeriodInput_Props = WithRequiredFormPath<AveragingInput_NaiveProps>

export const RuleSetInput: React.FC<React.PropsWithChildren<RuleSetInput_Props>> = ({ attachTo = [], name = "rule_set", blacklist = [RuleSet.TIERED] }) => {
  const displayedRules = allBaseRules.filter(rule => !blacklist.includes(rule))
  return (
    <Form.Item
      label={<FormTip label="Processor Pricing Structure" tip="Processor pricing structure defines the type of credit card processing fee charged by the processor, typically either fixed rate or interchange plus" />}
      name={[...attachTo, name]}
      rules={[{required: true, message: 'Please select a processor schema'}]}
    >
      <Select placeholder='Please select an option'>
        {displayedRules.map(rule => (
          <Select.Option key={rule} value={rule}>{getRuleSetName(rule)}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export const AveragingPeriodInput: React.FC<React.PropsWithChildren<AveragingPeriodInput_Props>> = ({ attachTo = [], name = "averagingPeriod", label, tip, required = true }) => {
  const rules = required ? [{required: true, message: 'Please select an averaging period'}] : []
  return (
    <Form.Item
      label={<FormTip label={label} tip={tip} />}
      name={[...attachTo, name]}
      rules={rules}
      initialValue={AveragingPeriod.DAILY}
    >
      <Select placeholder='Please select an option'>
        {allAveragingPeriods.map(rule => (
          <Select.Option key={rule} value={rule}>{getAveragingPeriodName(rule)}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

// Numeric Input Params
const rateInputParams = {
  min: 0,
  step: .05,
}
const fixInputParams = {
  min: 0,
  step: .01,
}

type CustomFormInput_Props = {
  required?: boolean,
  width?: string,
  margin?: string,
}

type RateInput_Props = WithRequiredFormPath<CustomFormInput_Props>
export const RateInput: React.FC<React.PropsWithChildren<RateInput_Props>> = ({ attachTo, name, width = '45%', margin = '0', required = false }) => {
  return <>
    <Form.Item
      name={[...attachTo, name]}
      noStyle
      rules={required ? [{required: true, message: 'Rate is required'}]: []}
    >
      <InputNumber
        style={{width, marginInline: margin}}
        placeholder='0.05'
        addonAfter='%'
        precision={4}
        {...rateInputParams} />
    </Form.Item>
  </>
}

type FixInput_Props = WithRequiredFormPath<CustomFormInput_Props>
export const FixInput: React.FC<React.PropsWithChildren<FixInput_Props>> = ({ attachTo, name, width = '45%', margin = '0', required = false }) => {
  return <>
    <Input.Group compact>
      <Form.Item
        name={[...attachTo, name]}
        noStyle
        rules={required ? [{required: true, message: 'Fixed fee is required'}]: []}
      >
        <InputNumber
          style={{width, marginInline: margin}}
          placeholder='0.10'
          addonBefore='$'
          precision={4}
          {...fixInputParams} />
      </Form.Item>
    </Input.Group>
  </>
}

// Rate + Fix Input
type RatePlusFixInput_NaiveProps = {
  required?: boolean,
  rateRules?: Rule[],
  fixRules?: Rule[],
}
type RatePlusFixInput_Props = WithRequiredFormPath<RatePlusFixInput_NaiveProps>

export const RatePlusFixInput: React.FC<React.PropsWithChildren<RatePlusFixInput_Props>> = ({ attachTo, name, required = false, rateRules = [], fixRules = [] }) => {
  const ratePath = [...attachTo, name, 'rate']
  const fixPath = [...attachTo, name, 'fix']

  return <>
    <Input.Group compact>
      <Form.Item
        name={ratePath}
        noStyle
        rules={required ? [{required: true, message: 'Rate is required'}, ...rateRules] : []}
      >
        <InputNumber
          style={{width: '45%'}}
          placeholder='0.05'
          addonAfter='%'
          precision={4}
          {...rateInputParams} />
      </Form.Item>
      <div style={{width: '10%', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'sub'}}>+</div>
      <Form.Item
        name={fixPath}
        noStyle
        rules={required ? [{required: true, message: 'Fixed fee is required', ...fixRules}]: []}
      >
        <InputNumber
          style={{width: '45%'}}
          placeholder='0.10'
          addonBefore='$'
          precision={4}
          {...fixInputParams} />
      </Form.Item>
    </Input.Group>
  </>
}

// FormItem abstraction for single line form item declarations
type FormItem_Props = {
  itemProps?: any,
  innerProps?: any,
  Inner: any,
}
const FormItem: React.FC<React.PropsWithChildren<FormItem_Props>> = ({ itemProps = {}, innerProps = {}, Inner }) => {
  return <Form.Item {...itemProps}>
    <Inner {...innerProps} />
  </Form.Item>
}
export const FormInput: React.FC<React.PropsWithChildren<any>> = (props) => <FormItem itemProps={props} Inner={Input} />
export const FormAutocomplete: React.FC<React.PropsWithChildren<any>> = ({itemProps, innerProps}) => <FormItem itemProps={itemProps} innerProps={innerProps} Inner={AutoComplete} />

type FormTitle_Props = {title: string, tooltip?: string}
export const FormTitle: React.FC<React.PropsWithChildren<FormTitle_Props>> = ({title, tooltip}) => {
  const titleComponentNaive = <Title level={5}>{title}{!!tooltip ? <>&nbsp;<QuestionCircleFilled /></> : ''}</Title>
  const titleComponent = !!tooltip ? <Tooltip title={tooltip}>{titleComponentNaive}</Tooltip> : titleComponentNaive

  return <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
    {titleComponent}
  </Space>
}

// Generic Brand Form Props - gives a form and brand context
type BrandForm_Props = UseFormContext & {
  brand: CardBrand
}

// Per Transaction Fee Form - brand-scoped input for additional fee to add to transaction (only used for Tiered - may be deprecated)
const PerTxFeeFormComponent: React.FC<React.PropsWithChildren<BrandForm_Props>> = ({ brand }) => {
  const perTxFeeProps = {
    label: <FormTip label='Per Tx Fee' tip="Per transaction fees charged in addition to the interchange fee and the interchange markup for network, auth, etc. Enter dollar value (eg $0.25 = 0.25)" />,
  }
  return <Form.Item
    {...perTxFeeProps}
  >
    <FixInput attachTo={['transactionFees']} name={brand} required={brand === CardBrand.DEFAULT} />
  </Form.Item>
}

// Interchange Program Form - three inputs for consumer, business, and corporate interchange program selection per brand
const InterchangeProgramForm: React.FC<React.PropsWithChildren<InterchangeBrandForm_Props>> = ({ brand, merchant, useSchemeFees }) => {
  const { getPrograms } = useInterchangeProgramSchedule()
  const [ programsList, setProgramsList ] = useState<InterchangeProgramMetadata[]>([])

  useEffect(() => {
    const programs = getPrograms()
    setProgramsList(programs)
  }, [getPrograms])

  return (<>
    {merchant?.terms?.jurisdiction && !!programsList.length && <>
      <Form.Item
          label={<FormTip label='Consumer Program' tip="The Card Networks' interchange rate program a merchant pays to accept consumer credit cards. If you have questions, please contact your InterPayments rep." />}
          name={['interchangePrograms', brand, ProgramType.PRESET, 'consumer']}
          rules={[{required: true, message: `Please enter a consumer program`}]}
      >
        <Select showSearch style={{width: '100%'}}>
          {programsList.filter(e => e[0] === brand.toLowerCase() && e[1] === 'consumer').sort((a, b) => a[2].localeCompare(b[2])).map(e => <Select.Option key={e[2]} value={e[2]}>{e[2]}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
          label={<FormTip label='Business Program' tip="The Card Networks' interchange rate program a merchant pays to accept business credit cards. If a merchant is eligible to receive Level 2/3 discounts on business cards, then choose the Level 2/3 category for which it is eligible. If you have questions, please contact your InterPayments rep." />}
          name={['interchangePrograms', brand, ProgramType.PRESET, 'business']}
          rules={[{required: true, message: `Please enter a business program`}]}
      >
        <Select showSearch style={{width: '100%'}}>
          {programsList.filter(e => e[0] === brand.toLowerCase() && e[1] === 'commercial').sort((a, b) => a[2].localeCompare(b[2])).map(e => <Select.Option key={e[2]} value={e[2]}>{e[2]}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item
          label={<FormTip label='Corporate Program' tip="The Card Networks' interchange rate program a merchant pays to accept corporate credit cards. If a merchant is eligible to receive Level 2/3 discounts on corporate cards, then choose the Level 2/3 category for which it is eligible. If you have questions, please contact your InterPayments rep." />}
          name={['interchangePrograms', brand, ProgramType.PRESET, 'corporate']}
          rules={[{required: true, message: `Please enter a corporate program`}]}
      >
        <Select showSearch style={{width: '100%'}}>
          {programsList.filter(e => e[0] === brand.toLowerCase() && e[1] === 'commercial').sort((a, b) => a[2].localeCompare(b[2])).map(e => <Select.Option key={e[2]} value={e[2]}>{e[2]}</Select.Option>)}
        </Select>
      </Form.Item>

    </>}
  </>)
}

const DirectProgramForm_TierHiROC_TierRow: React.FC<React.PropsWithChildren<BrandForm_Props & {tier: number}>> = ({ brand, tier }) => {
  const tierAsString = tier.toString()
  return <Row>
    <Col span={6}>
      <FormTitle title={tier.toString()} />
    </Col>
    <Col span={6}>
      <Form.Item wrapperCol={fullWidth}>
        <RatePlusFixInput attachTo={['interchangePrograms', brand, ProgramType.TIER_HIROC, 'tier', tierAsString]} required name='consumer' rateRules={[nonZeroRule()]} />
      </Form.Item>
    </Col>
    <Col span={6}>
      <Form.Item wrapperCol={fullWidth}>
        <RatePlusFixInput attachTo={['interchangePrograms', brand, ProgramType.TIER_HIROC, 'tier', tierAsString]} required name='business' rateRules={[nonZeroRule()]} />
      </Form.Item>
    </Col>
    {tier < 4 && <Col span={6}>
      <Form.Item wrapperCol={fullWidth}>
        <RatePlusFixInput attachTo={['interchangePrograms', brand, ProgramType.TIER_HIROC, 'tier', tierAsString]} required name='corporate' rateRules={[nonZeroRule()]} />
      </Form.Item>
    </Col>}
  </Row>
}

const DirectProgramForm_TierHiROC: React.FC<React.PropsWithChildren<BrandForm_Props>> = ({ form, brand }) => {
  return <>
    <Divider>Program Rates</Divider>
    
    <Row>
      <Col span={6}>
        <FormTitle title='Tier' />
      </Col>
      <Col span={6}>
        <FormTitle title='Consumer' />
      </Col>
      <Col span={6}>
        <FormTitle title='Business' />
      </Col>
      <Col span={6}>
        <FormTitle title='Corporate' />
      </Col>
    </Row>

    {_.range(1, 6).map(tier => <DirectProgramForm_TierHiROC_TierRow form={form} brand={brand} key={tier} tier={tier} />)}

    <Row>
      <Col span={6}>
        <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
          <Title level={5}>Debit</Title>
        </Space>
      </Col>
      <Col span={6}>
        <Form.Item wrapperCol={fullWidth}>
          <RatePlusFixInput attachTo={['interchangePrograms', brand, ProgramType.TIER_HIROC, 'tier', 'debit']} required name='consumer' rateRules={[nonZeroRule()]} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item wrapperCol={fullWidth}>
          <RatePlusFixInput attachTo={['interchangePrograms', brand, ProgramType.TIER_HIROC, 'tier', 'debit']} required name='business' rateRules={[nonZeroRule()]} />
        </Form.Item>
      </Col>
    </Row>

    <Row>
      <Col span={6}>
        <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
          <Title level={5}>Prepaid</Title>
        </Space>
      </Col>
      <Col span={6}>
        <Form.Item wrapperCol={fullWidth}>
          <RatePlusFixInput attachTo={['interchangePrograms', brand, ProgramType.TIER_HIROC, 'tier', 'prepaid']} required name='consumer' rateRules={[nonZeroRule()]} />
        </Form.Item>
      </Col>
    </Row>

    <Row>
      <Col span={12}>
        <Divider style={{width: '50%'}}>Hi-ROCs</Divider>
      </Col>
    </Row>

    <Row>
      <Col span={6}>
        <FormTitle title='Hi-ROC Threshold' tooltip='The minimum order value at which this tier will be active.  The maximum order value for this tier is 1 cent below the minimum of the next tier, if it exists.' />
      </Col>
      <Col span={6}>
        <FormTitle title='Rate Reduction' />
      </Col>
    </Row>

    <Form.List
      name={['interchangePrograms', brand, ProgramType.TIER_HIROC, 'hiroc']}
    >
      {(fields, {add, remove}) => (
        <>
          {fields.map((field, index) => {
            const getOrderValueMinimum = () => index === 0 ? -1 : form.getFieldValue(['interchangePrograms', brand, ProgramType.TIER_HIROC, 'hiroc'])[index - 1].orderValue
            return <Row key={field.key}>
              <Col span={6}>
                <Form.Item
                  wrapperCol={fullWidth}
                  key={field.key}
                  name={[field.name.toString(), 'orderValue']}
                  rules={[
                    { required: true, message: 'Please enter the order value for this range' },
                    { validator: async (rule, value) => value > getOrderValueMinimum() ? Promise.resolve() : Promise.reject(), message: `Please enter an order value larger than the previous tier's order value`}
                  ]}
                >
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder='0.10'
                    addonBefore='$'
                    formatter={commaSeparatedFormatter}
                    parser={commaSeparatedParser}
                    min={0}
                    precision={2}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <RateInput name='rate' width='83.333%' margin='8.333%' attachTo={[field.name.toString()]} />
              </Col>
              <Col span={1}>
                <MinusCircleOutlined style={{verticalAlign: 'bottom'}} onClick={() => remove(field.name)} />
              </Col>
            </Row>
          })}

          <Form.Item wrapperCol={{span: 12}}>
            <Button onClick={() => add()} block icon={<PlusOutlined />}>Add Range</Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </>
}

// Direct Program Form - for AmEx direct programs
const DirectProgramForm_OrderValue: React.FC<React.PropsWithChildren<BrandForm_Props>> = ({ form, brand }) => {
  return (<>
    <Divider>Program Rates</Divider>

    <Row>
      <Col span={1}>
        <FormTitle title='Tier' />
      </Col>
      <Col span={4}>
        <FormTitle title='Order Value' tooltip='The minimum order value at which this tier will be active.  The maximum order value for this tier is 1 cent below the minimum of the next tier, if it exists.' />
      </Col>
      <Col span={6}>
        <FormTitle title='Consumer' />
      </Col>
      <Col span={6}>
        <FormTitle title='Business' />
      </Col>
      <Col span={6}>
        <FormTitle title='Corporate' />
      </Col>
    </Row>

    <Form.List
      name={['interchangePrograms', brand, ProgramType.ORDER_VALUE]}
      rules={[{validator: async (_, values) => {
        if (!values || values.length === 0) return Promise.reject('At least 1 order value row is required')
      }}]}
    >
      {(fields, {add, remove}, {errors}) => (
        <>
          {fields.map((field, index) => {
            const fieldNameAsString = field.name.toString()
            const orderValueWrapperProps = index === 0 ? {initialValue: 0} : {}
            const orderValueProps = index === 0 ? {disabled: true} : {min: 0}
            const getOrderValueMinimum = () => index === 0 ? -1 : form.getFieldValue(['interchangePrograms', brand, ProgramType.ORDER_VALUE])[index - 1].orderValue
            return <Row key={field.key}>
              <Col span={1}>
                <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
                  <Title level={5}>{(index + 1).toString()}</Title>
                </Space>
              </Col>
              <Col span={4}>
                <Form.Item
                  {...orderValueWrapperProps}
                  wrapperCol={fullWidth}
                  key={field.key}
                  name={[field.name, 'orderValue']}
                  rules={[
                    { required: true, message: 'Please enter the order value for this range' },
                    { validator: async (rule, value) => value > getOrderValueMinimum() ? Promise.resolve() : Promise.reject(), message: `Please enter an order value larger than the previous tier's order value`}
                  ]}
                >
                  <InputNumber
                    style={{width: '100%'}}
                    placeholder='0.10'
                    addonBefore='$'
                    formatter={commaSeparatedFormatter}
                    parser={commaSeparatedParser}
                    precision={2}
                    {...orderValueProps}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item wrapperCol={fullWidth}>
                  <RatePlusFixInput attachTo={[fieldNameAsString]} required name='consumer' rateRules={[nonZeroRule()]} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item wrapperCol={fullWidth}>
                  <RatePlusFixInput attachTo={[fieldNameAsString]} required name='business' rateRules={[nonZeroRule()]} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item wrapperCol={fullWidth}>
                  <RatePlusFixInput attachTo={[fieldNameAsString]} required name='corporate' rateRules={[nonZeroRule()]} />
                </Form.Item>
              </Col>
              <Col span={1}>
                {index !== 0 && <MinusCircleOutlined style={{verticalAlign: 'bottom'}} onClick={() => remove(field.name)} />}
              </Col>
            </Row>
          })}

          <Form.Item wrapperCol={{span: 24}}>
            <Button onClick={() => add()} block icon={<PlusOutlined />}>Add Range</Button>
          </Form.Item>

          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  </>)
}

enum ProgramType {
  PRESET = 'preset',
  ORDER_VALUE = 'directOrderValue',
  TIER_HIROC = 'directTiered'
}

const allDirectProgramTypes = [ProgramType.PRESET, ProgramType.TIER_HIROC]

const ProgramType_Data = {
  [ProgramType.PRESET]: {
    name: 'OptBlue'
  },
  [ProgramType.ORDER_VALUE]: {
    name: 'Direct Order Value'
  },
  [ProgramType.TIER_HIROC]: {
    name: 'Direct Tiered'
  },
}

const getProgramTypeName = (p: ProgramType) => ProgramType_Data[p].name

const DirectProgramSelector = ({ form, brand, merchant }: {form: FormInstance, brand: CardBrand, merchant: Merchant | undefined}) => {
  return <Form.Item rules={[{required: true, message: 'Program type is required'}]} label='Program Type' name={['meta', 'useDirect', brand]}>
    <Radio.Group>
      {allDirectProgramTypes.map(v => <Radio.Button key={v} value={v}>{getProgramTypeName(v)}</Radio.Button>)}
    </Radio.Group>
  </Form.Item>
}

const DirectProgramForm: React.FC<React.PropsWithChildren<BrandForm_Props>> = ({ form, brand }) => {
  return <>
    <Form.Item noStyle shouldUpdate={(prev, current) => true}>
      {({getFieldValue}) => {
        const directType = getFieldValue(['meta', 'useDirect', brand])
        if (directType === ProgramType.ORDER_VALUE) return <DirectProgramForm_OrderValue form={form} brand={brand} />
        else if (directType === ProgramType.TIER_HIROC) return <DirectProgramForm_TierHiROC form={form} brand={brand} />
        return <></>
      }}
    </Form.Item>
  </>
}

// Brand Forms - all per card fees for a single card type given a certain rule set
const FixedBrandForm: React.FC<React.PropsWithChildren<BrandForm_Props>> = ({ brand }) => {
  return <Row gutter={[16, 0]}>
    <Col span={24}>
      <Form.Item required label={<FormTip label='Processor/ISO/Acquirer Fees' tip='Per transaction fees for standard card processing paid directly to the processor, ISO, and/or acquirer, excluding any and all fees paid separately for surcharging services' />}>
        <RatePlusFixInput required attachTo={['buckets', 'processor']} name={brand} />
      </Form.Item>
    </Col>
  </Row>
}

type InterchangeBrandForm_Props = BrandForm_Props & UseMerchantContext & {
  useSchemeFees: boolean | undefined
}

const InterchangeBrandForm: React.FC<React.PropsWithChildren<InterchangeBrandForm_Props>> = ({ form, brand, useSchemeFees, merchant }) => {
  return <Row gutter={[16, 0]}>
    <Col span={24}>
      {useSchemeFees &&
        <>
          <Form.Item required={true} label={<FormTip label={SchemeFeesV2 ? 'Credit Passthru/Network Fees' : 'Passthru/Network Fees'} tip={SchemeFeesV2 ? 'Per transaction fees paid directly to the card networks on credit transactions' : 'Per transaction fees paid directly to the card networks'} />}>
            <RatePlusFixInput required attachTo={['buckets', 'scheme']} name={brand} />
          </Form.Item>
          {SchemeFeesV2 && <Form.Item required={true} label={<FormTip label='Debit Passthru/Network Fees' tip='Per transaction fees paid directly to the card networks on debit transactions' />}>
            <RatePlusFixInput required attachTo={['bucketsDebit', 'scheme']} name={brand} />
          </Form.Item>}
        </>
      }

      <Form.Item required={true} label={<FormTip label='Processor/ISO/Acquirer Fees' tip='Per transaction fees for standard card processing paid directly to the processor, ISO, and/or acquirer, excluding any and all fees paid separately for surcharging services' />}>
        <RatePlusFixInput required attachTo={['buckets', 'markup']} name={brand} />
      </Form.Item>

      {brand !== CardBrand.AMEX && <InterchangeProgramForm form={form} brand={brand} merchant={merchant} useSchemeFees={undefined} />}

      {brand === CardBrand.AMEX && <>
        <DirectProgramSelector form={form} brand={brand} merchant={merchant} />

        <Form.Item noStyle shouldUpdate={(prev, current) => true}>
          {({getFieldValue}) => {
            const shouldUseDirect = getFieldValue(['meta', 'useDirect', brand]) !== ProgramType.PRESET
            return shouldUseDirect ? <DirectProgramForm form={form} brand={brand} />
                                   : <InterchangeProgramForm form={form} brand={brand} merchant={merchant} useSchemeFees={undefined} />
          }}
        </Form.Item>
      </>}
    </Col>
  </Row>
}

// Tiered pricing is probably deprecated unless we can link into the gateway and get qual data
const TieredBrandForm: React.FC<React.PropsWithChildren<BrandForm_Props>> = ({ form, brand }) => {
  return <Row gutter={[16, 0]}>
    <Col span={24}>
      <PerTxFeeFormComponent form={form} brand={brand} />

      {brand === CardBrand.DEFAULT && <>
        <Form.Item label='Qualified Rate'>
          <RatePlusFixInput required attachTo={['tiered']} name={`${brand}_qual`} />
        </Form.Item>
        <Form.Item label='Mid-Qualified Rate'>
          <RatePlusFixInput required attachTo={['tiered']} name={`${brand}_mid_qual`} />
        </Form.Item>
      </>}
      {[CardBrand.DEFAULT, CardBrand.AMEX].includes(brand) && <>
        <Form.Item label='Non-Qualified Rate'>
          <RatePlusFixInput required attachTo={['tiered']} name={`${brand}_non_qual`} />
        </Form.Item>
      </>}
    </Col>
  </Row>
}

// Brand Card - contains all per card fees for a single card type, as well as interchange programs if applicable
type BrandSection_Props = UseFormContext & UseMerchantContext & {
  ruleSet: RuleSet
}

type BrandCard_Props = BrandSection_Props & UseMerchantContext & {
  useSchemeFees: boolean,
  brand: CardBrand
}


const BrandCard: React.FC<React.PropsWithChildren<BrandCard_Props>> = ({ form, brand, ruleSet, merchant, useSchemeFees }) => {
  return (<ResponsiveColumn width={1} columns={1}>
    <FancyCard title={capitalize(brand)} hoverable>
      {ruleSet === RuleSet.FIXED_FEE && <FixedBrandForm form={form} brand={brand} />}
      {ruleSet !== undefined && ruleSet.includes(RuleSet.INTERCHANGE) && <InterchangeBrandForm form={form} brand={brand} merchant={merchant} useSchemeFees={useSchemeFees} />}
      {ruleSet === RuleSet.TIERED && <TieredBrandForm form={form} brand={brand} />}
    </FancyCard>
  </ResponsiveColumn>)
}

export const BrandSection: React.FC<React.PropsWithChildren<BrandSection_Props>> = ({ form, ruleSet, merchant }) => {
  return <InterchangeProgramScheduleProvider mid={merchant?.id!}>
    <Form.Item noStyle shouldUpdate={(prev, current) => (prev.meta?.useSchemeFees || undefined) !== (current.meta?.useSchemeFees || undefined)}>
      {({getFieldValue}) => {
        const useSchemeFees = getFieldValue(['meta', 'useSchemeFees'])
        const brandsWithCard = ruleSet === RuleSet.TIERED ? allCardBrandsWithDefault : allCardBrands
        return <>
          {brandsWithCard.map(b => <BrandCard key={b} form={form} brand={b} ruleSet={ruleSet} merchant={merchant} useSchemeFees={useSchemeFees} />)}
        </>
      }}
    </Form.Item>
  </InterchangeProgramScheduleProvider>
}

export const OtherFeesSection = ({ form, merchant }) => {
  return <>
    <ResponsiveColumn width={1} columns={1}>
      <FancyCard title={'Other Fees'} hoverable>
        <Form.Item label='ACH'>
          <RatePlusFixInput attachTo={['buckets']} name='ach' />
        </Form.Item>
        <Form.Item label='Wire'>
          <RatePlusFixInput attachTo={['buckets']} name='wire' />
        </Form.Item>
        <Form.Item label='Venmo'>
          <RatePlusFixInput attachTo={['buckets']} name='venmo' />
        </Form.Item>
        <Form.Item label='PayPal'>
          <RatePlusFixInput attachTo={['buckets']} name='paypal' />
        </Form.Item>
        <Form.Item label='Other'>
          <RatePlusFixInput attachTo={['buckets']} name='other' />
        </Form.Item>
      </FancyCard>
    </ResponsiveColumn>
  </>
}

// Gateway and Payfac Cards
const GatewayCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (<>
    <ResponsiveColumn width={1} columns={1}>
      <FancyCard title='Gateway' tip='If you have separate gateway fees, select "Use Gateway Fees" and enter relevant fees in the fields below.  If you are unsure, turn "Use Gateway Fees" slider off.' hoverable>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label='Use Gateway Fees' name={['meta', 'useGateway']} valuePropName='checked'>
              <Switch />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, current) => prev.meta?.useGateway !== current.meta?.useGateway}>
              {({getFieldValue}) => {
                const useGateway = getFieldValue(['meta', 'useGateway'])
                const rules = !!useGateway ? [{required: true, message: 'Please enter a gateway name'}] : []
                return (<>
                  <FormAutocomplete
                    itemProps={{label: 'Gateway Name', name: 'gatewayName', rules}}
                    innerProps={{options: []}}
                  />
                  {!!useGateway && <Form.Item label='Gateway Fees' required={true}>
                    <RatePlusFixInput required attachTo={['buckets']} name={'gateway'} />
                  </Form.Item>}
                </>)
              }}
            </Form.Item>
          </Col>
        </Row>
      </FancyCard>
    </ResponsiveColumn>
  </>)
}

const PayfacCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (<>
    <ResponsiveColumn width={1} columns={1}>
      <FancyCard title='Payfac' tip='If you have separate payfac fees, select "Use Payfac Fees" and enter relevant fees in the fields below.  If you are unsure, turn "Use Payfac Fees" slider off.' hoverable>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label='Use Payfac Fees' name={['meta', 'usePayfac']} valuePropName='checked'>
              <Switch />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, current) => prev.meta?.usePayfac !== current.meta?.usePayfac}>
              {({getFieldValue}) => {
                const usePayfac = getFieldValue(['meta', 'usePayfac'])
                const rules = !!usePayfac ? [{required: true, message: 'Please enter a payfac name'}] : []
                return (<>
                  <FormAutocomplete
                    itemProps={{label: 'Payfac Name', name: 'payfacName', rules}}
                    innerProps={{options: []}}
                  />
                  {!!usePayfac && <Form.Item label='Payfac Fees' required={true}>
                    <RatePlusFixInput required attachTo={['buckets']} name={'payfac'} />
                  </Form.Item>}
                </>)
              }}
            </Form.Item>
          </Col>
        </Row>
      </FancyCard>
    </ResponsiveColumn>
  </>)
}

export const GatewayPayfacSection: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <GatewayCard />
    <PayfacCard />
  </>
)

const ConvenienceFeeConfigSection = ({ isAdmin }: {isAdmin: boolean}) => {
  return <>
    {/* <Form.Item hidden name={['feeConfigs', 'convenienceFeeConfig', 'fix']} />
    <Form.Item hidden name={['feeConfigs', 'convenienceFeeConfig', 'rate']} /> */}
    <Form.Item hidden name={['feeConfigs', 'convenienceFeeConfig', 'rateExpiration']} />
    {!isAdmin && <Form.Item hidden name={['feeConfigs', 'convenienceFeeConfig', 'averagingPeriod']} />}
    <Form.Item hidden name={['feeConfigs', 'convenienceFeeConfig', 'rateTransient']} />
    <Form.Item hidden name={['feeConfigs', 'convenienceFeeConfig', 'fixCap']} />
    <Form.Item hidden name={['feeConfigs', 'convenienceFeeConfig', 'rateCap']} />
    <Form.Item hidden name={['feeConfigs', 'convenienceFeeConfig', 'notifiedOn']} />



    {/*
    <Form.Item label={<FormTip label='Starting Convenience Fee Rate' tip={`The initial convenience fee rate that is imposed. This rate is based upon the merchant's credit card rates from its most recent monthly processing statement. The Starting Convenience Fee Rate remains in place according to 1 of the 3 options you choose in the “Starting Convenience Fee Rate Expiration” field below.  You can choose for it to: never expire by choosing "Never Expire" in the "Starting Convenience Fee Rate Expiration" field dropdown menu, Auto-Expire after InterPayments has 31 days of transaction data to automatically calculate the convenience fee rate going forward, or Custom Expire if you choose a date on which to change the rate`} />}>
      <RateInput width='30%' attachTo={['feeConfigs', 'convenienceFeeConfig']} name={'rate'} />
    </Form.Item>
    <AverageExpirationField attachTo={['meta']} name={'convenienceExpirationRule'} label='Convenience Fee Expiration' />
    <AverageRateExpiryDateInput label='Convenience Fee Rate Expiry Date' overridePath='useConvenienceFees' expirationPath='convenienceExpirationRule' name='convenienceRateExpiration' />
    */}
    {isAdmin && <AveragingPeriodInput attachTo={['feeConfigs', 'convenienceFeeConfig']} name={'averagingPeriod'} label={"Convenience Fee Rate Update Frequency"} tip="How often the convenience fee rate is updated once the Starting Convenience Fee Rate expires." />}
  </>
}

const ServiceFeeConfigSection = ({ isAdmin }: {isAdmin: boolean}) => {
  return <>
    <Form.Item hidden name={['feeConfigs', 'serviceFeeConfig', 'fix']} />
    <Form.Item hidden name={['feeConfigs', 'serviceFeeConfig', 'rate']} />
    <Form.Item hidden name={['feeConfigs', 'serviceFeeConfig', 'rateExpiration']} />
    <Form.Item hidden name={['feeConfigs', 'serviceFeeConfig', 'rateTransient']} />
    <Form.Item hidden name={['feeConfigs', 'serviceFeeConfig', 'fixCap']} />
    <Form.Item hidden name={['feeConfigs', 'serviceFeeConfig', 'rateCap']} />
    <Form.Item hidden name={['feeConfigs', 'serviceFeeConfig', 'notifiedOn']} />
    {!isAdmin && <Form.Item hidden name={['feeConfigs', 'serviceFeeConfig', 'averagingPeriod']} />}
    {isAdmin && <AveragingPeriodInput attachTo={['feeConfigs', 'serviceFeeConfig']} name={'averagingPeriod'} label={"Service Fee Rate Update Frequency"} tip="How often the service fee rate is updated once the Starting Service Fee Rate expires." />}
  </>
}

export const ConvenienceFeeSection = ({ isAdmin }: {isAdmin: boolean}) => {
  if (!isAdmin) return <>
    <Form.Item hidden name={['meta', 'useConvenienceFees']} />
    <ConvenienceFeeConfigSection isAdmin={isAdmin} />
  </>
  return <ResponsiveColumn width={1} columns={1}>
    <FancyCard title='Convenience Fee' tip='' hoverable>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Form.Item label='Use Convenience Fees' name={['meta', 'useConvenienceFees']} valuePropName='checked'>
            <Switch />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, curr) => prev.meta?.useConvenienceFees !== curr.meta?.useConvenienceFees}>
            {({getFieldValue}) => {
              const useConvenienceFees = getFieldValue(['meta', 'useConvenienceFees'])
              if (!useConvenienceFees) return <></>
              return <>
                <ConvenienceFeeConfigSection isAdmin={isAdmin} />
              </>
            }}
          </Form.Item>
        </Col>
      </Row>
    </FancyCard>
  </ResponsiveColumn>
}


export const ServiceFeeSection = ({ isAdmin }: {isAdmin: boolean}) => {
  if (!isAdmin) return <>
    <Form.Item hidden name={['meta', 'useServiceFees']} />
    <ServiceFeeConfigSection isAdmin={isAdmin} />
  </>
  return <ResponsiveColumn width={1} columns={1}>
    <FancyCard title='Service Fee' tip='' hoverable>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Form.Item label='Use Service Fees' name={['meta', 'useServiceFees']} valuePropName='checked'>
            <Switch />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, current) => prev.meta?.useServiceFees !== current.meta?.useServiceFees}>
            {({getFieldValue}) => {
              const useServiceFees = getFieldValue(['meta', 'useServiceFees'])
              if (!useServiceFees) return <></>
              return <>
                <ServiceFeeConfigSection isAdmin={isAdmin} />
                {/* <Form.Item label={<FormTip label='Starting Service Fee Rate' tip={`The initial convenience fee rate that is imposed. This rate is based upon the merchant's credit card rates from its most recent monthly processing statement. The Starting Convenience Fee Rate remains in place according to 1 of the 3 options you choose in the “Starting Convenience Fee Rate Expiration” field below.  You can choose for it to: never expire by choosing "Never Expire" in the "Starting Convenience Fee Rate Expiration" field dropdown menu, Auto-Expire after InterPayments has 31 days of transaction data to automatically calculate the convenience fee rate going forward, or Custom Expire if you choose a date on which to change the rate`} />}>
                  <RateInput width='30%' attachTo={['feeConfigs', 'serviceFeeConfig']} name={'rate'} />
                </Form.Item>
                <AverageExpirationField attachTo={['meta']} name={'serviceExpirationRule'} label='Service Fee Expiration' />
                <AverageRateExpiryDateInput label='Service Fee Rate Expiry Date' overridePath='useServiceFees' expirationPath='serviceExpirationRule' name='serviceRateExpiration' />
                <AveragingPeriodInput attachTo={['feeConfigs', 'serviceFeeConfig']} name={'averagingPeriod'} label={"Service Fee Rate Update Frequency"} tip="How often the service fee rate is updated once the Starting Service Fee Rate expires." /> */}
              </>
            }}
          </Form.Item>
        </Col>
      </Row>
    </FancyCard>
  </ResponsiveColumn>
}

const LimitedAcceptanceTooltipInner = () => {
  const { Text, Paragraph } = Typography
  return <>
    <Paragraph italic style={{color: 'white'}}>
      If you are unsure about what Limited Acceptance is, leave this button in the default "OFF" position.  Please speak with your manager, InterPayments representative, or ask <a href="mailto:support@interpayments.com">support@interpayments.com</a> for more details.
    </Paragraph>
    <Paragraph style={{color: 'white'}}>
      <Text strong style={{color: 'white'}}>For surcharging purposes, Limited Acceptance only applies to American Express Direct Merchants.  </Text>
      Limited Acceptance is <Text underline style={{color: 'white'}}>irrelevant</Text> for American Express OptBlue merchants. 
    </Paragraph>
    <Paragraph style={{color: 'white'}}>
      Limited Acceptance allows AmEx Direct merchants to both accept AmEx and compliantly surcharge all card brands.  It requires AmEx Direct merchants to cease accepting Visa and MasterCard debit/prepaid cards.  Your manager or InterPayments representative can provide more details.
    </Paragraph>
    <Paragraph italic style={{color: 'white'}}>
      Note: If you select this Merchant to be Limited Acceptance, you will need to enter in AmEx rates for AmEx Debit and Prepaid cards in the AmEx rate card below.  You can enter zeroes for them as a placeholder.
    </Paragraph>
  </>
}

// Processor Rule Form - contains all rule-branching logic on the processor rate schedule - and also passes InterchangeProgram context down
type ProcessorRuleForm_Props = UseFormContext & UseAdminContext & UseMerchantContext & {
  ruleSet: RuleSet
  showAveraging?: boolean,
  showExtendedFees?: boolean
}
export const ProcessorRuleForm: React.FC<React.PropsWithChildren<ProcessorRuleForm_Props>> = ({ form, ruleSet, isAdmin = false, merchant, showAveraging = true, showExtendedFees = false }) => {
  const isInterchange = ruleSet !== undefined && ruleSet.includes(RuleSet.INTERCHANGE)
  const extendedFeesAuthorization = useAuthorization('feature:extended-fees', ['viewer'])
  const extendedFeesAuthorized = extendedFeesAuthorization.isAuthorized('viewer')

  return (<>
    {isInterchange && <>
      {showAveraging && <AveragingFinalInput form={form} isAdmin={isAdmin} merchant={merchant} />}
      <Form.Item label={<FormTip label='Use Passthru/Network Fees' tip='Per transaction fees paid directly to the card networks' />} name={['meta', 'useSchemeFees']} valuePropName='checked'>
        <Switch />
      </Form.Item>
    </>}
    <Row gutter={[16, 16]}>
      {isInterchange && <>
        <ConvenienceFeeSection isAdmin={isAdmin || showExtendedFees} />
        <ServiceFeeSection isAdmin={isAdmin || showExtendedFees} />
      </>}
      <GatewayPayfacSection />
      <BrandSection form={form} ruleSet={ruleSet} merchant={merchant} />
      {extendedFeesAuthorized && <OtherFeesSection form={form} merchant={merchant} />}
    </Row>
  </>)
}

// Final Inputs - compose processor forms with these inputs, which have standardized field names and attachment points
export const IdFinalInput: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Form.Item
    hidden={true}
    label="id"
    name="id"
  >
    <Input placeholder="id" autoComplete="off" />
  </Form.Item>
)
export const InternalNameFinalInput: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Form.Item
    label={<FormTip label="InterPayments Processor ID" tip="Processor display name, used in API calls to identify processor configuration to use for calculating processing fees.  The name should be written (and will be constrained) to lowercase and including only alpha-numerics, dashes, or periods." />}
    name="name"
    rules={[{required: true, message: 'Please enter a processor display name'}]}
  >
    <Input placeholder="sample-processor" autoComplete="off" />
  </Form.Item>
)
export const MidFinalInput: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Form.Item
    label={<FormTip label="MID" tip="Merchant's ID with the processor, if unknown this can be entered as the same value as the processor name" />}
    name="mid"
    rules={[{required: true, message: 'Please enter a MID'}]}
  >
    <Input placeholder="sample-processor" autoComplete="off" />
  </Form.Item>
)
export const MerchantCodeFinalInput: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { getProcessorCodes } = useSupportAPI()

  const [ naicsCodes, setNaicsCodes ] = useState<Array<[string, string]>>([])
  const [ mccCodes, setMccCodes ] = useState<Array<[string, string]>>([])
  const [ sicCodes, setSicCodes ] = useState<Array<[string, string]>>([])
  const [ codesLoaded, setCodesLoaded ] = useState<boolean>(false)

  const codeTypes = ['MCC', 'SIC', 'NAICS']

  const sorter = (a: [string, string], b: [string, string]) => {
    return parseInt(a[0]) - parseInt(b[0])
  }

  const loadProcessorCodes = () => {
    getProcessorCodes(codeTypes)
      .then(c => {
          setNaicsCodes(_.get(c, '[0].naics'))
          setMccCodes(_.get(c, '[1].mcc'))
          setSicCodes(_.get(c, '[2].sic'))
      })
      .then(_ => setCodesLoaded(true))
  }

  useEffect(() => {
    if (!codesLoaded) {
      loadProcessorCodes()
    }
  }, [codesLoaded])

  return (<>
    <FormAutocomplete
      itemProps={{label: 'NAICS Code', name: 'naicsCode'}}
      innerProps={{
        options: naicsCodes.sort(sorter).map(code => ({label: `${code[0]} - ${code[1]}`, value: code[0]})),
        filterOption: (input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }}
    />
    <FormAutocomplete
      itemProps={{label: 'SIC Code', name: 'sicCode'}}
      innerProps={{
        options: sicCodes.sort(sorter).map(code => ({label: `${code[0]} - ${titleCase(code[1])}`, value: code[0]})),
        filterOption: (input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }}
    />
    <FormAutocomplete
      itemProps={{label: 'MCC Code', name: 'mccCode'}}
      innerProps={{
        options: mccCodes.sort(sorter).map(code => ({label: `${code[0]} - ${titleCase(code[1])}`, value: code[0]})),
        filterOption: (input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }}
    />
  </>)
}
export const ISONameFinalInput: React.FC<React.PropsWithChildren<unknown>> = () => <FormInput label={'Independent Sales Organization'} name='isoName' />
export const LimitedAcceptanceFinalInput = ({ form, merchant }: { form: FormInstance, merchant: Merchant | undefined }) => (
  <Form.Item label={<FormTip label="Use Limited Acceptance" tip={<LimitedAcceptanceTooltipInner />} />} name={['limitedAcceptance']} valuePropName='checked'>
    <Switch />
  </Form.Item>
)
export const ProcessorNameFinalInput: React.FC<React.PropsWithChildren<unknown>> = () => (
  <FormAutocomplete
    itemProps={{label: <FormTip label='Payment Processor' tip='The ultimate payment processing company. This is not an ISO name, but it may be the same as the Merchant Acquirer name.' />, name: 'processorName', rules: [{required: true, message: 'Please enter your processor'}]}}
    innerProps={{
      options: allProcessors.map(processor => ({label: processor.name, value: processor.name})),
      filterOption: (input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
    }}
  />
)
export const AcquirerNameFinalInput = () => (
  <FormAutocomplete
    itemProps={{label: <FormTip label='Merchant Acquirer' tip='The company that establishes the bank account in which the payments for this processor are deposited.' />, name: 'acquirerName'}}
    innerProps={{
      options: allAcquirers.map(acquirer => ({label: acquirer.name, value: acquirer.name})),
      filterOption: (input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
    }}
  />
)

export const RuleSetFinalInput: React.FC<React.PropsWithChildren<unknown>> = () => <RuleSetInput attachTo={[]} name='ruleSet' />

const AveragingFields: React.FC<React.PropsWithChildren<UseFormContext & UseMerchantContext & UseAdminContext>> = ({form, isAdmin = false}) => <>
  <Form.Item noStyle shouldUpdate={_ => true}>
    {({getFieldValue, setFieldsValue}) => {
      const hasAverages = getFieldValue(['meta', 'hasAverages'])
      const hasOverride = getFieldValue(['meta', 'useOverride'])
      const forceStartingRate = !hasAverages && !isAdmin
      if (!hasOverride && forceStartingRate) setFieldsValue({meta: {useOverride: true}})
      return <ConditionalPopover config={{content: 'Starting surcharge rate is required since no averages are calculated on processor'}} showPopover={forceStartingRate}>
        <Form.Item label='Use Starting Surcharge Rate' name={['meta', 'useOverride']} valuePropName='checked'>
          <Switch disabled={forceStartingRate} />
        </Form.Item>
      </ConditionalPopover>
    }}
  </Form.Item>
  <Form.Item noStyle shouldUpdate={(last, current) => last?.meta?.useOverride !== current?.meta?.useOverride}>
    {({getFieldValue}) => (
      (!!getFieldValue(['meta', 'useOverride']) && <AverageRateOverrideFields form={form} isAdmin={isAdmin} />)
    )}
  </Form.Item>
  <AverageRateExpiryDateInput label='Surcharge Rate Expiry Date' overridePath={'useOverride'} expirationPath={'overrideExpirationRule'} name='rateExpiration' />
  <AveragingPeriodInput attachTo={[]} name='averagingPeriod' label="Surcharge Rate Update Frequency" tip="How often the surcharge rate is updated once the Starting Surcharge Rate expires.  For example: your Starting Surcharge Rate is 3.00% and you allow it to auto-expire.  InterPayments will automatically calculate the surcharge rate after that expiration.  If you set a daily update, your InterPayments' calculated surcharge rate will update each day.  If you set a monthly, quarterly, or yearly update, your surcharge rate will update at the end of each of those periods.  We will send a notification email 14 days before the rate expires." />
</>

const AverageStartingRateField = ({ attachTo, name, label, tip }: WithRequiredFormPath<{label: string, tip: string}>) => {
  return <Form.Item required label={<FormTip label={label} tip={tip} />}>
    <RateInput width='30%' attachTo={attachTo} name={name} required />
  </Form.Item>
}

export const AverageExpirationField = ({ attachTo, name, label }: WithRequiredFormPath<{label: string}>) => {
  return <Form.Item rules={[{required: true, message: 'Please enter a surcharge expiration rule'}]} label={label} name={[...attachTo, name]}>
    <Radio.Group>
      <Tooltip title="The Starting Surcharge Rate never expires - it stays in place indefinitely."><Radio.Button key={RateOverrideExpirationRule.NEVER} value={RateOverrideExpirationRule.NEVER}>Never Expire</Radio.Button></Tooltip>
      <Tooltip title={`The Starting Surcharge Rate will automatically expire once InterPayments has 31 days of transaction data to automatically calculate the surcharge rate going forward. If you'd like to  input a custom date at which the Starting Surcharge Rate expires, choose "Custom-Expire."  It will prompt you to manually enter an expiration date.  We will send a notification email 14 days before the Starting Surcharge Rate expires.`}><Radio.Button key={RateOverrideExpirationRule.AUTO} value={RateOverrideExpirationRule.AUTO}>Auto-Expire</Radio.Button></Tooltip>
      <Tooltip title="Enter the date on which the Starting Surcharge Rate expires. We will send a notification email 14 days before the rate expires."><Radio.Button key={RateOverrideExpirationRule.CUSTOM} value={RateOverrideExpirationRule.CUSTOM}>Custom Expire</Radio.Button></Tooltip>
    </Radio.Group>
  </Form.Item>
}

const AverageRateOverrideFields: React.FC<React.PropsWithChildren<UseFormContext & UseAdminContext>> = ({form, isAdmin = false}) => (<>
  <AverageStartingRateField label={'Starting Surcharge Rate'} tip={`The initial surcharge rate that is imposed. This rate is based upon the merchant's credit card rates from its most recent monthly processing statement. The Starting Surcharge Rate remains in place according to 1 of the 3 options you choose in the “Starting Surcharge Rate Expiration” field below.  You can choose for it to: never expire by choosing "Never Expire" in the "Starting Surcharge Rate Expiration" field dropdown menu, Auto-Expire after InterPayments has 31 days of transaction data to automatically calculate the surcharge rate going forward, or Custom Expire if you choose a date on which to change the rate`} attachTo={[]} name='averageRateOverride' />
  <AverageExpirationField attachTo={['meta']} name='overrideExpirationRule' label='Surcharge Rate Expiration' />
</>)

const AverageRateExpiryDateInput: React.FC<React.PropsWithChildren<{label: string, name: string, overridePath: string, expirationPath: string}>> = ({ label, name, overridePath, expirationPath }) => (<>
  <Form.Item noStyle shouldUpdate={(last, current) => true}>
    {({getFieldValue}) => {
      const useOverride = getFieldValue(['meta', overridePath])
      const rules = useOverride ? [{required: true, message: 'Please enter a manual expiry date or set "Surcharge Rate Expiration" to a different value'}] : []
      return (!useOverride || (!!useOverride && getFieldValue(['meta', expirationPath]) === 'custom')) && <>
        <Form.Item rules={rules} label={<FormTip label={label} tip="Enter the date on which the rate expires.  We will send a notification email 14 days before the rate expires." />} name={name}>
          <DatePicker style={{width: '30%'}} />
        </Form.Item>
      </>
    }}
  </Form.Item>
</>)

// Averaging Final Input - ProcessorForm style
export const AveragingFinalInput: React.FC<React.PropsWithChildren<UseFormContext & UseAdminContext & UseMerchantContext>> = ({form, isAdmin = false, merchant}) => (<>
  <Form.Item
    label='Averaging'
    name='averaging'
    rules={[{required: true, message: 'Please enter an interchange averaging method'}]}
  >
    <Radio.Group>
      {allVisibleAveragingTypesForMerchant(merchant, isAdmin).map(at => {
        return <Radio.Button key={at} value={at}>{getAveragingTypeName(at)}</Radio.Button>
      })}
    </Radio.Group>
  </Form.Item>
  <Form.Item noStyle shouldUpdate={(prev, current) => prev.averaging !== current.averaging}>
    {({getFieldValue}) => {
      const averaging = getFieldValue('averaging')
      if (averaging === AveragingType.NONE) return <></>
      else return <AveragingFields form={form} merchant={merchant} isAdmin={isAdmin} />
    }}
  </Form.Item>
</>)
export const RuleSpecificFinalInput: React.FC<React.PropsWithChildren<UseFormContext & UseAdminContext & UseMerchantContext & {showExtendedFees?: boolean}>> = ({ form, isAdmin = false, merchant, showExtendedFees = false }) => (<>
    <Form.Item noStyle shouldUpdate={(prev, current) => prev.ruleSet !== current.ruleSet}>
      {({getFieldValue}) => {
        const ruleSet = getFieldValue('ruleSet')
        return <ProcessorRuleForm ruleSet={ruleSet} form={form} isAdmin={isAdmin} merchant={merchant} showExtendedFees={showExtendedFees} />
      }}
    </Form.Item>
</>)

export const NotifiedOnFinalInput = () => (
  <Form.Item noStyle name='notifiedOn' />
)

// 3-bucket direct Amex custom rule data
export enum CardContext {
  CONSUMER = 'consumer',
  BUSINESS = 'business',
  CORPORATE = 'corporate'
}
const { CONSUMER, BUSINESS, CORPORATE } = CardContext

export const isCommercial = (cc: CardContext) => cc !== CONSUMER
export const cardTypeData = [
  // AMEX CONSUMER
  {name: 'standard', brand: CardBrand.AMEX, context: CONSUMER, tier: 4},
  {name: 'amexStandard', brand: CardBrand.AMEX, context: CONSUMER, tier: 4},
  {name: 'amexConsumer', brand: CardBrand.AMEX, context: CONSUMER, tier: 4},
  {name: 'amexConsumerTier1', brand: CardBrand.AMEX, context: CONSUMER, tier: 1},
  {name: 'amexConsumerTier2', brand: CardBrand.AMEX, context: CONSUMER, tier: 2},
  {name: 'amexConsumerTier3', brand: CardBrand.AMEX, context: CONSUMER, tier: 3},
  {name: 'amexConsumerTier4', brand: CardBrand.AMEX, context: CONSUMER, tier: 4},
  {name: 'amexConsumerTier5', brand: CardBrand.AMEX, context: CONSUMER, tier: 5},
  // AMEX SMALL BUSINESS
  {name: 'amexBusiness', brand: CardBrand.AMEX, context: BUSINESS, tier: 3},
  {name: 'amexBusinessTier1', brand: CardBrand.AMEX, context: BUSINESS, tier: 1},
  {name: 'amexBusinessTier2', brand: CardBrand.AMEX, context: BUSINESS, tier: 2},
  {name: 'amexBusinessTier3', brand: CardBrand.AMEX, context: BUSINESS, tier: 3},
  {name: 'amexBusinessTier4', brand: CardBrand.AMEX, context: BUSINESS, tier: 4},
  {name: 'amexBusinessTier5', brand: CardBrand.AMEX, context: BUSINESS, tier: 5},
  // AMEX CORPORATE
  {name: 'amexCorporate', brand: CardBrand.AMEX, context: CORPORATE, tier: 2},
  {name: 'amexCorporateTier1', brand: CardBrand.AMEX, context: CORPORATE, tier: 1},
  {name: 'amexCorporateTier2', brand: CardBrand.AMEX, context: CORPORATE, tier: 2},
  {name: 'amexCorporateTier3', brand: CardBrand.AMEX, context: CORPORATE, tier: 3},
  // AMEX DEBIT + PREPAID
  {name: 'amexDebit', brand: CardBrand.AMEX, context: CONSUMER, tier: 'debit'},
  {name: 'amexDebitBusiness', brand: CardBrand.AMEX, context: BUSINESS, tier: 'debit'},
  {name: 'amexPrepaid', brand: CardBrand.AMEX, context: CONSUMER, tier: 'prepaid'},
  {name: 'amexPrepaid', brand: CardBrand.AMEX, context: BUSINESS, tier: 'prepaid'},
  // AMEX REGULATED
  {name: 'amexDebitRegulated', brand: CardBrand.AMEX, context: CONSUMER, tier: 'regulated'},
  {name: 'amexDebitBusinessRegulated', brand: CardBrand.AMEX, context: BUSINESS, tier: 'regulated'},
  {name: 'amexPrepaidRegulated', brand: CardBrand.AMEX, context: CONSUMER, tier: 'regulated'},
  {name: 'amexPrepaidRegulated', brand: CardBrand.AMEX, context: BUSINESS, tier: 'regulated'}
]

type CardTierDataRecord = {
  hirocEligible: boolean,
}
type CardTierData = {
  '1': CardTierDataRecord,
  '2': CardTierDataRecord,
  '3': CardTierDataRecord,
  '4': CardTierDataRecord,
  '5': CardTierDataRecord,
  'debit': CardTierDataRecord,
  'prepaid': CardTierDataRecord,
  'regulated': CardTierDataRecord
}
type CardTier = keyof CardTierData
export const cardTierData: CardTierData = {
  '1': {hirocEligible: true},
  '2': {hirocEligible: true},
  '3': {hirocEligible: true},
  '4': {hirocEligible: true},
  '5': {hirocEligible: true},
  'debit': {hirocEligible: false},
  'prepaid': {hirocEligible: false},
  'regulated': {hirocEligible: false},
}

const transformRateToPercent = (rate: number | string) => (parseFloat(rate as any) * 100).toFixed(6)
const transformRateToDecimal = (rate: number | string) => (parseFloat(rate as any) / 100).toFixed(6)
const transformFix = (fix: number | string) => parseFloat(fix as any).toFixed(4)
const transformSimpleFee = (sf: SimpleFee) => {
  return {
    rate: transformRateToDecimal(sf.rate),
    fix: transformFix(sf.fix)
  }
}

type BaseFormData = {
  id: string,
  name: string,
  mid: string,
  ruleSet: RuleSet,

  naicsCode?: string,
  sicCode?: string,
  mccCode?: string,
  isoName?: string,
  processorName: string,
  gatewayName: string,
  payfacName?: string,

  notifiedOn?: string,

  limitedAcceptance: boolean,

  meta: {
    useConvenienceFees: boolean,
    //convenienceExpirationRule: RateOverrideExpirationRule,
    // convenienceRateExpiration: string,

    useServiceFees: boolean,
    //serviceExpirationRule: RateOverrideExpirationRule,
    // serviceRateExpiration: string,
  },
  
  feeConfigs: {
    convenienceFeeConfig: {
      averagingPeriod: AveragingPeriod,
      rate: number,
    },
    serviceFeeConfig: {
      averagingPeriod: AveragingPeriod,
      rate: number,
    }
  }
}

type BucketMap = {
  [key: string]: GenericFee
}

type BucketFormData = {
  buckets: BucketMap,
  bucketsDebit?: BucketMap,
}

type FixedFormData = BucketFormData

type Rate = {rate: number}
type Fix = {fix: number}
type RatePlusFix = Rate & Fix
type RatePlusFix_String = {rate: string, fix: string}
type RatePlusFix_Generic = {rate: string | number, fix: string | number}

type OrderValueBreakpoint = {orderValue: number}
type OrderValueBreakpointWithRate = OrderValueBreakpoint & Rate

type OrderValueRange = {rangeFloor?: number, rangeCeiling?: number}

type ContextBuckets1 = {consumer: RatePlusFix}
type ContextBuckets2 = ContextBuckets1 & {business: RatePlusFix}
type ContextBuckets3 = ContextBuckets2 & {corporate: RatePlusFix}
type ContextBuckets = ContextBuckets1 | ContextBuckets2 | ContextBuckets3

type OverrideRatePlusFix_Numeric = {cardRate: number, cardFix: number}
type OverrideRatePlusFix_Stringified = {cardRate: string, cardFix: string}
type OverrideRatePlusFix = OverrideRatePlusFix_Numeric | OverrideRatePlusFix_Stringified
type ProgramMetadata = {cardCategory: CardBrand, cardProgram: ProgramType}

type InterchangeProgram_Preset_Body = {consumer: string, business: string, corporate: string}
type InterchangeProgram_Preset = {[ProgramType.PRESET]: InterchangeProgram_Preset_Body}

type InterchangeProgram_DirectOrderValue_Record = OrderValueBreakpoint & ContextBuckets3
type InterchangeProgram_DirectOrderValue_Body = InterchangeProgram_DirectOrderValue_Record[]
type InterchangeProgram_DirectOrderValue = {[ProgramType.ORDER_VALUE]: InterchangeProgram_DirectOrderValue_Body}

type InterchangeProgram_DirectTiered_Tiers = {
  "1": ContextBuckets3,
  "2": ContextBuckets3,
  "3": ContextBuckets3,
  "4": ContextBuckets2,
  "5": ContextBuckets2,
  "debit": ContextBuckets2,
  "prepaid": ContextBuckets1,
}

type InterchangeProgram_DirectTiered_TiersExpanded = InterchangeProgram_DirectTiered_Tiers & {
  "prepaid": ContextBuckets2,
  "regulated": ContextBuckets2
}

type InterchangeProgram_DirectTiered_Body = {
  tier: InterchangeProgram_DirectTiered_Tiers,
  hiroc: OrderValueBreakpointWithRate[]
}

type InterchangeProgram_DirectTiered_BodyExpanded = InterchangeProgram_DirectTiered_Body & {
  tier: InterchangeProgram_DirectTiered_TiersExpanded
}

type InterchangeProgram_DirectTiered = {[ProgramType.TIER_HIROC]: InterchangeProgram_DirectTiered_Body}

type InterchangeProgram = InterchangeProgram_Preset | InterchangeProgram_DirectOrderValue | InterchangeProgram_DirectTiered

type InterchangeFormData = {
  ruleSet: RuleSet.INTERCHANGE | RuleSet.INTERCHANGE_AVG | RuleSet.INTERCHANGE_AVG_BRAND,
  averaging: AveragingType,
  averageRateOverride?: number | undefined,
  rateExpiration?: Dayjs | undefined,
  averagingPeriod: AveragingPeriod,
  interchangePrograms: {
    [key: string]: InterchangeProgram
  },
  meta: {
    useOverride: boolean,
    useConvenienceFees: boolean,
    useServiceFees: boolean,
    overrideExpirationRule?: RateOverrideExpirationRule,
  }
} & BucketFormData

type TieredFormData = {
  tiered: any, // I cannot wait to delete Tiered
  transactionFees: any,
}

type FixedForm = BaseFormData & FixedFormData
type InterchangeForm = BaseFormData & InterchangeFormData
type TieredForm = BaseFormData & TieredFormData
export type ProcessorForm = FixedForm | InterchangeForm | TieredForm

// sorts order value breakpoint-style ascending
const sortByOrderValue = <InType extends OrderValueBreakpoint>(a: InType, b: InType) => a.orderValue - b.orderValue

// Form to Request - takes an intermediate form and turns it into a /sf-style processor
export const formToRequest = (data: ProcessorForm) => {
  if (!data) return {}

  const parseRuleSet = (data: ProcessorForm) => {
    if (data.ruleSet.includes(RuleSet.INTERCHANGE)) {
      const interchangeData = data as InterchangeForm
      if (interchangeData.averaging === AveragingType.BRAND) return RuleSet.INTERCHANGE_AVG_BRAND
      else if (interchangeData.averaging === AveragingType.GLOBAL) return RuleSet.INTERCHANGE_AVG
      else if (interchangeData.averaging === AveragingType.PRODUCT_LEVEL) return RuleSet.INTERCHANGE_AVG_PRODUCT_LEVEL
      else if (interchangeData.averaging === undefined) return RuleSet.INTERCHANGE_AVG // if we don't provide an option for averaging, assume we are using global average
      else return RuleSet.INTERCHANGE
    } else return data.ruleSet
  }

  const parseProcessorSourceData = (data: ProcessorForm) => {
    const fullProcessorKeys = Object.keys(data)
    const processorSourceKeys = ['naicsCode', 'sicCode', 'mccCode', 'isoName', 'processorName', 'gatewayName', 'payfacName']
    return processorSourceKeys
      .filter(key => fullProcessorKeys.includes(key))
      .reduce((rollup, key) => {
        return {...rollup, [key]: data[key]}
      }, {})
  }

  const parseGenericFeeData = (data: FixedForm | InterchangeForm) => {
    // amends rate from percent to decimal and adds calculated base fee
    const transformPerCardFee = (pcf: PerCardFee) => {
      let minRate: number | undefined = undefined
      let minFix: number | undefined = undefined
      const naiveRates = Object.entries(pcf).reduce((rollup, pair) => {
        const name = pair[0]
        if (name === CardBrand.DEFAULT) return rollup

        const fee: SimpleFee = pair[1]!
        minRate = minRate === undefined ? fee.rate : Math.min(minRate, fee.rate)
        minFix = minFix === undefined ? fee.fix : Math.min(minFix, fee.fix)
        return {
          ...rollup,
          [name]: transformSimpleFee(fee)
        }
      }, {})
      const calculatedBaseRate = {
        [CardBrand.DEFAULT]: transformSimpleFee({rate: minRate || 0, fix: minFix || 0})
      }
      return {...naiveRates, ...calculatedBaseRate}
    }

    const rollupFees = (bucketMap: BucketMap) => Object.entries(bucketMap).reduce((rollup, pair) => {
      const name = pair[0]
      const fee: GenericFee = pair[1]
      if (isSimpleFee(fee)) {
        return {
          ...rollup,
          [name]: transformSimpleFee(fee)
        }
      } else {
        return {
          ...rollup,
          [name]: transformPerCardFee(fee)
        }
      }
    }, {})

    const buckets = data.buckets || {}
    const bucketsDebit = SchemeFeesV2 ? (data.bucketsDebit || {}) : undefined
    const genericFees = rollupFees(buckets)
    const genericFeesDebit = SchemeFeesV2 ? rollupFees(bucketsDebit!) : undefined

    return {
      genericFees, ...(SchemeFeesV2 ? {genericFeesDebit} : {})
    }
  }

  const fixedToRequest = (data: FixedForm) => {
    const genericFeeData = parseGenericFeeData(data)
    return genericFeeData
  }

  const interchangeToRequest = (data: InterchangeForm) => {
    const parseInterchangeProgramData = (data: InterchangeForm) => {
      const parseSingleProgram = (brand: CardBrand, program: InterchangeProgram) => {
        // parses a preset program - i.e. form input with three dropdowns allowing a consumer, business, and corporate program to be selected from presets
        const parseSinglePresetProgram = (brand: CardBrand, body: InterchangeProgram_Preset_Body) => {
          const consumerProgram = {id: brand, program: body.consumer, programType: CardContext.CONSUMER}
          const businessProgram = {id: brand, program: body.business, commercial: true, programType: CardContext.BUSINESS}
          const corporateProgram = {id: brand, program: body.corporate, commercial: true, programType: CardContext.CORPORATE}
          return [consumerProgram, businessProgram, corporateProgram]
        }

        // takes the order value breakpoint-style and converts into entries delimited by range (rangeFloor/rangeCeiling)
        const breakpointToRange = <InType extends OrderValueBreakpoint>(record: InType, index: number, array: InType[]) => {
          const calculateCeiling = (index: number, array: OrderValueBreakpoint[]) => array[index + 1].orderValue - 0.01
          const { orderValue, ...fields } = record
          const isOnlyOneRange = array.length === 1
          const rangeFloor = (isOnlyOneRange || index === 0) ? undefined : orderValue
          const rangeCeiling = (isOnlyOneRange || index === array.length - 1) ? undefined : calculateCeiling(index, array)
          const range = { ...(rangeFloor !== undefined && {rangeFloor}), ...(rangeCeiling !== undefined && {rangeCeiling}) } as OrderValueRange
          return { ...fields, ...range }
        }

        // splits the range-style entries and breaks them into context-scoped intermediates (i.e. one entry for each of consumer, business, and corporate)
        const splitContext = <InType extends ContextBuckets>(record: InType) => {
          const { consumer, business, corporate, ...fields } = record as ContextBuckets3
          const isContextBucket3 = CardContext.CORPORATE in record
          const isContextBucket2 = CardContext.BUSINESS in record
          return [
            {context: CardContext.CONSUMER, cardRate: consumer.rate, cardFix: consumer.fix, ...fields},
            ...(isContextBucket2 ? [{context: CardContext.BUSINESS, cardRate: business.rate, cardFix: business.fix, ...fields}] : []),
            ...(isContextBucket3 ? [{context: CardContext.CORPORATE, cardRate: corporate.rate, cardFix: corporate.fix, ...fields}] : [])
          ]
        }

        // takes intermediates and adds card brand and card program name metadata for the eventual rate overrides
        const createAddMetadata = (brand: CardBrand, program: ProgramType) => <InType extends object>(record: InType) => {
          return { ...record, cardCategory: brand, cardProgram: program }
        }

        // takes the list of all overrides and groups them into three buckets, consumer, business, and corporate (for sending to the backend)
        const bucketContextsAsBackend = <InType extends {context: CardContext}>(array: InType[]): [Omit<InType, 'context'>[], Omit<InType, 'context'>[], Omit<InType, 'context'>[]] => {
          const removeContext = (record: InType) => {
            const { context, ...fields } = record
            return fields
          }
          const consumerOverrides = array.filter(record => record.context === CardContext.CONSUMER).map(removeContext)
          const businessOverrides = array.filter(record => record.context === CardContext.BUSINESS).map(removeContext)
          const corporateOverrides = array.filter(reocrd => reocrd.context === CardContext.CORPORATE).map(removeContext)
          return [consumerOverrides, businessOverrides, corporateOverrides]
        }

        // takes card rate and fix from form-style, divides rate by 100 (into decimal), and then retypes into truncated string
        const retypeRateAndFix = <InType extends OverrideRatePlusFix>(record: InType) => {
          const { cardRate, cardFix, ...fields } = record
          const parse = (value: number | string) => typeof value === 'string' ? parseFloat(value) : value
          const format = (precision: number) => (value: number | string) => typeof value === 'number' ? value.toFixed(precision) : value
          return {
            ...fields,
            cardRate: parse(format(6)(parse(cardRate) / 100)), // divide card rate by 100, rounds to 6 decimal places, then re-parse the result to find the closest float
            cardFix: parse(cardFix)
          }
        }

        // parses an order value custom program into backend style
        const parseSingleOrderValueProgram = (brand: CardBrand, body: InterchangeProgram_DirectOrderValue_Body) => {
          type ContextIntermediate = OrderValueRange & OverrideRatePlusFix & {context: CardContext}
          type MetadataIntermediate = ContextIntermediate & ProgramMetadata
          type CardTypeIntermediate = MetadataIntermediate & {cardType: string}

          // takes the card contexts with rate/fix information and metadata and turns into a list of all overrides for this direct program
          const expandContextToCardType = (record: MetadataIntermediate): CardTypeIntermediate[] => {
            const { context, cardCategory, ...fields } = record
            const allCardsMatchingType = cardTypeData.filter(d => d.brand === cardCategory && d.context === context)
            return allCardsMatchingType.map(c => ({
              ...fields,
              cardCategory,
              context,
              cardType: c.name
            }))
          }

          const allOverrides: CardTypeIntermediate[] = body
            .sort(sortByOrderValue)
            .map(breakpointToRange)
            .flatMap(splitContext)
            .map(retypeRateAndFix)
            .map(createAddMetadata(brand, ProgramType.ORDER_VALUE))
            .flatMap(expandContextToCardType)

          const [consumerRateOverrides, businessRateOverrides, corporateRateOverrides] = bucketContextsAsBackend(allOverrides)

          const consumerProgram = {id: brand, program: ProgramType.ORDER_VALUE, rateOverride: consumerRateOverrides, programType: CardContext.CONSUMER}
          const businessProgram = {id: brand, program: ProgramType.ORDER_VALUE, rateOverride: businessRateOverrides, programType: CardContext.BUSINESS, commercial: true}
          const corporateProgram = {id: brand, program: ProgramType.ORDER_VALUE, rateOverride: corporateRateOverrides, programType: CardContext.CORPORATE, commercial: true}
          return [consumerProgram, businessProgram, corporateProgram]
        }

        // parses a tier/hiroc custom program into backend style
        const parseSingleTieredProgram = (brand: CardBrand, body: InterchangeProgram_DirectTiered_Body) => {
          type TierAndContext = {
            context: CardContext,
            tier: CardTier
          }
          type TierContextIntermediate = TierAndContext & OverrideRatePlusFix_Numeric
          type MetadataIntermediate = TierAndContext & OrderValueRange & ProgramMetadata

          const expandTiersFromBody = (body: InterchangeProgram_DirectTiered_Body): InterchangeProgram_DirectTiered_BodyExpanded => {
            const regulatedRatePlusFix: RatePlusFix = {rate: 0.04, fix: 0.25}
            const tier: InterchangeProgram_DirectTiered_TiersExpanded = {
              ...body.tier,
              prepaid: {[CardContext.CONSUMER]: body.tier.prepaid.consumer, [CardContext.BUSINESS]: body.tier.prepaid.consumer},
              regulated: {[CardContext.CONSUMER]: regulatedRatePlusFix, [CardContext.BUSINESS]: regulatedRatePlusFix}
            }
            return {...body, tier}
          }

          const addZeroBreakpoint = (array: OrderValueBreakpointWithRate[]) => [{orderValue: 0, rate: 0}, ...array]
          const tierObjectToEntries = (body: InterchangeProgram_DirectTiered_Body) => Object.entries(body.tier).map(t => {
            const [tier, buckets] = t
            return {tier, ...buckets}
          })
          // should we apply the hiroc adjustment to this tier?
          const isTierHirocEligible = (cardTier: CardTier) => cardTierData[cardTier].hirocEligible
          const muxOrderValueTiers = (valueTiers: (OrderValueRange & Rate)[]) => (cardTier: TierContextIntermediate) => {
            const { cardRate, ...cardTierFields } = cardTier
            return valueTiers.map(v => {
              const { rate, ...valueTierFields } = v
              return {
                ...cardTierFields,
                ...valueTierFields,
                cardRate: isTierHirocEligible(cardTierFields.tier) ? (cardRate - rate) : cardRate // new card rate subtracts the order value tier (hiroc) if the tier is hiroc eligible (i.e. not a debit or prepaid)
              }
            })
          }
          const expandTierToCardType = (record: MetadataIntermediate) => {
            const { context, cardCategory, tier, ...fields } = record
            const allCardsMatchingType = cardTypeData.filter(d => d.brand === cardCategory && d.context === context && d.tier.toString() === tier)
            return allCardsMatchingType.map(c => ({
              ...fields,
              cardCategory,
              context,
              cardType: c.name
            }))
          }

          const expandedBody: InterchangeProgram_DirectTiered_BodyExpanded = expandTiersFromBody(body)

          const allHirocTiers = addZeroBreakpoint(body.hiroc || [])
            .sort(sortByOrderValue)
            .map(breakpointToRange)

          const allOverrides = (tierObjectToEntries(expandedBody)
            .flatMap(splitContext) as unknown as TierContextIntermediate[]) // currently used because type signature on splitContext doesn't like Context2/Context3 mixing on Omit<InType, CardContext>
            .flatMap(muxOrderValueTiers(allHirocTiers))
            .map(retypeRateAndFix)
            .map(createAddMetadata(brand, ProgramType.TIER_HIROC))
            .flatMap(expandTierToCardType)

          const [consumerRateOverrides, businessRateOverrides, corporateRateOverrides] = bucketContextsAsBackend(allOverrides)

          const consumerProgram = {id: brand, program: ProgramType.TIER_HIROC, rateOverride: consumerRateOverrides, programType: CardContext.CONSUMER}
          const businessProgram = {id: brand, program: ProgramType.TIER_HIROC, rateOverride: businessRateOverrides, programType: CardContext.BUSINESS, commercial: true}
          const corporateProgram = {id: brand, program: ProgramType.TIER_HIROC, rateOverride: corporateRateOverrides, programType: CardContext.CORPORATE, commercial: true}
          return [consumerProgram, businessProgram, corporateProgram]
        }

        const programKey = Object.keys(program).find(k => k !== undefined)
        if (programKey === undefined) return {}
        const programBody = program[programKey]
        if (programKey === ProgramType.PRESET) return parseSinglePresetProgram(brand, programBody)
        else if (programKey === ProgramType.ORDER_VALUE) return parseSingleOrderValueProgram(brand, programBody)
        else if (programKey === ProgramType.TIER_HIROC)  return parseSingleTieredProgram(brand, programBody)
      }

      const interchangePrograms = data.interchangePrograms
      const allBrands = Object.keys(interchangePrograms)
      const allPrograms = {interchangePrograms: allBrands.flatMap(b => parseSingleProgram(b as CardBrand, data.interchangePrograms[b]))}
      return allPrograms
    }

    const parseInterchangeAveragingData = (data: InterchangeForm) => {
      const averagingPeriod = data.averagingPeriod
      const useOverride = data.meta.useOverride
      const overrideExpirationRule = data.meta?.overrideExpirationRule

      // Backend averaging code needs rateTransient set to true in order to update the expiration date, so we really want it always true
      // unless a merchant has selected RateOverrideExpirationRule.NEVER.
      const rateTransient = useOverride ? {rateTransient: overrideExpirationRule !== RateOverrideExpirationRule.NEVER} : {rateTransient: true}
      const averageRateOverride = useOverride ? {rate: transformRateToDecimal(data.averageRateOverride!)} : {}
      const rateExpiration = (!useOverride && !!data.rateExpiration) || overrideExpirationRule === RateOverrideExpirationRule.CUSTOM
        ? {rateExpiration: formatISO(data.rateExpiration!.toDate(), { representation: 'date' })}
        : {}

      return {
        averagingPeriod,
        ...rateTransient,
        ...averageRateOverride,
        ...rateExpiration,
      }
    }
    const genericFeeData = parseGenericFeeData(data)
    const interchangeProgramData = parseInterchangeProgramData(data)
    const interchangeAveragingData = parseInterchangeAveragingData(data)
    return _.merge(genericFeeData, interchangeProgramData, interchangeAveragingData)
  }

  const tieredToRequest = (data: TieredForm) => {
    const tieredFeesToRequest = (data: TieredForm) => {
      const tiered = data.tiered || []

      const parsedData = Object.keys(tiered).reduce((rollup: any, key: string) => {
        const rawValue = tiered[key]

        const removeAmexAnnotation = (key: string) => key.startsWith('amex') ? 'amex' : key
        const renameBaseToCredit = (key:string) => key.replace('base', 'credit')
        const formattedKey = renameBaseToCredit(removeAmexAnnotation(key))
        if (rawValue !== undefined) {
          const { rate, fix } = transformSimpleFee(rawValue)
          return {...rollup, [`${formattedKey}_rate`]: rate, [`${formattedKey}_fix`]: fix}
        } else return rollup
      }, {
        debit_qual_fix: 0,
        debit_mid_qual_fix: 0,
        debit_non_qual_fix: 0,
        debit_qual_rate: 0,
        debit_mid_qual_rate: 0,
        debit_non_qual_rate: 0,
      })
      return {tiered: parsedData}
    }

    const txFeesToRequest = (data: TieredForm) => {
      const transactionFees = data.transactionFees || {}

      const parsedData = Object.entries(transactionFees).reduce((rollup, pair) => {
        const key = pair[0]
        const value = pair[1]
        if (value !== undefined) return {...rollup, [key]: transformFix(value as any)}
        else return rollup
      }, {})

      return {transaction_fees: parsedData}
    }

    const tieredFeeValues = tieredFeesToRequest(data)
    const txFeeValues = txFeesToRequest(data)

    return _.merge(tieredFeeValues, txFeeValues)
  }

  /*
  const parseFeeConfigs = (data: ProcessorForm) => {
    const { meta } = data
    const { useConvenienceFees, convenienceExpirationRule } = meta
    const rateTransient = useConvenienceFees ? {rateTransient: convenienceExpirationRule !== RateOverrideExpirationRule.NEVER} : {}
    const averageRateOverride = useConvenienceFees ? {rate: transformRateToDecimal(data.feeConfigs.convenienceFeeConfig.rate)} : {}
    const rateExpiration = convenienceExpirationRule === RateOverrideExpirationRule.CUSTOM
      ? {rateExpiration: formatISO(data.meta.convenienceRateExpiration, { representation: 'date' })}
      : {}

    
  }
  */

  const ruleSet = parseRuleSet(data)
  const processorSourceData = parseProcessorSourceData(data)
  const ruleSpecificData = ruleSet === RuleSet.FIXED_FEE ? fixedToRequest(data as FixedForm)
                         : ruleSet.includes(RuleSet.INTERCHANGE) ? interchangeToRequest(data as InterchangeForm)
                         : ruleSet === RuleSet.TIERED ? tieredToRequest(data as TieredForm)
                         : {}

  const name = !!data.name ? data.name : data.processorName
  const mid = !!data.mid ? data.mid : name

  const requestData = {
    id: data.id,
    name,
    mid,
    limitedAcceptance: data.limitedAcceptance,
    rule_set: ruleSet,
    notifiedOn: data.notifiedOn,
    ...processorSourceData,
    ...ruleSpecificData,
    feeConfigs: data.feeConfigs,
  }
  return requestData
}

// Request to Form - takes a processor from merchant object and turns it into an intermediate form
export const requestToForm = (data: FullProcessor) => {
  console.log('Computing form values from request', data)

  const formDefaults = {
    meta: {
      useGateway: true,
      usePayfac: true,
      useSchemeFees: true,
      hasAverages: false,
      useConvenienceFees: false,
      useServiceFees: false,
    }
  }

  if (!data) return formDefaults

  // converts Fee[] to a PerCardFee, assuming each fee.id.toLowerCase() is in the CardBrand enum namespace
  const feeArrayToPCF = (fees: Fee[]): PerCardFee => {
    return fees.reduce((rollup, fee) => {
      if (fee.rate === undefined || fee.fix === undefined) return rollup
      const brand: CardBrand = fee.id.toLowerCase() as CardBrand
      const rate = transformRateToPercent(fee.rate)
      const fix = transformFix(fee.fix)
      return {...rollup, [brand]: {rate, fix}}
    }, {}) as PerCardFee
  }

  // converts an array of tuples [name, fee] to an object with ...{[name]: fee} entries
  const bucketArrayToBucketObject = (buckets: FeeBucket[]) => {
    const amendFeeRates = (fee: GenericFee) => {
      if (isSimpleFee(fee)) {
        return {rate: transformRateToPercent(fee.rate), fix: transformFix(fee.fix)}
      } else if (isPerCardFee(fee)) {
        return Object.entries(fee).reduce((rollup, pair) => {
          const key: string = pair[0]
          const value: SimpleFee = pair[1]!
          return {...rollup, [key]: amendFeeRates(value)}
        }, {})
      }
    }

    return buckets.reduce((rollup, bucket) => {
      const { name, fee } = bucket
      return {...rollup, [name]: amendFeeRates(fee)}
    }, {})
  }

  // rollups all buckets in data.buckets into an object, sets usePayfac depending on if bucket with key 'payfac' exists
  const bucketsToForm = (data: FullProcessor) => {
    if (!data.buckets) return {}
    const buckets = bucketArrayToBucketObject(data.buckets)
    const bucketsDebitNaive = SchemeFeesV2 ? bucketArrayToBucketObject(data.bucketsDebit!) : undefined
    const bucketKeys = Object.keys(buckets)
    const debitBucketKeys = SchemeFeesV2 ? Object.keys(bucketsDebitNaive!) : undefined
    const useGateway = bucketKeys.includes('gateway')
    const usePayfac = bucketKeys.includes('payfac')

    const creditSchemeFees = bucketKeys.includes('scheme')
    const debitSchemeFees = SchemeFeesV2 && debitBucketKeys!.includes('scheme')
    const useSchemeFees = creditSchemeFees || debitSchemeFees

    const withCopiedFees = (props: string[]) => (from: object, to: object) => {
      return props.reduce((a, p: string) => {
        if (Object.hasOwn(a, p)) return a
        else return {...a, [p]: from[p]}
      }, to)
    }

    // PRO-3582 copy credit scheme fees to debit
    const bucketsDebit = SchemeFeesV2 ? {...(creditSchemeFees && !debitSchemeFees) ? withCopiedFees(['scheme'])(buckets, bucketsDebitNaive!) : bucketsDebitNaive} : {}

    return {
      buckets,
      bucketsDebit,
      meta: {useGateway, usePayfac, useSchemeFees}
    }
  }

  // rollups all defined string members of ProcessorSource into an object
  const processorSourceToForm = (data: FullProcessor) => {
    const fullProcessorKeys = Object.keys(data)
    const processorSourceKeys = ['naicsCode', 'sicCode', 'mccCode', 'isoName', 'processorName', 'gatewayName', 'payfacName']
    return processorSourceKeys
      .filter(key => fullProcessorKeys.includes(key))
      .reduce((rollup, key) => {
        return {...rollup, [key]: data[key]}
      }, {})
  }

  // converts either a buckets (highest priority) or fixedFees (fallback) type into form, setting usePayfac appropriately
  const fixedToForm = (data: FullProcessor) => {
    const fixedFeesToForm = (data: FullProcessor) => {
      const processorFees: PerCardFee | Record<string, never> = !!data.fixedFees ? feeArrayToPCF(data.fixedFees) : {}
      return {
        buckets: {
          processor: processorFees
        },
        meta: {
          useGateway: false,
          usePayfac: false,
        }
      }
    }

    // if we have request.buckets data, populate form with granular bucket data
    // else remap request.fixedFees Fee[] to form.buckets.processor object
    const fixedValues = (!!data.buckets && data.buckets.length > 0) ? bucketsToForm(data) : fixedFeesToForm(data)
    return fixedValues
  }

  const interchangeToForm = (data: FullProcessor) => {
    const interchangeFeesToForm = (data: FullProcessor) => {
      const interchangeBaseFees = !!data.interchangeFees ? data.interchangeFees.find(fee => fee.id.toLowerCase() === 'base') : undefined
      const processorFees: PerCardFee | Record<string, never> = !!interchangeBaseFees ? feeArrayToPCF(interchangeBaseFees.fees) : {}
      return {
        buckets: {
          processor: processorFees
        },
        meta: {
          useGateway: false,
          usePayfac: false,
          useSchemeFees: false,
        }
      }
    }

    const interchangeProgramsToForm = (data: FullProcessor) => {
      type IntermediateProgramRecord = {
        brand: CardBrand,
        consumer: InterchangePrograms,
        business: InterchangePrograms,
        corporate: InterchangePrograms
      }

      const unique = <T extends any>(array: T[]) => array.filter((o, i, a) => a.indexOf(o) === i)

      const getProgramType = (program: InterchangePrograms) => ['custom', ProgramType.ORDER_VALUE].includes(program.program) ? ProgramType.ORDER_VALUE
                                                               : program.program === ProgramType.TIER_HIROC ? ProgramType.TIER_HIROC
                                                               : ProgramType.PRESET

      const makeProgram = (record: IntermediateProgramRecord) => {
        const makePresetProgram = (record: IntermediateProgramRecord) => ({[record.brand]: {[ProgramType.PRESET]: {consumer: record?.consumer?.program || '', business: record?.business?.program || '', corporate: record?.corporate?.program || ''}}})
        type CardTypeDataIntermediate = ProgramRateOverride & {context: CardContext, tier: number}
        type MergeIntermediate = {
          [x: string]: number | RatePlusFix_Generic,
          orderValue: number,
          tier: number
        }
        // selects only entries with card type data defined for the cardType specified, otherwise returns undefined
        const buildSelectWithCardTypeData = (brand: CardBrand) => <InType extends {cardType: string}>(override: InType): (Omit<InType, 'cardType'> & {context: CardContext, tier: string | number}) | undefined => {
          const { cardType, ...fields } = override
          const data = cardTypeData.find(data => data.brand === brand && data.name === cardType)
          if (data === undefined) return undefined
          const { context, tier } = data
          return {...fields, context, tier}
        }
        // stringify rate and fix and turn rate from decimal to percent
        const retypeRateAndFix = <InType extends {cardRate: number, cardFix: number}>(record: InType) => {
          const { cardRate, cardFix, ...fields } = record
          const retypedRateAndFix: RatePlusFix_String = {rate: (cardRate * 100).toFixed(6), fix: cardFix.toFixed(4)}
          return {...record, ...retypedRateAndFix}
        }
        // turn order value range input into order value breakpoint input
        const rangeToBreakpoint = <InType extends OrderValueRange>(record: InType): OrderValueBreakpoint & Omit<InType, 'rangeFloor' | 'rangeCeiling'> => {
          const { rangeFloor, rangeCeiling, ...fields } = record
          const orderValue = rangeFloor ? rangeFloor : 0
          return { orderValue, ...fields }
        }

        // takes an entry with order value, rate plus fix string, context, and tier, and turns into an object entry
        const transformToFormEntry = <InType extends OrderValueBreakpoint & RatePlusFix_Generic & {context: CardContext, tier: number}>(record: InType) => {
          const { orderValue, tier, context, rate, fix } = record
          return { orderValue, tier, [context]: { rate, fix } }
        }

        // builds a reducer used to merge object entries using dynamic field key
        const buildMergeBy = (key: string) => <InType extends {[x: string]: any}>(array: InType[], record: InType) => {
          const buildFindKey = (mergeKey: number | string, invert: boolean = false) => (record: InType) => {
            const { [key]: objectKey } = record
            return (mergeKey === objectKey) === invert
          }

          const { [key]: mergeKey } = record
          if (!['number', 'string'].includes(typeof mergeKey)) return array

          const matchingKey = array.find(buildFindKey(mergeKey, true))
          if (matchingKey === undefined) return [...array, record]
          const others = array.filter(buildFindKey(mergeKey, false))
          const merged = {...matchingKey, ...record}
          return [...others, merged]
        }

        const makeOrderValueProgram = (record: IntermediateProgramRecord) => {
          //type BreakpointIntermediate = Omit<CardTypeDataIntermediate, 'rangeFloor' | 'rangeCeiling' | 'cardRate' | 'cardFix'> & OrderValueBreakpoint & RatePlusFixString
          const { brand, consumer, business, corporate } = record
          const allOverrides = [...consumer.rateOverride, ...business.rateOverride, ...corporate.rateOverride]
          const programFormEntries = (allOverrides
            .map(buildSelectWithCardTypeData(brand))
            .filter(o => o !== undefined) as CardTypeDataIntermediate[])
            .map(retypeRateAndFix)
            .map(rangeToBreakpoint)
            .map(transformToFormEntry)
            .reduce(buildMergeBy('orderValue'), [] as MergeIntermediate[])
            .map(e => _.omit(e, ['tier']))

          return {[brand]: {[ProgramType.ORDER_VALUE]: programFormEntries}}
        }

        const makeTieredProgram = (record: IntermediateProgramRecord) => {
          const { brand, consumer, business, corporate } = record
          const allOverrides = [...consumer.rateOverride, ...business.rateOverride, ...corporate.rateOverride]
          const allFormEntries = (allOverrides
            .map(buildSelectWithCardTypeData(brand))
            .filter(o => o !== undefined) as CardTypeDataIntermediate[])
            .map(retypeRateAndFix)
            .map(rangeToBreakpoint)

          const tierFormEntries = allFormEntries
            .filter(e => e.orderValue === 0)
            .map(transformToFormEntry)
            .reduce(buildMergeBy('tier'), [] as MergeIntermediate[])
            .map(e => _.omit(e, 'orderValue'))
            .map(e => {
              const { tier, ...fields } = e
              return [tier, fields]
            })

          const tierFormObject = Object.fromEntries(tierFormEntries)

          const hirocFormEntries = allFormEntries
            .filter(e => e.context === CardContext.CONSUMER && e.tier === 1)
            .map(e => _.pick(e, ['orderValue', 'rate']))
            .sort(sortByOrderValue)
            .map(e => ({...e, rate: parseFloat(e.rate)}))
            .map((e, i, a) => i === 0 ? undefined : ({...e, rate: (a[0].rate - e.rate).toFixed(6)}))
            .filter(e => e !== undefined)

          return {[brand]: {[ProgramType.TIER_HIROC]: {
            tier: tierFormObject,
            hiroc: hirocFormEntries
          }}}
        }

        const { consumer } = record
        const programType = getProgramType(consumer)
        if (programType === ProgramType.ORDER_VALUE) return makeOrderValueProgram(record)
        else if (programType === ProgramType.TIER_HIROC) return makeTieredProgram(record)
        else return makePresetProgram(record)
      }

      const buildGroupBrandWithPrograms = (programs: InterchangePrograms[]) => (brand: CardBrand) => {
        const allBrandPrograms = programs.filter(p => p.id === brand)
        const consumer = allBrandPrograms.find(p => p.programType === CardContext.CONSUMER) as InterchangePrograms
        const business = allBrandPrograms.find(p => p.programType === CardContext.BUSINESS) as InterchangePrograms
        const corporate = allBrandPrograms.find(p => p.programType === CardContext.CORPORATE) as InterchangePrograms
        return {brand, consumer, business, corporate}
      }

      const interchangePrograms = data.interchangePrograms || []

      const allBrands: CardBrand[] = unique(interchangePrograms.map(p => p.id as CardBrand)) // get all unique brand names (should be visa, mastercard, discover, and amex)
      const programFormEntries = allBrands
        .map(buildGroupBrandWithPrograms(interchangePrograms)) // group programs as { consumer, business, corporate } fields along with brand
        .map(makeProgram) // build program entries
        .reduce((a, o) => ({...a, ...o}), {} as any) // reduce into form object
      
      // set meta fields appropriately
      const metaFormEntries = Object.entries(programFormEntries)
        .map((entry: [string, any]) => {
          const [ brand, program ] = entry
          const programType = Object.keys(program).find(p => !!p)
          return { [brand]: programType || ProgramType.PRESET }
        })
        .reduce((a, o) => ({...a, ...o}), {} as any)

        return {
        interchangePrograms: programFormEntries,
        meta: { useDirect: metaFormEntries }
      }
    }

    const interchangeAveragingToForm = (data: FullProcessor) => {
      // Averaging Stuff
      const averaging = data.ruleSet === RuleSet.INTERCHANGE_AVG ? AveragingType.GLOBAL
                      : data.ruleSet === RuleSet.INTERCHANGE_AVG_BRAND ? AveragingType.BRAND
                      : data.ruleSet === RuleSet.INTERCHANGE_AVG_PRODUCT_LEVEL ? AveragingType.PRODUCT_LEVEL
                      : AveragingType.NONE
      const averagingPeriod = !!data.averagingPeriod ? data.averagingPeriod : undefined

      // Average Fees Stuff
      const hasAverages = !!data.interchangeFees.find(fee => fee.id.includes('AVG'))

      // Rate Override Stuff
      const useOverride = !!data.rate
      const averageRateOverride = !!data.rate ? transformRateToPercent(data.rate) : undefined
      const overrideExpirationRule = !!data.rateTransient
        ? (!!data.rateExpiration ? RateOverrideExpirationRule.CUSTOM : RateOverrideExpirationRule.AUTO)
        : RateOverrideExpirationRule.NEVER
      const rateExpiration = !!data.rateExpiration ? dayjs(parseISO(data.rateExpiration)) : undefined

      return {
        // Averaging
        averaging,
        averagingPeriod,
        // Rate Override
        averageRateOverride,
        rateExpiration,
        meta: {useOverride, overrideExpirationRule, hasAverages}
      }
    }

    const interchangeFeeValues = (!!data.buckets && data.buckets.length > 0) ? bucketsToForm(data) : interchangeFeesToForm(data)
    const interchangeProgramValues = (!!data.interchangePrograms) ? interchangeProgramsToForm(data) : {}
    const interchangeAveragingValues = interchangeAveragingToForm(data)
    const interchangeValues = _.merge(interchangeFeeValues, interchangeProgramValues, interchangeAveragingValues)

    return interchangeValues
  }

  const tieredToForm = (data: FullProcessor) => {
    const tieredFeesToForm = (data: FullProcessor) => {
      const tieredFees = data.tieredFees || []
      return tieredFees.reduce((rollup, fee: TieredFees) => {
        const tierName = fee.id.toLowerCase()
        const tierStructure = fee.fees.reduce((innerRollup, innerFee) => {
          const rawCardName = innerFee.id.toLowerCase()
          const cardBrand: CardBrand = (rawCardName === 'credit' ? 'base' : rawCardName) as CardBrand
          const key = cardBrand === CardBrand.AMEX ? `${cardBrand}_non_qual` : `${cardBrand}_${tierName}`
          return {
            ...innerRollup,
            [key]: {
              rate: transformRateToPercent(innerFee.rate!),
              fix: transformFix(innerFee.fix!),
            }
          }
        }, {})
        return {...rollup, ...tierStructure}
      }, {})
    }

    const transactionFeesToForm = (data: FullProcessor) => {
      const fixFees = data.fixFees || []
      const perTxFees = fixFees.reduce((rollup, fee: FixedFees) => {
        return {
          ...rollup,
          [fee.id.toLowerCase()]: fee.fix,
        }
      }, {})
      return perTxFees
    }

    const tiered = (!!data.tieredFees && data.tieredFees.length > 0) ? tieredFeesToForm(data) : {}
    const transactionFees = (!!data.fixFees && data.fixFees.length > 0) ? transactionFeesToForm(data) : {}

    return {
      tiered,
      transactionFees
    }
  }

  const feeConfigToForm = (data: FullProcessor) => {
    const { feeConfigs } = data
    const useConvenienceFees = !!feeConfigs?.convenienceFeeConfig?.averagingPeriod
    const useServiceFees = !!feeConfigs?.serviceFeeConfig?.averagingPeriod
    return {feeConfigs, meta: {useConvenienceFees, useServiceFees}}
  }

  const processorSourceData = processorSourceToForm(data)

  const ruleSet = data.ruleSet.includes(RuleSet.INTERCHANGE) ? RuleSet.INTERCHANGE : data.ruleSet
  const ruleSpecificData = ruleSet === RuleSet.FIXED_FEE ? fixedToForm(data)
                         : ruleSet === RuleSet.INTERCHANGE ? interchangeToForm(data)
                         : ruleSet === RuleSet.TIERED ? tieredToForm(data)
                         : {}

  const feeConfigData = feeConfigToForm(data)

  const { id, name, mid, limitedAcceptance } = data

  const naiveFormValues = {
    id,
    name,
    mid,
    limitedAcceptance,
    ruleSet,
    notifiedOn: data.notifiedOn,
    ...processorSourceData,
    ...ruleSpecificData,
    ...feeConfigData,
    meta: {
      ...(ruleSpecificData as any)?.meta,
      ...feeConfigData.meta,
    }
  }

  const formValues = _.merge(formDefaults, naiveFormValues)
  console.log('Computed form values', formValues)
  return formValues
}
