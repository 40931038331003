import { BookOutlined, HomeOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Layout, Result, Row, Table, Typography } from "antd"
import { usePortalResources } from "hooks/usePortalResources"
import { useProducts } from "hooks/useProducts"
import { getMerchantDisplayName, Merchant } from "models/models"
import { useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"

const { Content } = Layout
const { Title } = Typography

export const PaylinkDashboardView = () => {
  const { merchants, merchantsLoaded } = usePortalResources()
  const { paylinkGrants } = useProducts()

  const history = useHistory()

  const paylinkMerchants = useMemo(() => merchants.filter(m => !!(paylinkGrants || []).find(g => g[1] === `merchant:${m.id}`)), [merchants, paylinkGrants])

  const columns = useMemo(() => [
    {
      key: 'name',
      title: 'Name',
      render: (m: Merchant) => getMerchantDisplayName(m)
    },
    {
      key: 'actions',
      render: (m: Merchant) => <div style={{textAlign: 'right'}}>
        <Button type='primary' ghost icon={<BookOutlined />} onClick={() => history.push(`/pl/${m.id}`)} />
      </div>
    }
  ], [history])

  useEffect(() => {
    if (!paylinkMerchants) return
    if (paylinkMerchants.length === 1) history.push(`/pl/${paylinkMerchants[0].id!}`)
  }, [paylinkMerchants])  

  return <Layout>
    <Content className='main-content'>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Result
            icon={<HomeOutlined />}
            title={<Title level={3} style={{marginRight: 0}}>PayLink Dashboard</Title>}
          />
        </Col>
        <Col span={24}>
          <Card>
            <div style={{display: 'grid', gridTemplateColumns: '1fr auto 1fr'}}>
              <Title level={4} style={{gridColumn: 2, textAlign: 'center', marginBlockEnd: '0.5em'}}>Merchants</Title>
            </div>
            <Divider />
            <Table
              columns={columns}
              dataSource={paylinkMerchants}
              loading={!merchantsLoaded}
            />
          </Card>
        </Col>
      </Row>
    </Content>
  </Layout>
}