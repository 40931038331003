import { Switch, useHistory } from "react-router-dom"
import { buildRouteAsJSX, paylinkRoutes } from "models/routes"
import { useProducts } from "hooks/useProducts"
import { useEffect } from "react"

export const PaylinkMain = () => {
  const { loaded, hasPaylink } = useProducts()
  const history = useHistory()
  
  console.log(hasPaylink)

  useEffect(() => {
    if (loaded && !hasPaylink) history.push('/')
  }, [loaded, hasPaylink])

  if (!hasPaylink) return <></>
  return <>
    <Switch>
      {paylinkRoutes.map(route => buildRouteAsJSX(route))}
    </Switch>
  </>
}