/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React, {Suspense, useEffect} from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'

import {Button, Layout, Result, Space} from 'antd'

import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react"

import Loading from "../components/Loading/Loading"

import {env2} from "../utils/env2"
import {AppFooter} from '../components/Layout/AppFooter'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  binservRoutes,
  feeservRoutes,
  auditPortalRoutes,
  adminPortalRoutes,
  buildRouteAsJSX
} from '../models/routes'
import { AppHeader } from '../components/Layout/AppHeader'
import { PortalTheme } from '../models/models'
import { usePortalTheme } from '../App'
import { ErrorBoundary } from 'components/ErrorBoundary'
import {RedirectLoginOptions} from "@auth0/auth0-spa-js";
import { AuthError } from '../components/AuthErrorMagicRedirect'
import { SurchargingMain } from './surcharging/SurchargingMain'
import { PaylinkMain } from './paylink/PaylinkMain'
import { useProducts } from 'hooks/useProducts'
import { useAuthorization } from 'hooks/useAuthorization'
import { createGlobalState } from 'react-use'

export enum Product {
  SURCHARGING = 'surcharging',
  PAYLINK = 'paylink',
}

export const useLastProduct = createGlobalState<Product>(Product.SURCHARGING)

const NothingHere = () => {
  const { isAuthorized, actionsLoaded } = useAuthorization('*', ['admin'])

  const [ lastProduct ] = useLastProduct()
  const history = useHistory()
  const { loading, loaded, hasSurcharging, hasPaylink, paylinkGrants, noProducts } = useProducts()
  
  useEffect(() => {
    if (!isAuthorized('admin')) return
    if (loaded && !loading) {
      if (hasSurcharging && !hasPaylink) history.push('/p')
      else if (hasPaylink && !hasSurcharging) {
        if (paylinkGrants!.length === 1) history.push(`/pl/merchant/${paylinkGrants![0][1].split(':')[1]}`)
        else history.push('/pl')
      } else {
        if (lastProduct === Product.SURCHARGING) history.push('/p')
        else if (lastProduct === Product.PAYLINK) history.push('/pl')
      }
    }
  }, [loading, loaded, isAuthorized])

  useEffect(() => {
    if (actionsLoaded && !isAuthorized('admin')) history.push('/p')
  }, [isAuthorized, actionsLoaded])

  return <></>
}

dayjs.extend(utc)

const AppName = env2("REACT_APP_NAME", "InterPayments Portal")
document.title = AppName

const DebugAuth = env2("REACT_APP_DEBUG_AUTH", "true") === "true"
const debugLog = (message?: any, ...optionalParams: any[]) => {
  if (DebugAuth) {
    console.log(`[auth] ${message}`, ...optionalParams)
  }
}

const MainFC: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isLoading, error, logout, user, getIdTokenClaims } = useAuth0()

  const [portalTheme, setPortalTheme] = usePortalTheme()

  // every 30 seconds, check if we are still logged in and log out if we are not
  useEffect(() => {
    const timeout = setInterval(async () => {
      try {
        const t = await getIdTokenClaims()
        debugLog("testing idTokenClaims")
        if (!t) {
          debugLog("token non-existent,... logging out")
          await handleLogout(undefined)
        } else if (t && t.exp) {
          const now = dayjs()
          const expirationDate = t.exp * 1000
          const tokenExpiration = dayjs.utc(expirationDate)
          if (now.isAfter(tokenExpiration)) {
            debugLog("token expired,... logging out", tokenExpiration)
            await handleLogout(undefined)
          }
        }
      } catch (error) {
        console.log("token check error", error)
      }
    }, 1000 * 30)
    return () => clearTimeout(timeout)
  }, [])

  const handleLogout = async (e: React.MouseEvent<HTMLElement> | undefined) => {
    if (e && e.ctrlKey) {
      const t = await getIdTokenClaims()
      if (t && t.__raw) {
        const BaseUrl = env2("REACT_APP_BASE_URL")
        const url = `${BaseUrl}/v1/collective/whoami?_a=${t.__raw}`
        window.open(url, '_blank')
      }
    } else {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      })
    }
  }

  if (error) {
    return <AuthError error={error} />
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Layout
      style={{maxHeight: '100vh', height:"100vh"}}
    >
      <Layout>
        <AppHeader />
        <Layout.Content style={{minHeight: 'unset'}}>
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Switch>
                {/*
                  Setup main routes - the routes can be found in the models/routes.tsx file
                */}
                {(portalTheme === PortalTheme.DEFAULT) && <>
                  <Route path='/p' component={SurchargingMain} />
                  <Route path='/pl' component={PaylinkMain} />
                  <Route path='/' component={NothingHere} exact />
                </>}
                {/*(portalTheme === PortalTheme.DEFAULT) && mainRoutes.map(route => buildRouteAsJSX(route))*/}
                {(portalTheme === PortalTheme.BINSERV) && binservRoutes.map(route => buildRouteAsJSX(route))}
                {(portalTheme === PortalTheme.FEESERV) && feeservRoutes.map(route => buildRouteAsJSX(route))}
                {(portalTheme === PortalTheme.AUDIT_PORTAL) && <>
                  <Redirect from='/' to='/p' />
                  {...auditPortalRoutes.map(route => buildRouteAsJSX(route))}
                </>}
                {(portalTheme === PortalTheme.ADMIN_PORTAL) && adminPortalRoutes.map(route => buildRouteAsJSX(route))}
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </Layout.Content>
        <AppFooter />
      </Layout>
    </Layout>
  )
}

const DefaultConnection: (string | undefined) = env2("REACT_APP_DEFAULT_CONNECTION", undefined)
const DefaultPrompt: (string | undefined) = env2("REACT_APP_DEFAULT_PROMPT", undefined)
const optionalConnection = DefaultConnection ? { connection: DefaultConnection } : {}
const prompt = { prompt: DefaultPrompt || undefined }
const loginOptions: RedirectLoginOptions = {
  authorizationParams: {
    display: "page",
    screen_hint: "login",
    ...optionalConnection,
    ...prompt as { prompt: 'none' | 'login' | 'consent' | 'select_account' | undefined }
  }
}

export const Main = withAuthenticationRequired(MainFC, {
  onRedirecting: () => <Loading />,
  loginOptions: loginOptions
})
