import { blue } from "@ant-design/colors"
import { DeleteOutlined, PlusOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Form, Input, message, Modal, notification, Popconfirm, Result, Row, Select, Space, Spin, Table, Tag, Tooltip, Typography } from "antd"
import { FancyCard } from "components/Card"
import { usePaylinkAPI } from "hooks/paylink/usePaylinkAPI"
import { useTableSearch } from "hooks/useTableSearch"
import { DefaultEmailMaxLength, difference, formLayout, Invite, maxLengthRule, Merchant, tailLayout, User, UserRole } from "models/models"
import { InviteRequest, Location } from "models/paylink"
import { Key, useCallback, useEffect, useMemo, useState } from "react"
import { RoleTag } from "components/RoleTag"
import { useAuthorization } from "hooks/useAuthorization"
import { usePaylinkMerchantContext } from "../PaylinkMerchantContext"
import { useCasbinAPI } from "hooks/useCasbinAPI"
import _ from "lodash"
import { ColumnsType, ColumnType } from "antd/es/table"
import { useAuth0 } from "@auth0/auth0-react"

const { Text, Title } = Typography

type InviteForm_Shape = {
  email: string,
  role: UserRole,
}

const InviteForm = ({ onSubmit, onCancel }: {onSubmit: (values: InviteForm_Shape) => void, onCancel: () => void}) => {
  const options = useMemo(() => {
    return [UserRole.PAYLINK_ADMIN, UserRole.PAYLINK_ACCOUNTING, UserRole.PAYLINK_PAYER]
      .map(role => ({label: <RoleTag role={role} />, key: role, value: role}))
  }, [])

  return <Form
    {...formLayout}
    onFinish={values => onSubmit(values)}
  >
    <Form.Item
      name='email'
      label="Email"
      rules={[{required: true, message: 'Please enter an email address'}, {type: 'email', message: 'Please enter a valid email address'}, maxLengthRule(DefaultEmailMaxLength)]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name='role'
      label='Role'
      rules={[{required: true, message: 'Please enter a valid role'}]}
    >
      <Select
        options={options}
      />
    </Form.Item>

    <Form.Item {...tailLayout}>
      <Space>
        <Button type="primary" htmlType="submit">Invite</Button>
        <Button type="default" htmlType="button" onClick={onCancel}>Cancel</Button>
      </Space>
    </Form.Item>
  </Form>
}

type UserRecord = User & {role: string, status: UserStatus.ACTIVE}
type InviteRecord = Invite & {status: UserStatus.INVITED}
type KeyedRecord = (UserRecord | InviteRecord) & {key: string}

const AddLocationButton = ({ user, initialLocations, onRefresh }: {user: UserRecord, initialLocations: string[], onRefresh: () => Promise<void>}) => {
  const { locations } = usePaylinkMerchantContext()
  const { addUserPermission, removeUserPermission } = useCasbinAPI()

  const keyedLocations = useMemo(() => {
    if (!locations) return []
    return locations.map(l => ({...l, key: l.id}))
  }, [locations])

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ open, setOpen ] = useState<boolean>(false)
  const [ selectedLocations, setSelectedLocations ] = useState<Key[]>(initialLocations)

  useEffect(() => {
    setSelectedLocations(initialLocations)
  }, [initialLocations])

  const onSelectionChange = useCallback((keys: Key[]) => {
    setSelectedLocations(keys)
  }, [setSelectedLocations])

  const handleSubmit = useCallback(() => {
    const selectedSet = new Set<string>(selectedLocations.map(l => l.toString()))
    const initialSet = new Set<string>(initialLocations.map(l => l.toString()))

    const toAdd = difference(selectedSet, initialSet)
    const toRemove = difference(initialSet, selectedSet)

    const addLocationPromises = Array.from(toAdd).map(l => addUserPermission(user.user_id, `location:${l}`, UserRole.PAYLINK_PAYER))
    const removeLocationPromises = Array.from(toRemove).map(l => removeUserPermission(user.user_id, `location:${l}`, UserRole.PAYLINK_PAYER))
    const allPromises = [...addLocationPromises, ...removeLocationPromises]
    
    if (allPromises.length === 0) {
      setOpen(false)
      return Promise.resolve()
    }

    message.info({content: 'Updating user locations', key: 'updateLocations'})
    setLoading(true)
    return Promise.all(allPromises)
      .then(_ => onRefresh())
      .then(_ => {
        setLoading(false)
        setOpen(false)
        message.success({content: 'User locations updated successfully!', key: 'updateLocations'})
      })
      .catch(e => {
        setLoading(false)
        message.error({content: 'User locations could not be updated', key: 'updateLocations'})
      })
  }, [initialLocations, selectedLocations])

  const columns = useMemo((): ColumnsType<Location> => {
    return [
      {
        key: 'name',
        title: 'Name',
        render: (_, location) => <Text strong>{location.contact.name}</Text>
      }
    ]
  }, [])
  
  return <>
    <Modal loading={loading} open={open} closeIcon={false} footer={<Button type='primary' onClick={() => handleSubmit()}>Update</Button>} onCancel={() => setOpen(false)}>
      <Table
        dataSource={keyedLocations}
        columns={columns}
        pagination={false}
        rowSelection={{
          onChange: onSelectionChange,
          selectedRowKeys: selectedLocations
        }}
      />
    </Modal>
    <Button type='primary' icon={<PlusOutlined />} onClick={() => setOpen(true)} />
  </>
}

const UserExpanded = ({ user }: {user: UserRecord}) => {
  const { getUserGrants } = useCasbinAPI()

  const { locations } = usePaylinkMerchantContext()
  const [ locationGrants, setLocationGrants ] = useState<string[]>([])

  const mappedLocationGrants = useMemo(() => {
    if (!locations) return []
    return locations.filter(l => locationGrants.includes(l.id)).map(l => ({...l, key: l.id}))
  }, [locations, locationGrants])

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        title: 'Name',
        render: (_, location) => <Text strong>{location.contact.name}</Text>
      }
    ]
  }, [])

  const refresh = useCallback(() => {
    setLocationGrants([])
    return getUserGrants(user.user_id)
      .then(response => {
        const grants: ([string, string, string][] | undefined) = _.get(response, `casbin.0.${user.user_id}`)
        if (!grants) {
          setLocationGrants([])
          return
        }
        const nextGrants = grants.filter(g => g[1].startsWith('location:'))
        const locations = nextGrants.map(g => g[1].split(':')[1])
        setLocationGrants(locations)
      })
  }, [user, getUserGrants])

  useEffect(() => {
    refresh()
  }, [refresh])

  return <>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4}>{user.name} <Text type='secondary'>({user.email})</Text></Title>
              <RoleTag role={user.role} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card title='Locations' extra={<AddLocationButton onRefresh={refresh} user={user} initialLocations={locationGrants} />}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Table dataSource={mappedLocationGrants} columns={columns} pagination={false} />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  </>
}

enum UserStatus {
  ACTIVE = 'Active',
  INVITED = 'Invited',
}

const UsersTable = ({ merchant, onRefresh }: {merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const { getSearchProps } = useTableSearch<any>()
  const { deleteUser, deleteInvite } = usePaylinkAPI()
  const { isAuthorized } = useAuthorization(`merchant:${merchant.id}`, [UserRole.PAYLINK_ADMIN])

  const [ loading, setLoading ] = useState<boolean>(false)

  const mid = merchant.id!

  const deleteRecord = useCallback((record: KeyedRecord) => {
    if (record.status === UserStatus.ACTIVE) {
      setLoading(true)
      message.info({ content: <Text>Deleting user <Text strong>{record.name}</Text><Text type='secondary'> ({record.email})</Text></Text>, key: 'deleteRecord' })
      deleteUser(mid, record.user_id, record.role)
        .then(onRefresh)
        .then(_ => {
          message.success({ content: <Text>User <Text strong>{record.name}</Text><Text type='secondary'> ({record.email})</Text> was deleted successfully</Text>, key: 'deleteRecord' })
          setLoading(false)
        })
        .catch(e => {
          message.error({ content: <Text>User <Text strong>{record.name}</Text><Text type='secondary'> ({record.email})</Text> could not be deleted</Text>, key: 'deleteRecord' })
          setLoading(false)
        })
    } else if (record.status === UserStatus.INVITED) {
      setLoading(true)
      message.info({ content: <Text>Deleting invite to <Text strong>{record.email}</Text></Text>, key: 'deleteRecord' })
      deleteInvite(mid, record.token)
        .then(onRefresh)
        .then(_ => {
          message.success({ content: <Text>Invite to <Text strong>{record.email}</Text> was deleted successfully</Text>, key: 'deleteRecord' })
          setLoading(false)
        })
        .catch(e => {
          message.error({ content: <Text>Invite to <Text strong>{record.email}</Text> could not be deleted</Text>, key: 'deleteRecord' })
          setLoading(false)
        })
    }
  }, [merchant])

  const dataSource = useMemo(() => {
    const users = (merchant.users || []).map(u => ({...u, key: u.user_id, status: UserStatus.ACTIVE})) as KeyedRecord[]
    const invites = (merchant.invites || []).filter(i => i.active).map(i => ({...i, key: i.email, status: UserStatus.INVITED})) as KeyedRecord[]
    const data = [...users, ...invites]
    return data
  }, [merchant])

  return <Spin spinning={loading}>
    <Table
      columns={[
        {
          title: 'Name',
          key: 'name',
          render: (_, record) => {
            if (record.status === UserStatus.ACTIVE) return <div>
              <Text>{(record as User).name}</Text>
              <br />
              <Text type='secondary'>{record.email}</Text>
            </div>
            else return <Text type='secondary'>{record.email}</Text>
          },
          ...getSearchProps((value, record) => {
            const userId = (record.userId || '').toLowerCase()
            const name = (record.name || '').toLowerCase()
            const email = (record.email || '').toLowerCase()
            const searchValue = value.toString().toLowerCase()
            return [userId, name, email].some(v => v.includes(searchValue))
          })
        },
        {
          title: 'Status',
          key: 'status',
          render: (_, record) => {
            if (record.status === UserStatus.ACTIVE) return <Tag color='success'>Active</Tag>
            else if (record.status === UserStatus.INVITED) return <Tag color='warning'>Invited</Tag>
            return <></>
          },
          filters: [
            {
              value: UserStatus.ACTIVE,
              text: <Tag color='success'>Active</Tag>
            },
            {
              value: UserStatus.INVITED,
              text: <Tag color='warning'>Invited</Tag>
            }
          ],
          onFilter: (value, record) => record.status === value
        },
        {
          title: 'Role',
          key: 'role',
          render: (_, record) => record.status === UserStatus.ACTIVE ? <RoleTag role={record.role} /> : <Space size='small'>{Array.from(record.roles).map(role => <RoleTag key={role} role={role}/>)}</Space>
        },
        {
          key: 'actions',
          render: (_, record) => <div style={{textAlign: 'right'}}>
            <Space size='small'>
              <Popconfirm
                placement="left"
                title={record.status === UserStatus.ACTIVE ? <Text>Are you sure you want to delete user <Text strong>{record.name}</Text><Text type='secondary'> ({record.email})</Text>?</Text> : <Text>Are you sure you want to revoke invite to <Text strong>{record.email}</Text>?</Text>}
                onConfirm={() => deleteRecord(record)}
              >
                <Tooltip title='Delete User'>
                  <Button ghost danger icon={<DeleteOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Space>
          </div>
        }
      ]}
      dataSource={dataSource}
      expandable={{
        defaultExpandAllRows: false,
        rowExpandable: record => record.status === UserStatus.ACTIVE,
        expandedRowRender: (record) => {
          return <UserExpanded user={record as UserRecord} />
        }
      }}
    />
  </Spin>
}

const NewInviteCard = ({merchant, onRefresh}: {merchant: Merchant, onRefresh: () => Promise<void>}) => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  const mid = merchant.id!

  const { sendInvite } = usePaylinkAPI()

  const handleSubmit = useCallback((values: InviteForm_Shape) => {
    setLoading(true)
    const request: InviteRequest = {
      merchantId: mid,
      email: values.email,
      roles: [values.role],
      origin: window.location.origin
    }
    message.info({ content: <Text>Sending invite to <Text strong>{values.email}</Text></Text>, key: 'inviteUser' })
    return sendInvite(mid, request)
      .then(onRefresh)
      .then(_ => {
        message.success({ content: <Text>Invite to <Text strong>{values.email}</Text> sent successfully</Text>, key: 'inviteUser' })
        setLoading(false)
        setShowForm(false)
      })
      .catch(e => {
        message.error({ content: <Text>Invite to <Text strong>{values.email}</Text> could not be sent</Text>, key: 'inviteUser' })
        setLoading(false)
      })
  }, [])

  if (showForm) return <Spin spinning={loading}>
    <Card title='New Invite'>
      <InviteForm onSubmit={handleSubmit} onCancel={() => setShowForm(false)} />
    </Card>
  </Spin>

  return <FancyCard style={{height: '100%', position: 'relative', overflow: 'hidden'}} icon={<PlusOutlined style={{color: blue[0]}} />} hoverable onClick={() => setShowForm(true)}>
    <Title level={4} style={{color: blue.primary, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>New Invite</Title>
  </FancyCard>
}

export const UsersInner = ({ onRefresh }: {onRefresh: () => Promise<void>}) => {
  const { merchant } = usePaylinkMerchantContext()

  const [ loading, setLoading ] = useState<boolean>(false)

  const handleRefresh = useCallback(() => {
    setLoading(true)
    return onRefresh()
      .then(_ => setLoading(false))
  }, [onRefresh])

  if (!merchant) return <></>
  return <Spin spinning={loading}>
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Result
          title={<Title level={2}>Users</Title>}
          icon={<UserOutlined />}
        />
      </Col>

      <Col span={24}>
        <NewInviteCard merchant={merchant} onRefresh={handleRefresh} />
      </Col>

      <Col span={24}>
        <UsersTable merchant={merchant} onRefresh={handleRefresh} />
      </Col>
    </Row>
  </Spin>
}

