/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React, { useEffect, useMemo, useState } from 'react'
import { Button, Dropdown, Layout, Tooltip } from 'antd'
import {env2} from "../../utils/env2"
import {Logo} from "./Logo";
import { CaretDownOutlined, CodeOutlined, HomeOutlined, QuestionOutlined, ReloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useAuthorization } from "../../hooks/useAuthorization";
import { useAuth0 } from '@auth0/auth0-react';
import { PortalTheme } from '../../models/models'
import { usePortalTheme } from '../../App';
import {RedirectLoginOptions} from "@auth0/auth0-react/dist/auth0-context";
import { ItemType } from 'antd/es/menu/interface';
import { blue } from '@ant-design/colors';
import { useProducts } from 'hooks/useProducts';

const { Header } = Layout

const DebugAuth = env2("REACT_APP_DEBUG_AUTH", "true") === "true"
const debugLog = (message?: any, ...optionalParams: any[]) => {
  if (DebugAuth) {
    console.log(`[auth] ${message}`, ...optionalParams)
  }
}

export const IsProduction = env2("REACT_APP_BASE_URL") === 'https://api.interpayments.com'

const ResellerPortalVersion = import.meta.env.VITE_BUILD_VERSION
const VersionAtAppStart = env2("REACT_APP_VERSION1")
const PipelineBuildNumber = env2("REACT_APP_BUILD_NUMBER")
const VersionString = ((PipelineBuildNumber) ? `${ResellerPortalVersion} (${PipelineBuildNumber})` : ResellerPortalVersion)
const AppName = env2("REACT_APP_NAME")

const SignupService: boolean = env2("REACT_APP_SIGNUPSERVICE", "false") === "true"

const DefaultConnection: (string | undefined) = env2("REACT_APP_DEFAULT_CONNECTION", undefined)
const DefaultPrompt: (string | undefined) = env2("REACT_APP_DEFAULT_PROMPT", undefined)

//debugLog("ResellerPortalVersion", ResellerPortalVersion)
debugLog("VersionAtAppStart", VersionAtAppStart)
debugLog("PipelineBuildNumber", PipelineBuildNumber)
//debugLog("VersionString", VersionString)

export const getThemeStyle = (theme: PortalTheme | undefined) => {
  return theme === PortalTheme.FEESERV ? {'backgroundColor': '#00251f'} :
         theme === PortalTheme.BINSERV ? {'backgroundColor': '#1E1227'} :
         {}
}

export const SimpleHeader:React.FC<React.PropsWithChildren<unknown>> = () => {
  const [portalTheme, setPortalTheme] = usePortalTheme()

  return (<Header style={{height: 'unset', ...getThemeStyle(portalTheme)}}>
    <div className='header-outer'>
      <div className='header-leader'>
        <div className='header-tools' />
        {!SignupService && <div className='header-brand'>
          <Logo />
          <div className='header-title-outer'>
            <h1 className='header-title-main'>{AppName}</h1>
            <h2 className='header-title-aside'>{ResellerPortalVersion}</h2>
          </div>
        </div>}
      </div>
    </div>
  </Header>)
}

const AppHeaderFC:React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory()
  const { user, loginWithRedirect, logout, getIdTokenClaims } = useAuth0()
  const { actionsLoaded, isAuthorized } = useAuthorization('*', ['admin'])
  const { hasPaylink } = useProducts()

  const [version, setVersion] = useState<string|undefined>(undefined)
  const [updateAvailable, setUpdateAvailable] = useState<boolean>(false)

  const [portalTheme, setPortalTheme] = usePortalTheme()

  // every 10 minutes, check if the version has changed and if so toggle updateAvailable
  useEffect(() => {
    if (!SignupService && VersionAtAppStart) {
      const timeout = setInterval(async () => {
        const d = Date.now
        try {
          const url = `/env.js`
          const rsp = await fetch(url, { cache: "no-store" })
          const text = await rsp.text()
          const payload = text.replace(/window._env = (.*?);/, "$1")
          const p = JSON.parse(payload)
          debugLog("compare versions", p.REACT_APP_VERSION1, ResellerPortalVersion)
          setVersion(`${p.REACT_APP_VERSION1}...${d}`)
          if (p.REACT_APP_VERSION1 !== ResellerPortalVersion) {
            debugLog("New software version is available... refresh")
            setUpdateAvailable(true)
          }
        } catch (error) {
          console.log("version check error", error)
        }
      }, 1000 * 60 * 10)
      return () => clearTimeout(timeout)
    } else {
      debugLog("running local, no need for version check")
      return
    }
  }, [])

  const handleLogout = async (e: React.MouseEvent<HTMLElement> | undefined) => {
    if (e && e.ctrlKey) {
      const t = await getIdTokenClaims()
      if (t && t.__raw) {
        const BaseUrl = env2("REACT_APP_BASE_URL")
        const url = `${BaseUrl}/v1/collective/whoami?_a=${t.__raw}`
        window.open(url, '_blank')
      }
    } else {
      logout({
        logoutParams: {returnTo: window.location.origin},
      })
    }
  }

  // https://interpayments.atlassian.net/browse/PRO-2157
  // something is required here for SSO and only enterprise login
  const optionalConnection = DefaultConnection ? { connection: DefaultConnection } : {}
  const prompt = { prompt: DefaultPrompt || undefined }
  const loginOptions: (RedirectLoginOptions | undefined) = {
    authorizationParams: {
      screen_hint: "login",
      display: "page",
      ...optionalConnection,
      ...prompt as { prompt: 'none' | 'login' | 'consent' | 'select_account' | undefined },
    },
    appState: {
      targetUrl: window.location.pathname
    }
  }

  // if we have a separate AdminUrl (different webapp), then redirect to it for all admin content
  const HomeUrl = env2("REACT_APP_HOME_URL", undefined)
  const AdminUrl = env2("REACT_APP_ADMIN_URL", undefined)
  const PortalUrl = env2("REACT_APP_MANAGEMENT_URL", undefined)

  const productMenuItems: (ItemType & {href: string})[] = useMemo(() => {
    return [
      {
        key: 'surcharging',
        label: 'IP Portal',
        href: '/p'
      },
      ...(hasPaylink ? [{
        key: 'paylink',
        label: 'Paylink Portal',
        href: '/pl'
      }] : [])
    ]
  }, [hasPaylink])

  return <Header style={{height: 'unset', ...getThemeStyle(portalTheme)}}>
    {!SignupService && <div className='header-outer'>
      <div className='header-leader'>
        <div className='header-tools'>
          {user && <>
            <Tooltip title="Back to Home" placement='right'>
              <Button icon={<HomeOutlined />} type="primary" ghost onClick={() => window.location.href = HomeUrl} />
            </Tooltip>
            {actionsLoaded && (isAuthorized('admin')
              ? <Tooltip title="Admin Tools" placement='right'>
                <Button icon={<CodeOutlined />} ghost onClick={() => {
                  AdminUrl ? window.location.href = `${AdminUrl}/p/admin` : history.push(`/p/admin`)
                }} />
              </Tooltip>
              : <Tooltip title='Contact Support' placement='right'>
                <Button icon={<QuestionOutlined />} ghost onClick={() => window.location.assign('mailto:support@interpayments.com')} />
              </Tooltip>)}
          </>}
        </div>
        <div className='header-brand'>
          <Logo />
          <div className='header-title-outer'>
            <h1 className='header-title-main'>
              {AppName}
              {portalTheme !== PortalTheme.AUDIT_PORTAL && <Dropdown menu={{items: productMenuItems, onClick: (item) => history.push(productMenuItems.find(m => m.key === item.key)!.href)}}>
                <CaretDownOutlined style={{marginInlineStart: '0.25em', color: blue.primary}} />
              </Dropdown>}
            </h1>
            <h2 className='header-title-aside'>{VersionString}</h2>
            {updateAvailable && <Tooltip title="Update available, click to refresh" placement='right'>
              <Button icon={<ReloadOutlined style={{ fontSize: '12px', color: '#08c' }} />} type="text" onClick={() => window.location.reload() } />
            </Tooltip>}
          </div>
        </div>
      </div>
      <div className='header-auth'>
        {user ?
        <>
          <span className='header-auth-greeting'>Logged in as</span>
          <span className='header-auth-user'>{user.name}</span>
          <a className='header-auth-logout' onClick={(e:React.MouseEvent<HTMLElement>) => handleLogout(e)}>Log Out</a>
        </> :
        <>
          <span className='header-auth-greeting'>You are not logged in</span>
          <a className='header-auth-logout' onClick={() => loginWithRedirect(loginOptions)}>Log In</a>
        </>}
      </div>
    </div>}
  </Header>
}

export const AppHeader = AppHeaderFC
