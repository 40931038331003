import React, { CSSProperties } from 'react'
import { Card, Col, Tooltip, Typography } from 'antd'
import { BulletedList, Link } from './Primitives'
import { QuestionCircleFilled } from '@ant-design/icons'

type IconAnnotation_Props = {
  icon: any,
}

type LinkCard_Props = {
  title: string,
  href: string,
  bullets: string[],
  icon: any,
  isWide?: boolean,
  isAnchor?: boolean,
  style?: CSSProperties,
}

const IconAnnotation: React.FC<React.PropsWithChildren<IconAnnotation_Props>> = ({ icon }) => (
  <div style={{
    position: 'absolute',
    zIndex: 0,
    top: '50%',
    right: '24px',
    transform: 'translateY(-50%)',
    fontSize: '8rem',
    color: '#f5f5f5'
  }}>
    {icon}
  </div>
)

type FancyCard_Props = {
  title?: string,
  tip?: string,
  icon?: any,
  hoverable?: boolean,
  style?: CSSProperties,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
}

export const FancyCard: React.FC<React.PropsWithChildren<FancyCard_Props>> = ({ title, tip, icon, children, onClick, hoverable = false, style = {} }) => {
  const handleClick = onClick ?? (() => undefined)
  const tipComponent = !!tip ? <>&nbsp;<Tooltip title={tip}><QuestionCircleFilled /></Tooltip></> : <></>
  return <Card hoverable={hoverable} style={style} onClick={handleClick}>
    <div style={{ position: 'relative', zIndex: 1 }}>
      {!!title && <Typography.Title level={4}>{title}{tipComponent}</Typography.Title>}
      {children}
    </div>
    {!!icon && <IconAnnotation icon={icon} />}
  </Card>
}

const BulletedLinkCardFC: React.FC<React.PropsWithChildren<LinkCard_Props>> = ({ title, href, bullets, icon, isWide = false, isAnchor = false, style = {} }) => {
  const columnProps = isWide ? { span: 24 } : { xs: 24, lg: 12 }
  return (
    <Col {...columnProps}>
      <Link to={href} isAnchor={isAnchor}>
        <FancyCard title={title} icon={icon} hoverable style={style}>
          <BulletedList content={bullets} />
        </FancyCard>
      </Link>
    </Col>
  )
}

export const BulletedLinkCard = BulletedLinkCardFC